import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  NaturalPersonControlName,
  PostOfficeModalComponent,
  PzService,
  Street,
} from '@gk/gk-modules';
import { Observable, takeWhile } from 'rxjs';
import { InvestorDetailsControlName } from '../../services/investor-details-form/investor-details-form.model';
import { Place } from '../../services/place/place.model';
import { PostalCode } from '../../services/postal-code/postal-code.model';

@Component({
  selector: 'app-investor-details',
  templateUrl: './investor-details.component.html',
  styleUrls: ['./investor-details.component.scss'],
})
export class InvestorDetailsComponent implements OnInit, OnDestroy {
  private isAlive = true;
  investorDetailsControlName = InvestorDetailsControlName;
  lawPersonAddressControlName = LawPersonAddressControlName;
  @ViewChild(PostOfficeModalComponent)
  postOfficeModalComponent: PostOfficeModalComponent;
  @Input() investorDetailsFromGroup: UntypedFormGroup;
  @Input() legalPersonEnabled = true;
  @Input() idPrefix = '';
  @Input() isPhoneNumberRequired = true;
  @Input() isPhoneNumberVisible = true;
  @Input() isEmailVisible = true;
  @Input() isEmailRequired = true;
  @Input() isPeselVisible = true;
  @Input() isIdVisible = true;
  @Input() isEPUAPAddressVisible = false;
  @Input() primitivePostalCode: boolean;
  @Input() wasFormValidated: boolean;
  @Input() automaticCopyingDataFromPz = true;

  constructor(
    private cdRef: ChangeDetectorRef,
    private pzService: PzService,
  ) {}

  ngOnInit(): void {
    this.toggleFormDisabledByLawPersonType(this.getTypeFormControl().value);
    this.subscribeToTypeFormControl();
    this.clearValidatorsForInvisibleFormControls();
    this.copyDataFromPz();
  }

  toggleFormDisabledByLawPersonType(lawPersonType: LawPersonType): void {
    if (lawPersonType === LawPersonType.Natural) {
      this.investorDetailsFromGroup
        .get(LawPersonControlName.LegalPersonData)
        .disable();
      this.investorDetailsFromGroup
        .get(LawPersonControlName.NaturalPersonData)
        .enable();
    } else {
      this.investorDetailsFromGroup
        .get(LawPersonControlName.NaturalPersonData)
        .disable();
      this.investorDetailsFromGroup
        .get(LawPersonControlName.LegalPersonData)
        .enable();
    }
    this.cdRef.detectChanges();
  }

  subscribeToTypeFormControl(): void {
    this.getTypeFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((lawPersonType) =>
        this.toggleFormDisabledByLawPersonType(lawPersonType),
      );
  }

  clearValidatorsForInvisibleFormControls(): void {
    if (!this.isPhoneNumberVisible) {
      this.getPhoneNumberFormControl().clearValidators();
      this.getPhoneNumberFormControl().updateValueAndValidity();
    }
    if (!this.isEmailVisible) {
      this.getEmailFormControl().clearValidators();
      this.getEmailFormControl().updateValueAndValidity();
    }
  }

  getTypeFormControl(): UntypedFormControl {
    return this.investorDetailsFromGroup.get(
      LawPersonControlName.Type,
    ) as UntypedFormControl;
  }

  isNaturalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Natural;
  }

  isLegalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Legal;
  }

  getNaturalPersonFormGroup(): UntypedFormGroup {
    return this.investorDetailsFromGroup.get(
      LawPersonControlName.NaturalPersonData,
    ) as UntypedFormGroup;
  }

  getLegalPersonFormGroup(): UntypedFormGroup {
    return this.investorDetailsFromGroup.get(
      LawPersonControlName.LegalPersonData,
    ) as UntypedFormGroup;
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.investorDetailsFromGroup.get(
      LawPersonControlName.Address,
    ) as UntypedFormGroup;
  }

  getPhoneNumberFormControl(): UntypedFormControl {
    return this.investorDetailsFromGroup.get(
      this.investorDetailsControlName.PhoneNumber,
    ) as UntypedFormControl;
  }

  getEmailFormControl(): UntypedFormControl {
    return this.investorDetailsFromGroup.get(
      this.investorDetailsControlName.Email,
    ) as UntypedFormControl;
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string,
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(formControl && (formControl.dirty || formControl.touched));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(
    customPostOfficeModalHeaderInfo?: string,
  ): Observable<boolean> {
    return this.postOfficeModalComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(
      customPostOfficeModalHeaderInfo,
    );
  }

  copyDataFromPz(): void {
    if (!this.automaticCopyingDataFromPz) {
      return;
    }
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => {
        this.investorDetailsFromGroup.patchValue({
          [InvestorDetailsControlName.Email]: petentData.email,
          [InvestorDetailsControlName.PhoneNumber]: petentData.phone,
        });
        this.getNaturalPersonFormGroup().patchValue({
          [NaturalPersonControlName.FirstName]: petentData.firstname,
          [NaturalPersonControlName.LastName]: petentData.lastname,
          [NaturalPersonControlName.Pesel]: petentData.pesel,
        });
        this.getAddressFormGroup().patchValue({
          [LawPersonAddressControlName.Place]: new Place(
            petentData.placeId,
            petentData.place,
            true,
          ),
          [LawPersonAddressControlName.Street]: new Street(
            petentData.streetId,
            petentData.street,
          ),
          [LawPersonAddressControlName.PostalCode]: new PostalCode(
            petentData.postalCodeId,
            petentData.postalCode,
          ),
          [LawPersonAddressControlName.BuildingNumber]:
            petentData.buildingNumber,
          [LawPersonAddressControlName.LocalNumber]: petentData.localNumber,
          [LawPersonAddressControlName.StreetAbsence]: !petentData.street,
        });
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
