import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, skip, takeWhile } from 'rxjs/operators';
import { CommunicatorControlConfig } from '../gk-dynamic-list/gk-dynamic-list.model';
import { DataService } from '../gk-dynamic-list/services/data/data.service';
import {
  MessageAuthor,
  MessageFromApi,
  Messages,
  NewMessageDto,
  Recipient,
  RecipientFromApi,
} from './communicator.model';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'gk-app-modal-communicator',
  templateUrl: './modal-communicator.component.html',
  styleUrls: ['./modal-communicator.component.scss'],
})
export class ModalCommunicatorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private isAlive = true;
  messagesPending = false;
  title: string;
  newMessage: string;
  messages: Messages[];
  recipients: Recipient[];
  selectedRecipientId: string;
  isPendingMeeting = true;
  @Input() listItem: any;
  @Input() controlConfigs: CommunicatorControlConfig;
  @Input() refreshCallback: () => any;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @ViewChildren('messagesQueryList') messagesQueryList: QueryList<Messages>;

  constructor(
    public activeModal: NgbActiveModal,
    public dataService: DataService,
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.fetchRecipients();
    this.fetchMessages();
  }

  ngAfterViewInit(): void {
    this.registerScrollToLastMessage();
  }

  registerScrollToLastMessage(): void {
    this.messagesQueryList.changes
      .pipe(
        takeWhile(() => this.isAlive),
        skip(1),
      )
      .subscribe(() => {
        this.scrollToBottom();
      });
  }

  scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  setTitle(): void {
    const signature = this.getSignature();
    this.title = signature
      ? `Komunikator dla sprawy o sygnaturze ${signature}`
      : `Komunikator`;
  }

  getSignature(): string {
    return this.listItem.caseSignature;
  }

  fetchRecipients(): void {
    if (!this.controlConfigs.recipientRequestConfig) {
      return;
    }
    this.dataService
      .getData(this.controlConfigs.recipientRequestConfig, this.listItem)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((recipients) => {
        this.selectedRecipientId = recipients.KoncowyAdresatUuid;
        this.recipients = recipients.Adresaci.map(
          (recipient: RecipientFromApi) => Recipient.fromApiToApp(recipient),
        );
      });
  }

  fetchMessages(): void {
    combineLatest([
      this.dataService
        .getData<
          Array<MessageFromApi>
        >(this.controlConfigs.messagesRequestConfig, this.listItem)
        .pipe(map((data) => data.map((item) => Messages.fromApiToApp(item)))),
      this.checkIsMeetingPending(),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: ([messages, isMeetingPending]) => {
          this.messages = messages;
          this.handleRefreshCallback();
          this.messagesPending = false;
          this.isPendingMeeting = isMeetingPending;
          setTimeout(() => {
            this.scrollToBottom();
          }, 1);
        },
        error: () => (this.messagesPending = false),
      });
  }

  checkIsMeetingPending(): Observable<boolean> {
    return this.dataService.getData(
      this.controlConfigs.isPendingRequestConfig,
      this.listItem,
    );
  }

  sendMessage(): void {
    this.messagesPending = true;
    this.dataService
      .getData(
        this.controlConfigs.addMessageRequestConfig,
        this.completeDataToDto(),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.resetForm();
        this.fetchMessages();
      });
  }

  handleRefreshCallback(): void {
    if (this.refreshCallback) {
      this.refreshCallback();
    }
  }

  resetForm(): void {
    this.newMessage = '';
  }

  completeDataToDto(): NewMessageDto {
    return {
      ...this.listItem,
      newMessage: this.newMessage,
      recipient: this.selectedRecipientId,
    };
  }

  handleSendMessage(event: Event): void {
    event.preventDefault();
    this.sendMessage();
  }

  getMessageBackgroundColorClass(author: MessageAuthor): string {
    if (this.controlConfigs.extendedColourCodedMessagesByAuthor) {
      return this.getMessageBackgroundColorClassBySpecificAuthor(author);
    } else {
      return this.getMessageBackgroundColorClassByTypeOfMessageAuthor(author);
    }
  }

  getMessageBackgroundColorClassBySpecificAuthor(author: MessageAuthor) {
    switch (author) {
      case MessageAuthor.Odgik:
        return 'message-color-red';
      case MessageAuthor.CoordinationMeeting:
        return 'message-color-green';
      case MessageAuthor.DesignerPortal:
        return 'message-color-blue';
      default:
        return '';
    }
  }

  getMessageBackgroundColorClassByTypeOfMessageAuthor(
    author: MessageAuthor,
  ): string {
    switch (author) {
      case MessageAuthor.DesignerPortal:
      case MessageAuthor.Surveyor:
        return 'message-color-blue';
      default:
        return 'message-color-red';
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
