import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { FileExtension } from '../../../../utils/files/files.model';
import { getFileSizeString } from '../../../../utils/files/files.util';
import { InputFileControlConfig } from '../../../gk-dynamic-list.model';
import { ConfirmationDocumentService } from '../../../services/confirmation-document/confirmation-document.service';
import { DataService } from '../../../services/data/data.service';
import { DynamicListManagerService } from '../../../services/dynamic-list-manager/dynamic-list-manager.service';
import { DynamicListService } from '../../../services/dynamic-list/dynamic-list.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-input-file',
  templateUrl: './control-input-file.component.html',
  styleUrls: ['./control-input-file.component.scss'],
})
export class ControlInputFileComponent extends Control<any> implements OnInit {
  @Input()
  override controlConfig: InputFileControlConfig;
  fileInputId = `${new Date().getTime()}`;
  isUploading = false;
  dropText = '';
  inputFileTranslations: { [key: string]: string };
  file: File;
  lastInvalids: { file: File; type: string }[] = [];
  resSucceeded: boolean;
  resFailed: boolean;
  noFileChosenInfo: boolean;

  constructor(
    private confirmationDocumentService: ConfirmationDocumentService,
    private dynamicListManagerService: DynamicListManagerService,
    private sanitizer: DomSanitizer,
    private dynamicListService: DynamicListService,
    protected override translateService: TranslateService,
    protected override dataService: DataService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.subToDefaultDropText();
  }

  subToDefaultDropText(): void {
    this.translateService
      .get('GK.DYNAMIC_LIST.CONTROL_INPUT_FILE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translations) => {
        this.inputFileTranslations = translations;
        this.updateDropText();
      });
  }

  getAcceptedMimeTypes(): string {
    return this.controlConfig.mimeTypes
      ? this.controlConfig.mimeTypes.join(',')
      : '';
  }

  getObjectUrl(file: File): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(file),
    );
  }

  removeFile(): void {
    this.file = undefined;
    this.handleFileInputChangeAction();
  }

  updateDropText(): void {
    this.dropText =
      (this.file && this.file.name) ||
      _.get(this.inputFileTranslations, 'DROP_TEXT', '');
  }

  handleFileInputChangeAction(): void {
    this.updateDropText();
    this.resetInfos();
    this.uploadFile();
  }

  getCurrentFileSizeString(): string {
    const fileSize = this.file && this.file.size;
    return fileSize ? getFileSizeString(fileSize) : '';
  }

  getFileFormattedExtension(ext: FileExtension): string {
    return `*.${ext}`;
  }

  getAllAvailableFormattedFileExtensions(): string {
    return this.controlConfig.extensions.reduce(
      (formattedExts, currentExt) =>
        formattedExts
          ? `${formattedExts}, ${this.getFileFormattedExtension(currentExt)}`
          : this.getFileFormattedExtension(currentExt),
      '',
    );
  }

  getGeneratedInvalidTypeText(): string {
    const defaultInvalidText = _.get(
      this.inputFileTranslations,
      'INVALID_EXTENSION',
      '',
    );
    const possibleExtensionsText = _.get(
      this.inputFileTranslations,
      'POSSIBLE_EXTENSIONS',
      '',
    );
    return `${defaultInvalidText} ${possibleExtensionsText} ${this.getAllAvailableFormattedFileExtensions()}`;
  }

  resetInfos(): void {
    this.resFailed = false;
    this.resSucceeded = false;
    this.noFileChosenInfo = false;
  }

  isExtensionValid(): boolean {
    if (_.isEmpty(this.controlConfig.extensions)) {
      return true;
    }

    const currentExt = this.file.name.split('.').pop();
    return _.some(this.controlConfig.extensions, (ext) => ext === currentExt);
  }

  handleFailedUpload = (): void => {
    this.resFailed = true;
    this.isUploading = false;
  };

  uploadFile(): void {
    this.resetInfos();

    if (!this.file || !this.isExtensionValid()) {
      this.noFileChosenInfo = true;
      return;
    }

    this.isUploading = true;

    this.confirmationDocumentService
      .sendFile(this.controlConfig.apiUrl, this.file, this.getValue())
      .then((request) =>
        request.pipe(takeWhile(() => this.isAlive)).subscribe({
          next: () => {
            this.isUploading = false;
            this.resSucceeded = true;
            this.dynamicListManagerService.refreshDocumentsListControl.next();
            this.dynamicListService.handlePanelReload();
          },
          error: () => this.handleFailedUpload(),
        }),
      )
      .catch(() => this.handleFailedUpload());
  }
}
