import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PprfParcelFormModel } from './pprf-parcel-form.model';
import { PprfParcelFormService } from './pprf-parcel-form.service';

@Component({
  selector: 'gk-pprf-parcel-form',
  templateUrl: './pprf-parcel-form.component.html',
})
export class PprfParcelFormComponent {
  @Input() form: FormGroup;
  @Input() formModel: PprfParcelFormModel;
  formlyFormConfig = this.pprfParcelFormService.getFormConfig();

  constructor(private pprfParcelFormService: PprfParcelFormService) {}
}
