import { ApiFilter } from '../../gk-dynamic-list/services/services.model';

export enum ShippingFormName {
  SelfPickup = 'odbiór osobisty',
  PostOffice = 'poczta',
  EServicesInbox = 'skrzynka odbiorcza e-usługi',
}

export interface ShippingFormDto {
  Id: number;
  Nazwa: string;
  IsPostalDataRequired: boolean;
  IsFtpRequired: boolean;
  IsEmailRequired: boolean;
}

export class ShippingForm {
  constructor(
    public id: number,
    public name: string,
    public postalDataRequired: boolean,
    public emailRequired: boolean,
    public ftpAddressRequired?: boolean,
    public electronicPosition?: boolean,
  ) {}

  static fromApiToApp(data: ShippingFormDto): ShippingForm {
    return new this(
      data.Id,
      data.Nazwa,
      data.IsPostalDataRequired,
      data.IsEmailRequired,
      data.IsFtpRequired,
      electronicReceivingMethods.includes(data.Id),
    );
  }
}

export enum ShippngFormDataType {
  Personal = 1,
  NotPersonal,
}

export enum ReceivingMethod {
  Personally = 1,
  Post = 3,
  EServiceInbox = 6,
  SharingNetworkService = 7,
  SharingFTPServerOfAuthority = 8,
  ShipmentToIndicatedEmail = 9,
}

export const electronicReceivingMethods = [
  ReceivingMethod.EServiceInbox,
  ReceivingMethod.SharingNetworkService,
  ReceivingMethod.SharingFTPServerOfAuthority,
  ReceivingMethod.ShipmentToIndicatedEmail,
];

export enum ReceivingTypesItemForms {
  Electronic = 1,
  Printed = 2,
  Mixed = 3,
}

export enum ReceivingType {
  NoDefined = 0,
  Personally = 1,
  Post = 3,
  EServiceInbox = 6,
  SharingNetworkService = 7,
  SharingFTPServerOfAuthority = 8,
  ShipmentToIndicatedEmail = 9,
}

export interface ShopFeePositions {
  PozycjeOplaty: ShopPosition[];
  SumaOplat: number;
}

export interface ShopPosition extends BaseShopPosition {
  PozUuid?: string;
  Nazwa?: string;
  JednName?: string;
  JednNamePl?: string;
  JednNameEn?: string;
  Cena?: number;
  IleJedn?: number;
  CzyCelWymagany?: boolean;
  TabId?: number;
  PrfxSprId?: number;
  RawHtmlFiles?: File[];
  Kryteria?: ApiFilter[];
}

export interface ApiAppAddress {
  Kraj?: string;
  Wojewodztwo?: string;
  Powiat?: string;
  Gmina?: string;
  NumerBudynku: string;
  NumerLokalu?: string;
  Miejscowosc: DictNullableIdDto;
  Ulica: DictNullableIdDto;
  KodPocztowy: DictNullableIdDto;
}

export interface ApiAppPerson {
  Skrot?: string;
  Imie: string;
  Nazwisko: string;
  Pesel?: string;
  Nip?: string;
  Regon?: number | string;
  Email: string;
  Telefon: string;
  NrKonta?: string;
}

export interface DictNullableIdDto {
  Id?: number;
  Nazwa: string;
}

export interface BaseShopPosition {
  PozId?: number;
  IdJedn?: PositionIdUnit;
  IleJedn?: number;
  Opis?: string;
  Szczegoly?: string;
  Uwagi?: string;
  Objects?: PozycjaObjectDto[];
  PostacElektroniczna?: boolean;
  ZbiorDanych?: boolean;
  DaneOs?: boolean;
  Form?: string;
}

export enum PositionIdUnit {
  SquareKilometer = 1,
  MapSheetOneDesignation = 2,
  Photo = 3,
  Photopoint = 4,
  GeodeticControlPoint = 5,
  Hectare = 6,
  BorderPoint = 7,
  LandParcel = 8,
  Building = 9,
  Premises = 10,
  LandUseContour = 11,
  ClassificationContour = 12,
  SubjectPersonOrganizationUnit = 13,
  EgibDatabaseObject = 14,
  A0Sheet = 15,
  District = 16,
  LandParcelBordersDistrict = 17,
  PersonOtherSubject = 18,
  Community = 19,
  County = 20,
  Country = 21,
  Voivodship = 22,
  LandRegistryUnit = 23,
  BuildingsRegistryUnit = 24,
  PremisesRegistryUnit = 25,
  ItemBuildingsFile = 26,
  ItemPremisesFile = 27,
  RealEstateObjectTransactionValuation = 28,
  MapSheet = 29,
  SituationPoint = 30,
  A4Sheet = 31,
  Document = 32,
  FirstCopyAuthorizedDocument = 33,
  NextCopiesAuthorizedDocument = 34,
  DocumentsCadastralUnit4Hours = 35,
  ProjectedTerritorialDevelopmentNetwork = 36,
  OneYearService = 37,
  SubjectPersonOrganization = 38,
  Fee = 10001,
}

export interface PozycjaObjectDto {
  ObjectId: string | number;
  Type: string;
  Area?: string;
  Geom?: string;
}
