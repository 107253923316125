import { WebPortal } from '@gk/gk-modules';

const DESIGNER_PORTAL_ID = 9;
const PRZP_PORTAL_ID = 10;

export class PortalSettingsAccess {
  constructor(
    public przpAccess: boolean,
    public designerAccess: boolean,
  ) {}

  static getPortalSettingsAccess(webPortal: WebPortal[]): PortalSettingsAccess {
    return new this(
      webPortal.some((portal) => portal.portalId === PRZP_PORTAL_ID),
      webPortal.some((portal) => portal.portalId === DESIGNER_PORTAL_ID),
    );
  }
}

export interface TranslationVisibilityPortal {
  isVisibleGr: boolean;
  isVisibleEn: boolean;
  isVisibleCz: boolean;
  isVisibleTranslate: boolean;
}
