import { FileEdgeState, MemoryUnit } from './files.model';

export const getFileSizeInKB = (sizeInBytes: number): number =>
  Math.round(sizeInBytes / 1024);

export const getFileSizeString = (sizeInBytes: number): string => {
  return sizeInBytes >= 1024
    ? `${getFileSizeInKB(sizeInBytes)}${MemoryUnit.KiloByte}`
    : `${sizeInBytes}${MemoryUnit.Byte}`;
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const finalBase = (reader.result as string).split(',')[1];
      if (finalBase) {
        resolve(finalBase);
      } else {
        reject('Empty file');
      }
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const finalBase = (reader.result as string).split(',')[1];
      if (finalBase) {
        resolve(finalBase);
      } else {
        reject('Empty blob');
      }
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};

export const stringifyFile = async (file: File): Promise<string> => {
  if (!file) {
    return '';
  }

  const fileToStringify: FileEdgeState = {
    lastModified: file.lastModified,
    name: file.name,
    size: file.size,
    type: file.type,
    base64: await fileToBase64(file),
  };

  return await JSON.stringify(fileToStringify);
};

export const b64toBlob = async (
  b64Data: string,
  contentType = '',
  sliceSize = 512,
): Promise<Blob> => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const parseFile = async (file: string): Promise<Blob> => {
  const fileEdgeState: FileEdgeState = JSON.parse(file);
  const blob = await b64toBlob(fileEdgeState.base64, fileEdgeState.type);
  (blob as any).lastModified = fileEdgeState.lastModified;
  (blob as any).name = fileEdgeState.name;

  return blob;
};

export const b64EncodeUnicode = (str: string): string => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_match, p1) => {
      return String.fromCharCode(parseInt(p1, 16));
    }),
  );
};

export const b64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c: string) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
};

export const createUuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};
