<div class="d-flex column-gap-2 row-gap-2" [ngClass]="flexDirectionClass">
  <div class="d-flex flex-fill align-items-center">
    <label class="m-0 pe-2" for="{{ id }}-filterRightOperandFrom">{{
      'DATE_RANGE_PICKER.FROM' | translate
    }}</label>
    <div class="input-group">
      <input
        class="form-control form-control form-control-sm"
        [ngClass]="{ required: required }"
        [attr.aria-required]="required"
        [outsideDays]="'hidden'"
        [minDate]="getMinFromDate()"
        [maxDate]="toDate"
        name="dpFrom"
        id="{{ id }}-filterRightOperandFrom"
        [(ngModel)]="fromDate"
        (ngModelChange)="handleDateChanged()"
        ngbDatepicker
        #dateFrom="ngbDatepicker"
        attr.placeholder="{{ 'DATE_RANGE_PICKER.PLACEHOLDER' | translate }}"
      />
      <button
        class="btn btn-outline-secondary fa fa-calendar"
        (click)="dateFrom.toggle()"
        type="button"
      ></button>
    </div>
  </div>

  <div class="d-flex flex-fill align-items-center">
    <label class="m-0 pe-2" for="{{ id }}-filterRightOperandTo">{{
      'DATE_RANGE_PICKER.TO' | translate
    }}</label>
    <div class="input-group">
      <input
        class="form-control form-control form-control-sm"
        [ngClass]="{ required: required }"
        [attr.aria-required]="required"
        [outsideDays]="'hidden'"
        [minDate]="getMinToDate()"
        [maxDate]="getMaxToDate()"
        name="dpTo"
        id="{{ id }}-filterRightOperandTo"
        [(ngModel)]="toDate"
        (ngModelChange)="handleDateChanged()"
        ngbDatepicker
        #dateTo="ngbDatepicker"
        attr.placeholder="{{ 'DATE_RANGE_PICKER.PLACEHOLDER' | translate }}"
      />
      <button
        class="btn btn-outline-secondary fa fa-calendar"
        (click)="dateTo.toggle()"
        type="button"
      ></button>
    </div>
  </div>

  <div>
    <button
      type="button"
      class="btn btn-sm btn-outline-danger text-nowrap"
      (click)="resetPeriod()"
    >
      {{ 'DATE_RANGE_PICKER.RESET_PERIOD' | translate }}
    </button>
  </div>
</div>
