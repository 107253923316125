import { Injectable } from '@angular/core';
import { GkKendoMessagesListGridDto } from './gk-kendo-messages-list-grid-data-model';
import { GridDataService } from '../../services/grid-data.service';
import { Observable } from 'rxjs';
import { BasicResponse } from '../../../utils/basic-response/basic-response.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})
export class GkKendoMessagesListGridDataService extends GridDataService<GkKendoMessagesListGridDto> {
  constructor(private httpClient: HttpClient) {
    super(undefined, 'GET');
  }

  getNewMessagesStatus(url: string): Observable<boolean> {
    return this.httpClient
      .get<BasicResponse<boolean>>(url)
      .pipe(map((response) => response.Result));
  }
  getPendingNewMessagesStatus(): Observable<boolean> {
    return this.httpClient
      .get<
        BasicResponse<boolean>
      >(`/api/geodeta/komunikator/prace/oczekujace/statusnowychwiad`)
      .pipe(map((response) => response.Result));
  }
}
