import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { LoginService } from '@gk/gk-modules';
import * as _ from 'lodash';
import { Observable, catchError, combineLatestWith, map, of } from 'rxjs';
import { PortalConfigService } from '../../services/portal-config/portal-config.service';
import { SessionService } from '../../services/session/session.service';
import { MainRoutes, PzInitRoutes } from '../guard.models';

@Injectable()
export class SessionGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private loginService: LoginService,
    private portalConfigService: PortalConfigService,
  ) {}

  private readonly routeMapping: { [key in MainRoutes]?: PzInitRoutes } = {
    [MainRoutes.Designer]: PzInitRoutes.Designer,
    [MainRoutes.Przp]: PzInitRoutes.Przp,
    [MainRoutes.GrantingRights]: PzInitRoutes.GrantingRights,
    [MainRoutes.NetworkServiceOrder]: PzInitRoutes.NetworkServiceOrder,
    [MainRoutes.RoadExitPortal]: PzInitRoutes.RoadExit,
    [MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse]:
      PzInitRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse,
    [MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse]:
      PzInitRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse,
    [MainRoutes.LaneOccupancyPortalInfrastructureDevices]:
      PzInitRoutes.LaneOccupancyInfrastructureDevices,
    [MainRoutes.LaneOccupancyPortalBuildingObjects]:
      PzInitRoutes.LaneOccupancyBuildingObjects,
    [MainRoutes.LaneOccupancyPortalBuildingWorks]:
      PzInitRoutes.LaneOccupancyBuildingWorks,
    [MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest]:
      PzInitRoutes.LandAndBuildingRegisterDataChangesNotificationRequest,
    [MainRoutes.LandAndBuildingRegisterDataUpdateRequest]:
      PzInitRoutes.LandAndBuildingRegisterDataUpdateRequest,
    [MainRoutes.SoilClassificationOfLandRequest]:
      PzInitRoutes.SoilClassificationOfLandRequest,
    [MainRoutes.PresentationCommentsLandBuildingRegistryProject]:
      PzInitRoutes.PresentationCommentsLandBuildingRegistryProject,
    [MainRoutes.AccessToInformationOnEnvironmentAndItsProtection]:
      PzInitRoutes.AccessToInformationOnEnvironmentAndItsProtection,
    [MainRoutes.BuildingPermit]: PzInitRoutes.BuildingPermit,
    [MainRoutes.DemolitionPermit]: PzInitRoutes.DemolitionPermit,
    [MainRoutes.BuildingOrPartUseChange]: PzInitRoutes.BuildingOrPartUseChange,
    [MainRoutes.TemporaryStructureBuildingPermit]:
      PzInitRoutes.TemporaryStructureBuildingPermit,
    [MainRoutes.BuildingPermitModificationDecision]:
      PzInitRoutes.BuildingPermitModificationDecision,
    [MainRoutes.NotObjectedNotificationRightsObligationsTransfer]:
      PzInitRoutes.NotObjectedNotificationRightsObligationsTransfer,
    [MainRoutes.PlotLandDevArchConstrProjSeparateDecision]:
      PzInitRoutes.PlotLandDevArchConstrProjSeparateDecision,
    [MainRoutes.AdjacentPropertyNeedEnterDecision]:
      PzInitRoutes.AdjacentPropertyNeedEnterDecision,
    [MainRoutes.EnvironmentalProtectionPortalFishingCardRequest]:
      PzInitRoutes.EnvironmentalProtectionPortalFishingCardRequest,
    [MainRoutes.ConstructionPortalConstructionNotificationRequest]:
      PzInitRoutes.ConstructionPortalConstructionNotificationRequest,
    [MainRoutes.ConstructionPortalDemolitionNotificationRequest]:
      PzInitRoutes.ConstructionPortalDemolitionNotificationRequest,
    [MainRoutes.ConstructionPortalPremiseIndependenceRequest]:
      PzInitRoutes.ConstructionPortalPremiseIndependenceRequest,
    [MainRoutes.ConstructionPortalConstructionLogRequest]:
      PzInitRoutes.ConstructionPortalConstructionLogRequest,
    [MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest]:
      PzInitRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest,
    [MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest]:
      PzInitRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest,
    [MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest]:
      PzInitRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest,
    [MainRoutes.BuildingPermitTransfer]: PzInitRoutes.BuildingPermitTransfer,
    [MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan]:
      PzInitRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest,
    [MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan]:
      PzInitRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest,
    [MainRoutes.PlanningDepartmentExtractFromStudy]:
      PzInitRoutes.PlanningDepartmentExtractFromStudy,
    [MainRoutes.SurveyorPortal]: PzInitRoutes.PzInitSystemUrl,
    [MainRoutes.AppraiserPortal]: PzInitRoutes.PzInitSystemUrl,
    [MainRoutes.AddressNumberRequestPortal]:
      PzInitRoutes.AddressNumberRequestPortal,
    [MainRoutes.OwnershipConversion]: PzInitRoutes.OwnershipConversion,
    [MainRoutes.PropertyLease]: PzInitRoutes.PropertyLease,
    [MainRoutes.SupplementaryParcelPurchase]:
      PzInitRoutes.SupplementaryParcelPurchase,
    [MainRoutes.StreetNaming]: PzInitRoutes.StreetNaming,
    [MainRoutes.CommunalPremisePurchase]: PzInitRoutes.CommunalPremisePurchase,
    [MainRoutes.SingleFamilyBuilding70ConstructionConversion]:
      PzInitRoutes.SingleFamilyBuilding70ConstructionConversion,
    [MainRoutes.SingleFamilyBuildingConstructionConversion]:
      PzInitRoutes.SingleFamilyBuildingConstructionConversion,
    [MainRoutes.GeodeticAndCartographicServiceDataRequest]:
      PzInitRoutes.GeodeticAndCartographicServiceDataRequest,
  };

  authenticate(url: UrlSegment[]): void {
    const path = _.get(url, '[0].path', '') as MainRoutes;
    const currentPzInitUrl = this.routeMapping[path];

    if (currentPzInitUrl) {
      this.loginService.navigateToExternalLogin(currentPzInitUrl);
    } else {
      this.navigateToNotFound();
    }
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const path = _.get(route.url, '[0].path', '') as MainRoutes;
    return this.sessionService.getSystemSession().pipe(
      combineLatestWith(
        this.portalConfigService.getPortalConfByMainRoute(path),
      ),
      map(([sessionState, portalConfig]) => {
        const path = _.get(route.url, '[0].path', '') as MainRoutes;

        if (
          portalConfig?.isPerunAuthenticationAvailable &&
          !sessionState.isAuthenticated()
        ) {
          this.navigateToSignInPage(path);
          return false;
        }

        if (
          (path === MainRoutes.Designer ||
            portalConfig?.isPerunAuthenticationAvailable) &&
          sessionState.isPerunAuthenticated()
        ) {
          return true;
        }

        if (sessionState.isPzAuthenticated()) {
          return true;
        }

        this.authenticate(route.url);
        return false;
      }),
      catchError(() => {
        this.navigateToNotFound();
        return of(false);
      }),
    );
  }

  navigateToSignInPage(portalRoute: MainRoutes): void {
    this.router.navigateByUrl(`/${portalRoute}/${MainRoutes.SignIn}`);
  }

  navigateToNotFound(): void {
    this.router.navigateByUrl(`/${MainRoutes.NotFound}`);
  }
}
