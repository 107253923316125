import { Type } from '@angular/core';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/kendo/form-field';
import { FormlyFieldInput } from '@ngx-formly/kendo/input';
import { FormlyFieldRadio } from '@ngx-formly/kendo/radio';
import { FormlyFieldKendoSelectComponentProps } from './select.type';

export interface InputProps extends FormlyFieldProps {
  percent?: boolean;
  buttonConfig?: ButtonConfig;
}

export interface ButtonConfig {
  text: string;
  click: () => void;
  disabled?: boolean | (() => boolean);
}

export interface InputAutocompleteProps extends FormlyFieldProps {
  loading: boolean;
  valueField: string;
  textField: string;
  onFilterChange(
    $term: string,
    field: FieldTypeConfig<InputAutocompleteProps>,
  ): void;
}

export interface FormlyKendoInputFieldConfig
  extends FormlyFieldConfig<InputProps> {
  type: 'kendo-input' | Type<FormlyFieldInput>;
}

export interface FormlyKendoSelectFieldConfig
  extends FormlyFieldConfig<FormlyFieldKendoSelectComponentProps> {
  type: FormFieldType.KendoSelect | Type<FormlyFieldInput>;
}

export type RadioProps = FormlyFieldProps;

export interface FormlyKendoRadioFieldConfig extends FormlyFieldConfig {
  type: 'kendo-radio' | Type<FormlyFieldRadio>;
}

export enum FormFieldType {
  KendoInput = 'kendo-input',
  KendoRadio = 'kendo-radio',
  KendoSelect = 'kendo-select',
  MultiSelect = 'kendo-multiselect',
  KendoCheckbox = 'kendo-checkbox',
  KendoDatepicker = 'kendo-datepicker',
  KendoDateRangePicker = 'kendo-date-range-picker',
  RangePicker = 'range-picker',
  Textarea = 'textarea',
  KendoInputAutocomplete = 'kendo-input-autocomplete',
  KendoCombobox = 'kendo-combobox',
  KendoButton = 'kendo-button',
  KendoSelectRange = 'kendo-select-range',
}

export enum FormFieldOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum FormFieldWrappers {
  FormField = 'form-field',
  RadioList = 'radio-list',
  KendoPanel = 'kendo-panel',
}
