import { AttachmentFileInputId } from '../new-designer-request/new-designer-request.model';

export enum UploadingState {
  Waiting = 0,
  Uploading = 1,
  Failed = 2,
  Success = 3,
}

export interface FileWithCustomUploading extends File {
  uploadingState?: UploadingState;
  docType?: number;
  description?: string;
  progress?: number;
}

export class ApiNewDokumentPowiazanyDalDto {
  constructor(
    public NazwaPliku: string,
    public Plik: string,
    public RodzajOpracowaniaDokumentu: number | string,
    public Opis: string,
  ) {}
}

export class AttachmentEvent {
  constructor(
    public fileInputId: AttachmentFileInputId,
    public payload?: File,
  ) {}
}
