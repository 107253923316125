import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { NgbDateRange } from '../../../services/new-request/new-request.model';
import { PlaceService } from '../../../services/place/place.service';
import { PropertyLeaseControlName } from '../../../services/property-lease-form/property-lease-form.model';
import { PropertyLeaseFormService } from '../../../services/property-lease-form/property-lease-form.service';
import { PropertyLease } from '../../../services/property-lease/property-lease.model';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';

@Component({
  selector: 'app-new-property-lease-request',
  templateUrl: './new-property-lease-request.component.html',
  styleUrls: ['./new-property-lease-request.component.scss'],
})
export class NewPropertyLeaseRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override readonly controlName = PropertyLeaseControlName;
  readonly mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('areaInSquareMeters', 'GK.MAP.AREA_IN_SQUARE_METERS'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  override portalId = PortalId.PropertyLease;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    private propertyLeaseFormService: PropertyLeaseFormService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override streetService: StreetService,
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override translateService: TranslateService,
    private toastr: ToastrService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.fetchApplicantMapSettings();
    this.subscribeToDocSignTranslations();
    this.fetchWrongFileText();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.PropertyLease,
      this.mapStateService.getViewState(MapId.PropertyLease, mapSettings),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon, ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.PropertyLease,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  createForm(): void {
    this.formGroup = this.propertyLeaseFormService.getFormGroup();
    this.subscribeToDecisionFormRadioFormControl();
  }

  override shouldShowRequiredAlert(formControl: AbstractControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      this.submitted,
    );
  }

  isPurposeFormControlValid(): boolean {
    return this.getPurposeFormControl().valid;
  }

  getPurposeFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.Purpose);
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  areAreaFormControlsValid(): boolean {
    return this.isAreaFormControlValid() && this.areLandParcelsChosen();
  }

  areLandParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  isAreaFormControlValid(): boolean {
    return this.getAreaFormControl().valid;
  }

  getAreaFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.AreaToLease);
  }

  isDateRangeInvalid(): boolean {
    return !this.isDateRangeCorrect() || this.isDateRangeEmpty();
  }

  isDateRangeCorrect(): boolean {
    const formControlValue = this.getLeaseDateFormControl().value || {};

    return DateHelperUtil.isToDateNotLessThanFromDate(
      formControlValue.from,
      formControlValue.to,
    );
  }

  isDateRangeEmpty(): boolean {
    const formControlValue = this.getLeaseDateFormControl().value || {};

    return _.isEmpty(formControlValue.from) && _.isEmpty(formControlValue.to);
  }

  updateDateRangeFormControl(dateRange: NgbDateRange): void {
    this.getLeaseDateFormControl().setValue(dateRange);
  }

  getLeaseDateFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.LeaseDate);
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup() as UntypedFormGroup);
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areLandParcelsChosen() &&
      !this.isDateRangeInvalid() &&
      this.isAreaFormControlValid()
    );
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            PropertyLease.fromAppToApi(
              this.getFormValue(),
              this.getIssueRanges(),
              this.getLandParcels(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/wydzierzawienieNieruchomosci/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getFormValue(): PropertyLease {
    return this.formGroup.getRawValue();
  }

  getIssueRanges(): string[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects]
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  getLandParcels(): ZamowienieDzialkaDto[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects]
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        ),
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data));
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl('/property-lease/requests-list');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
