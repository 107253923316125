import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/kendo/form-field';

interface DatePickerAreaProps extends FormlyFieldProps {
  minDate: Date;
  maxDate: Date;
}

@Component({
  selector: 'gk-formly-field-kendo-datepicker',
  template: `
    <kendo-datepicker
      calendarType="classic"
      [size]="'small'"
      [ngClass]="{ required: props.required }"
      [min]="props.minDate"
      [max]="props.maxDate"
      [formControl]="formControl"
      [formlyAttributes]="field"
      format="yyyy-MM-dd"
      [placeholder]="props.placeholder | translate"
    ></kendo-datepicker>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldKendoDatepickerComponent extends FieldType<
  FieldTypeConfig<DatePickerAreaProps>
> {}
