@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label
      >:</span
    >
    <span
      gkControlValue
      [showLabel]="controlConfig.showLabel"
      [ngbTooltip]="controlTextTooltip"
      [disableTooltip]="!isTooltipEnabled()"
      tooltipClass="horizontal-tooltip"
    >
      @if (isControlValueString()) {
        {{ getValue() | slice: 0 : getSliceCount() }}
        @if (isTooltipEnabled()) {
          ...
        }
      } @else {
        {{ getValue() }}
      }
    </span>
  </div>
  <ng-template #controlTextTooltip
    ><p [innerHTML]="getValue()"></p
  ></ng-template>
}
