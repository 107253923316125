import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import {
  InterModLoginType,
  InterModLoginTypeDto,
} from './intermod-login-type.model';

@Injectable({
  providedIn: 'root',
})
export class InterModLoginTypeService {
  private availableTypesCache$: Observable<InterModLoginType>;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {}

  getAvailableTypes(): Observable<InterModLoginType> {
    if (!this.availableTypesCache$) {
      this.availableTypesCache$ = this.httpClient
        .get<InterModLoginTypeDto>(
          '/api/system/eservice/intermapintermod/logowanie/rodzaje',
        )
        .pipe(
          map((data) => InterModLoginType.fromApiToApp(data)),
          tap((data) => {
            if (!data || (!data.ewidLogging && !data.wkLogging)) {
              this.router.navigateByUrl(`/${MainRoutes.NotFound}`);
            }
          }),
          shareReplay(1),
        );
    }

    return this.availableTypesCache$;
  }
}
