import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DraftJsonBody, DraftType } from './draft-manager.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DialogManagerService } from '../../gk-kendo';

@Injectable()
export class DraftManagerService {
  draftUrl = '/api/system/cache/draft';

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private dialogManagerService: DialogManagerService,
  ) {}

  public createDraft<T>(draftType: DraftType, data: T): void {
    const confirmConfig = {
      contentKey: 'DRAFT_MANAGER.CREATE_DRAFT_CONFIRMATION',
      width: 340,
      height: 180,
      confirmSuccessCallback: (): void => {
        this.submitDraft(draftType, data).subscribe(() => {
          this.toastr.success(
            this.translateService.instant('DRAFT_MANAGER.CREATE_SUCCESS'),
          );
        });
      },
    };
    this.dialogManagerService.showConfirmation(confirmConfig);
  }

  public loadDraft<U>(
    draftType: DraftType,
    successCallback: (data: U) => void,
    contentKey = 'DRAFT_MANAGER.OVERWRITE_DRAFT_CONFIRMATION',
  ): void {
    const confirmConfig = {
      contentKey,
      width: 340,
      height: 180,
      confirmSuccessCallback: (): void => {
        this.getDraft(draftType).subscribe((data) => {
          this.toastr.success(
            this.translateService.instant('DRAFT_MANAGER.LOAD_SUCCESS'),
          );
          successCallback(JSON.parse(data) as U);
        });
      },
    };
    this.dialogManagerService.showConfirmation(confirmConfig);
  }

  private submitDraft<T>(draftType: DraftType, data: T): Observable<void> {
    return this.http.post<void>(
      this.draftUrl,
      this.getDraftJsonBody(draftType, data),
    );
  }

  private getDraft(draftType: DraftType): Observable<string> {
    return this.http.get<string>(`${this.draftUrl}/${draftType}`);
  }

  private getDraftJsonBody<T>(draftType: DraftType, data: T): DraftJsonBody {
    return {
      Type: draftType,
      Value: JSON.stringify(data),
    };
  }
}
