import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { DepartmentCode } from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatestWith, map } from 'rxjs';
import { PortalConfigService } from '../../services/portal-config/portal-config.service';
import { SessionService } from '../../services/session/session.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class PerunSessionGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private portalConfigService: PortalConfigService,
    private translateService: TranslateService,
    private toastr: ToastrService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const path = _.get(route.url, '[0].path', '') as MainRoutes;
    return this.sessionService.getSystemSession().pipe(
      combineLatestWith(
        this.portalConfigService.getPortalConfByMainRoute(path),
      ),
      map(([sessionState]) => {
        if (!sessionState.isPerunAuthenticated()) {
          this.navigateToSignInPage(path);
          return false;
        }

        return true;
      }),
    );
  }

  navigateToGeodesyDepartment(): void {
    this.router.navigate([`/${MainRoutes.Department}`, DepartmentCode.Geodesy]);
  }

  navigateToSignInPage(portalRoute: MainRoutes): void {
    this.router.navigateByUrl(`/${portalRoute}/${MainRoutes.SignIn}`);
  }

  showAccessWarning(): void {
    this.toastr.warning(this.translateService.instant('NO_ACCESS'));
  }
}
