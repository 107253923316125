<button
  [ngClass]="buttonClass"
  [disabled]="
    (!invoices.length && !documentSectionId) ||
    (paymentService.isPaymentInProgress | async)
  "
  (click)="pay()"
  [attr.aria-label]="'GK.DYNAMIC_LIST.MAKE_PAYMENT' | translate"
  [attr.title]="'GK.DYNAMIC_LIST.MAKE_PAYMENT' | translate"
>
  @if (buttonIconVisible) {
    <i class="fa fa-credit-card" aria-hidden="true"></i>
  }
  {{ 'GK.DYNAMIC_LIST.MAKE_PAYMENT' | translate }}
</button>
@if (paymentInfoTooltipVisible) {
  <i
    class="bi bi-question-circle ms-1"
    placement="top-right"
    [ngbTooltip]="'GK.DYNAMIC_LIST.TRADITIONAL_TRANSFER_INFO' | translate"
  ></i>
}
