import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  PopupSettings,
  ToolBarComponent,
} from '@progress/kendo-angular-toolbar';
import {
  ExtendedGkKendoGridComponent,
  GenericToolbarModifyGeometryPayload,
  GenericToolbarShowGeometryPayload,
  GridToolBarItem,
  GridToolBarItemType,
} from '../gk-kendo-grid.model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { SVGIcon } from '@progress/kendo-svg-icons';
import {
  iwIconDeselectAll,
  iwIconSelectAll,
} from '../../../assets/css/ewid-svg/svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoMapBase } from '../gk-kendo-map-base';
import {
  AnyGeometryObject,
  MapAction,
  MapObjectTableActionType,
} from '../../../gk-map/models';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gk-kendo-grid-toolbar',
  templateUrl: './gk-kendo-grid-toolbar.component.html',
  styleUrl: './gk-kendo-grid-toolbar.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GkKendoGridToolbarComponent),
      multi: true,
    },
  ],
})
export class GkKendoGridToolbarComponent
  extends GkKendoMapBase
  implements OnInit, ControlValueAccessor
{
  @Input() gridToolbarItems: GridToolBarItem[] = [];
  @Input() selectable: SelectableSettings | boolean = {
    enabled: true,
    mode: 'single',
  };
  @Input() parent: ExtendedGkKendoGridComponent<any>;
  @Input() position: 'top' | 'bottom';
  @Input() overflow = false;
  public iwIconSelectAll = iwIconSelectAll;
  public iwIconDeselectAll = iwIconDeselectAll;
  areAllItemsSelected = false;
  gridToolbarPopupSettings: PopupSettings = {
    popupClass: 'vw-100-mobile',
  };
  protected readonly gridToolBarButtonType = GridToolBarItemType;
  @ViewChild('kendoToolbar') private kendoToolbar: ToolBarComponent;

  constructor(
    public deviceService: DeviceDetectorService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToMapActions();
  }

  protected subscribeToSelectedKeysChange(): void {
    this.parent.gridComponent.selectionDirective.selectedKeysChange.subscribe(
      (selectedKeys: any[]) => {
        if (
          selectedKeys.length === 0 &&
          this.parent.dataBindingDirective.gridDataResult.data.length === 0
        ) {
          this.areAllItemsSelected = false;
        } else {
          this.areAllItemsSelected =
            selectedKeys.length ===
            this.parent.dataBindingDirective.gridDataResult.data.length;
        }
      },
    );
  }

  getButtonClass(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.className === 'function') {
      return gridToolbarItem.className();
    }

    return gridToolbarItem.className;
  }

  getIconClass(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.iconClass === 'function') {
      return gridToolbarItem.iconClass();
    }
    return gridToolbarItem.iconClass;
  }

  getSvgIcon(gridToolbarItem: GridToolBarItem): SVGIcon {
    if (typeof gridToolbarItem.svgIcon === 'function') {
      return gridToolbarItem.svgIcon();
    }
    return gridToolbarItem.svgIcon;
  }

  onToggleSelectAllGridItemsClick(): void {
    this.subscribeToSelectedKeysChange();
    const selectionKey =
      this.parent.gridComponent.selectionDirective.selectionKey;

    if (this.parent.gridComponent.scrollable === 'virtual' && selectionKey) {
      const allRecordsInGrid = this.parent.gridDataResult;
      const allRecordKeys = allRecordsInGrid.data.map(
        (record: any[]) => record[selectionKey],
      );

      this.parent.gridComponent.selectionDirective.selectedKeysChange.emit(
        this.areAllItemsSelected ? [] : allRecordKeys,
      );
    } else {
      this.parent.gridComponent.selectionService.updateAll(
        !this.areAllItemsSelected,
      );
    }
  }

  getToolbarItemDisabledState(gridToolbarItem: GridToolBarItem): boolean {
    if (typeof gridToolbarItem.disabled === 'function') {
      return gridToolbarItem.disabled();
    }

    return gridToolbarItem.disabled;
  }

  getToolbarItemVisibleState(
    visible: boolean | (() => boolean) | undefined,
    gridToolBarItemType?: GridToolBarItemType,
  ): boolean | GridToolBarItemType {
    if (visible === true || (typeof visible === 'function' && visible())) {
      return gridToolBarItemType || true;
    }
    if (visible === false || (typeof visible === 'function' && !visible())) {
      return false;
    }

    return gridToolBarItemType || true;
  }

  getToolbarItemText(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.text === 'function') {
      return gridToolbarItem.text();
    }
    return gridToolbarItem.text;
  }

  getToolbarItemTitle(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.title === 'function') {
      return gridToolbarItem.title();
    }
    return gridToolbarItem.title;
  }

  getToolbarItemSelectedValue(
    gridToolbarItem: GridToolBarItem,
  ): boolean | undefined {
    if (typeof gridToolbarItem.selected === 'function') {
      return gridToolbarItem.selected();
    }

    return gridToolbarItem.selected;
  }

  handleGridToolbarItemEvent(
    gridToolbarItem: GridToolBarItem,
    event?: Event | number,
  ): void {
    if (gridToolbarItem.callback) {
      gridToolbarItem.callback(event);
    }
    this.kendoToolbar.popupRef?.close();
  }

  checkIfMultipleSelectionMode(): boolean {
    if (this.selectable && typeof this.selectable !== 'boolean') {
      return this.selectable.mode === 'multiple';
    } else {
      return false;
    }
  }

  getDropdownItemsTextAndTitleTranslations(
    gridToolBarItems: GridToolBarItem[],
  ): GridToolBarItem[] {
    return gridToolBarItems.map((item) => {
      return {
        ...item,
        text: this.translateService.instant(this.getToolbarItemText(item)),
        title: this.translateService.instant(this.getToolbarItemTitle(item)),
      };
    });
  }

  onModifyGeometryToolbarButtonClick(
    payload: GenericToolbarModifyGeometryPayload,
  ): void {
    const wktGeom = payload.wktGeom;

    if (!wktGeom) {
      this.toastr.warning(this.translateService.instant('GK.MAP.NO_RANGE'));
      return;
    }

    this.showGeomOnMap(wktGeom);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(MapObjectTableActionType.Edit, {
        geom: wktGeom,
      }),
    ]);
  }

  onShowGeometryToolbarButtonClick(
    payload: GenericToolbarShowGeometryPayload,
  ): void {
    const wktGeom = payload.wktGeom;

    if (!wktGeom) {
      this.toastr.warning(this.translateService.instant('GK.MAP.NO_RANGE'));
      return;
    }

    this.showGeomOnMap(wktGeom);
  }

  getToggleLabelText(): string {
    return this.translateService.instant(
      !this.areAllItemsSelected ? 'SELECT_ALL' : 'DESELECT_ALL',
    );
  }

  // @ts-expect-error @todo Will be implemented in the future, when more generic form control will be created
  private onFormControlValueChange: (value: AnyGeometryObject) => void;
  // @ts-expect-error @todo Will be implemented in the future, when more generic form control will be created
  private onFormControlValueTouched: () => void;

  public writeValue(_value: AnyGeometryObject): void {
    // @todo It will be implemented in the future, when programmatic changes from model to view are requested.
  }

  public registerOnChange(fn: (value: AnyGeometryObject) => void): void {
    this.onFormControlValueChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onFormControlValueTouched = fn;
  }
}
