import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { PprfBuildingFormControlName } from './pprf-building-form/pprf-building-form.model';
import { PprfParcelFormControlName } from './pprf-parcel-form/pprf-parcel-form.model';
import { PprfPremisesFormControlName } from './pprf-premises-form/pprf-premises-form.model';
import { PprfPropertyFormControlName } from './pprf-property-form/pprf-property-form.model';
import { PprfTransactionAndPricingFormControlName } from './pprf-transaction-and-pricing-form/pprf-transaction-and-pricing-form.model';
import { PprfStaticDictionaryField } from './services/pprf-form-dictionary/pprf-form-dictionary.model';

export type PropertyPriceRegisterFormControlNames =
  | PprfTransactionAndPricingFormControlName
  | PprfPropertyFormControlName
  | PprfPremisesFormControlName
  | PprfParcelFormControlName
  | PprfBuildingFormControlName;

export type PropertyPriceRegisterFormExpressions = {
  [K in PropertyPriceRegisterFormControlNames]?: {
    [property: string]:
      | string
      | boolean
      | Array<string>
      | ((field: FormlyFieldConfig) => any)
      | ((filter: string, field: FieldTypeConfig<any>) => any)
      | Observable<any>
      | PprfStaticDictionaryField;
  };
};

export const pprfDefaultValue = new PprfStaticDictionaryField(
  null,
  'DICT_DEFAULT_NAME',
);

export class PropertyPriceRegisterFormConfig {
  constructor(
    public transactionAndPricingTabKey = 'PROPERTY_PRICE_REGISTER_FORM.TRANSACTION_AND_PRICING.TITLE',
    public fieldsToHide: PropertyPriceRegisterFormControlNames[] = [],
    public propertiesToOverride: PropertyPriceRegisterFormExpressions = {},
  ) {}
}
