import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../services.model';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  private dictionariesCache = new Map<string, Observable<any>>();

  constructor(private httpClient: HttpClient) {}

  get<T>(url: string): Observable<T[]> {
    if (!this.dictionariesCache.has(url)) {
      this.dictionariesCache.set(
        url,
        this.httpClient.get<ApiResponse<T[]>>(url).pipe(
          map((data) => data.Response),
          shareReplay(1),
        ),
      );
    }

    return this.dictionariesCache.get(url);
  }
}
