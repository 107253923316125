import {
  Component,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
} from '@angular/core';
import { MapControl } from '../../../../controls';
import {
  SourceType,
  ToolType,
  FileLoaderFormValue,
  Wkt,
  DownloadRangeFile,
  MapObject,
  MapObjectApiType,
} from '../../../../models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormComponent } from '../../../../../gk-dynamic-form/gk-dynamic-form.component';
import { takeWhile } from 'rxjs/operators';
import { FieldConfig } from '../../../../../gk-dynamic-form/gk-dynamic-form.model';
import * as _ from 'lodash';
import { RangeTypeValue } from '../../../../configs';
import { PolygonTopologyService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { saveFileByTemporaryLinkBase } from '../../../../../utils/utils';

@Component({
  selector: 'gk-download-range-modal',
  templateUrl: './download-range-modal.component.html',
  providers: [PolygonTopologyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadRangeModalComponent
  extends MapControl
  implements OnInit, AfterViewInit, OnDestroy
{
  private isAlive = true;
  @ViewChild('formContainer')
  formContainer: DynamicFormComponent;
  override toolType: ToolType;
  formValue: FileLoaderFormValue;
  override sourceType = SourceType.DownloadRangeFile;
  wktFileNameTranslation: string;
  txtFileNameTranslation: string;
  wkt: Wkt;

  constructor(
    private activeModal: NgbActiveModal,
    private polygonTopologyService: PolygonTopologyService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) public document: Document,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchTxtFileNameTranslation();
    this.fetchWktFileNameTranslation();
  }

  fetchTxtFileNameTranslation(): void {
    this.translateService
      .get('GK.MAP.TXT_FILE_NAME')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.txtFileNameTranslation = translation));
  }

  fetchWktFileNameTranslation(): void {
    this.translateService
      .get('GK.MAP.WKT_FILE_NAME')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.wktFileNameTranslation = translation));
  }

  ngAfterViewInit(): void {
    this.setFormValueAndSubscribeToChanges();
  }

  setFormValueAndSubscribeToChanges(): void {
    const form = this.formContainer.form;
    this.formValue = form.value;

    form.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((formValue) => {
        this.formValue = formValue;
      });
  }

  downloadFile(): void {
    switch (this.formValue.geometryFormat.value) {
      case RangeTypeValue.Wkt: {
        this.downloadWkt();

        break;
      }
      case RangeTypeValue.Txt: {
        this.downloadTxt();

        break;
      }
    }
  }

  downloadWkt(): void {
    const geom = this.getSelectedRangeMapObject().geom;
    this.saveFileByTemporaryLink(geom, this.wktFileNameTranslation);
  }

  downloadTxt(): void {
    const geom = this.getSelectedRangeMapObject().geom;
    this.polygonTopologyService
      .convertRangeToTxt(geom)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.saveFileByTemporaryLink(data, this.txtFileNameTranslation);
      });
  }

  saveFileByTemporaryLink(geom: string, fileName: string): void {
    saveFileByTemporaryLinkBase(this.document, geom, fileName);
    this.closeModal();
  }

  closeModal(): void {
    this.activeModal.close();
  }

  getDownloadRangeFile(): DownloadRangeFile {
    return this.mapState.toolsState[this.toolType][
      this.sourceType
    ] as DownloadRangeFile;
  }

  getFieldConfigs(): FieldConfig[] {
    return this.getDownloadRangeFile().fieldConfigs;
  }

  getFormHeader(): string {
    return this.getDownloadRangeFile().formHeader;
  }

  getSelectedRangeMapObject(): MapObject {
    return (
      this.mapState.mapObjectTablesState.length === 1
        ? this.mapState.mapObjectTablesState[0].selectedMapObjects.filter(
            (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
          )
        : this.mapState.mapObjectTablesState.find(
            (mapObjectTableState) =>
              _.isEmpty(mapObjectTableState.mapObjectTypes) ||
              mapObjectTableState.mapObjectTypes.includes(
                MapObjectApiType.ExtentOrPolygon,
              ),
          ).selectedMapObjects
    )[0];
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
