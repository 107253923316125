import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { ConversionUtils } from '../../../gk-map/utils';
import {
  GeometryFormat,
  OpenLayersGeometryType,
  Wkt,
} from '../../../gk-map/models';
import { Type } from 'ol/geom/Geometry';
import { GkKendoGridMapService } from '../gk-kendo-grid-map.service';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { getGkKendoGeomFileSelectActionButtonConfig } from './gk-kendo-geom-file-select.model';
import { takeWhile } from 'rxjs';
import { MapControl } from '../../../gk-map/controls';
import { Geometry } from 'ol/geom';
import * as _ from 'lodash';
import { containsExtent } from 'ol/extent';
import { PolygonTopologyService } from '../../../gk-map/services';

@Component({
  selector: 'gk-get-kendo-geom-file-select',
  templateUrl: './gk-kendo-geom-file-select.component.html',
  styleUrls: ['./gk-kendo-geom-file-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GkKendoGeomFileSelectComponent
  extends MapControl
  implements OnDestroy, OnInit
{
  windowRef: WindowRef;
  gkKendoGeomFileSelectActionButtonConfig =
    getGkKendoGeomFileSelectActionButtonConfig(this);
  fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.wkt', '.txt'],
  };
  selectedFiles: Array<File>;
  geometryFormatList: Array<GeometryFormat> = [
    GeometryFormat.Wkt,
    GeometryFormat.Txt,
  ];
  selectedGeomFormat = GeometryFormat.Wkt;
  gkKendoGridMapService = inject(GkKendoGridMapService);
  showFormatTxt: boolean;
  convertingGeometryInProgress: boolean;
  isValidFormat = false;
  convertedGeometry: Geometry;
  isValidGeometryType = false;
  isValidRange = false;
  geometryTypesToCheckTopology = [
    OpenLayersGeometryType.Polygon,
    OpenLayersGeometryType.MultiPolygon,
  ];
  polygonTopologyService = inject(PolygonTopologyService);
  isValidTopology = false;
  changeDetectorRef = inject(ChangeDetectorRef);
  wkt: Wkt;
  protected isAlive = true;

  ngOnInit(): void {
    this.gkKendoGridMapService.$mapState
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapState) => {
        this.mapState = mapState;
      });
  }

  isValid = (): boolean =>
    this.isValidFormat &&
    this.isValidGeometryType &&
    this.isValidRange &&
    this.isValidTopology;

  public geomFormatValueChange(value: GeometryFormat): void {
    this.showFormatTxt = value === GeometryFormat.Txt;
  }

  public select(e: SelectEvent): void {
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = (ev): void => {
          const result = ev.target.result as string;
          this.selectedGeomFormat = ConversionUtils.getGeometryFormat(
            result,
          ) as GeometryFormat;

          this.handleGeometryValidation(result, this.selectedGeomFormat);
        };

        reader.readAsText(file.rawFile);
      }
    });
  }

  geometryConvertSuccessCallback(): void {
    this.convertingGeometryInProgress = false;
    this.isValidFormat = true;
    const geometryType = this.convertedGeometry.getType();
    this.handleGeometryTypeValidation(geometryType);
    this.handleRangeValidation();
    this.handleTopologyValidation(geometryType);
  }

  handleGeometryTypeValidation(geometryType: Type): void {
    if (this.isPolygonOrMultiPolygon(geometryType)) {
      this.isValidGeometryType = true;
    }
  }

  handleRangeValidation(): void {
    if (this.isGeometryExtentContainedInFullExtent()) {
      this.isValidRange = true;
    }
  }

  isGeometryExtentContainedInFullExtent(): boolean {
    return containsExtent(
      this.mapState.viewState.fullNativeExtent,
      this.convertedGeometry.getExtent(),
    );
  }

  handleTopologyValidation(geometryType: any): void {
    _.includes(this.geometryTypesToCheckTopology, geometryType)
      ? this.checkTopology()
      : (this.isValidTopology = true);
  }

  checkTopology(): void {
    this.polygonTopologyService
      .getPolygonTopologyValidation(
        ConversionUtils.getWktFromGeometry(this.convertedGeometry),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((validationStatus) => {
        this.isValidTopology = validationStatus.isValid;
        this.changeDetectorRef.markForCheck();
      });
  }

  isPolygonOrMultiPolygon(geometryType: Type): boolean {
    return geometryType === 'Polygon' || geometryType === 'MultiPolygon';
  }

  onLoadClick(): void {
    this.windowRef.close(this.wkt);
  }

  handleGeometryValidation(
    geometry: string,
    selectedGeomFormat: GeometryFormat,
  ): void {
    this.convertingGeometryInProgress = true;
    this.changeDetectorRef.markForCheck();
    this.resetGeometryValidationStatuses();
    this.handleGeometryFormatValidation(geometry, selectedGeomFormat);
  }

  resetGeometryValidationStatuses(): void {
    this.isValidFormat = false;
    this.isValidGeometryType = false;
    this.isValidRange = false;
    this.isValidTopology = false;
    this.convertedGeometry = undefined;
  }

  handleGeometryFormatValidation(
    geometry: string,
    selectedGeomFormat: GeometryFormat,
  ): void {
    switch (selectedGeomFormat) {
      case GeometryFormat.Wkt:
        this.handleWktValidation(geometry);
        return;
      case GeometryFormat.Txt:
        this.handleTxtValidation(geometry);
        return;
      default:
        return;
    }
  }

  handleWktValidation(wkt: string): void {
    try {
      this.convertedGeometry = ConversionUtils.getGeometryFromWkt(
        wkt.replace(/['"]/g, ''),
      );
      this.wkt = wkt;
      this.geometryConvertSuccessCallback();
    } catch (err) {
      this.convertingGeometryInProgress = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  handleTxtValidation(rangeFile: string): void {
    this.polygonTopologyService
      .convertRangeFileToWkt(rangeFile)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (wkt) => {
          try {
            this.convertedGeometry = ConversionUtils.getGeometryFromWkt(
              wkt.replace(/['"]/g, ''),
            );
            this.wkt = wkt;
            this.geometryConvertSuccessCallback();
          } catch (err) {
            this.convertingGeometryInProgress = false;
            this.changeDetectorRef.markForCheck();
          }
        },
        error: () => {
          this.convertingGeometryInProgress = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  getValidationStatus(validationParam: boolean): string {
    return this.convertingGeometryInProgress
      ? 'fa fa-spinner fa-pulse fa-fw text-primary'
      : validationParam
        ? 'fa fa-check text-success'
        : 'fa fa-times text-danger';
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
