import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
    ColumnHeader,
    DemonstrationOfApplicantRightService,
    DocSignService,
    EgibObject,
    FieldId,
    FormAlertService,
    LawPersonSimpleFormComponent,
    MapId,
    MapObject,
    MapObjectApiType,
    MapObjectTableState,
    MapPortalName,
    MapSettings,
    MapSettingsService,
    MapState,
    MapStateService,
    PortalId,
    PzService,
    StreetService,
    ToolType,
    markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import {
    LBRCNNewDataChangesNotificationControlName,
    LBRCNNewDataChangesNotificationFormGroup,
} from '../services/new-data-changes-notification-form/new-data-changes-notification-form.model';
import { LBRCNNewDataChangesNotificationFormService } from '../services/new-data-changes-notification-form/new-data-changes-notification-form.service';
import { LBRCNNewDataChangesNotification } from '../services/new-data-changes-notification/new-data-changes-notification.model';
import { MainRoutes } from './../../../guards/guard.models';

@Component({
  selector: 'app-lbrc-new-data-changes-notification',
  templateUrl: './new-data-changes-notification.component.html',
  styleUrls: ['./new-data-changes-notification.component.scss'],
})
export class LBRCNewDataChangesNotificationComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = LBRCNNewDataChangesNotificationControlName;
  override formGroup: FormGroup<LBRCNNewDataChangesNotificationFormGroup>;
  override portalId =
    PortalId.LandAndBuildingRegisterDataChangesNotificationRequest;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
      new ColumnHeader('mapObjectNumber', 'GK.MAP.PARCEL_NUMBER'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE'),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel, MapObjectApiType.Building],
    true,
  );
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private lbrcnNewDataChangesNotificationFormService: LBRCNNewDataChangesNotificationFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
    public demonstrationOfApplicantRightService: DemonstrationOfApplicantRightService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup =
      this.lbrcnNewDataChangesNotificationFormService.getFormGroup();
    this.subscribeToDecisionFormRadioFormControl();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.AddressNumberRequest)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.LandAndBuildingRegisterDataChangesNotificationRequest,
      this.mapStateService.getViewState(
        MapId.LandAndBuildingRegisterDataChangesNotificationRequest,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.Building],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.LandAndBuildingRegisterDataChangesNotificationRequest,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areMapObjectsChosen()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            LBRCNNewDataChangesNotification.fromAppToApi(
              this.getFormValue(),
              this.getLandParcels(),
              this.getBuildings(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/zgloszenieZmianyDanychEgib/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getLandParcels(): EgibObject[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects].filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      ),
    );
  }

  getBuildings(): EgibObject[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects].filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.Building)
      ),
    );
  }

  getFormValue(): LBRCNNewDataChangesNotification {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest}/requests-list`,
    );
  }

  isChangeScopeFormControlValid(): boolean {
    return this.getChangeScopeFormControl().valid;
  }

  getChangeScopeFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.ChangeScope);
  }

  isDemonstrationOfApplicantRightFormControlValid(): boolean {
    return this.getDemonstrationOfApplicantRightFormControl().valid;
  }

  getDemonstrationOfApplicantRightFormControl(): AbstractControl<FieldId> {
    return this.formGroup.get(this.controlName.DemonstrationOfApplicantRight);
  }

  areMapObjectsChosen(): boolean {
    return !!this.mapState.mapObjectTablesState[0].mapObjects?.length;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
