import { TranslateService } from '@ngx-translate/core';
import { MockProvider, MockProviders, MockService } from 'ng-mocks';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { MapAction, MapState } from '../../gk-map/models';
import { PolygonTopologyService } from '../../gk-map/services';
import { DownloadService } from '../../services';
import { DomRefService } from '../../services/dom-ref/dom-ref.service';
import { MapSettingsService } from '../../services/map-settings/map-settings.service';
import { BaseDocumentationGridService } from '../base-documentation-grid/base-documentation-grid.service';
import { GkKendoUploadService } from '../gk-kendo-upload';
import { DialogManagerService } from '../services/gk-dialog-manager.service';
import { GkKendoWindowService } from '../services/kendo-window/gk-kendo-window/gk-kendo-window.service';
import { GkKendoGridMapService } from './gk-kendo-grid-map.service';
import { GkKendoMessagesListGridDataService } from './gk-kendo-messages-list-grid/gk-kendo-messages-list-grid-data.service';

export const gkKendoGridBaseMockProviders = [
  MockProviders(
    GkKendoUploadService,
    DialogManagerService,
    BaseDocumentationGridService,
    GkKendoWindowService,
    DownloadService,
    MapSettingsService,
    ToastrService,
    TranslateService,
    PolygonTopologyService,
    DomRefService,
    GkKendoMessagesListGridDataService,
    ReCaptchaV3Service,
  ),
  MockProvider(GkKendoGridMapService, {
    $pendingMapActions: new ReplaySubject<MapAction[]>(1),
    $mapState: MockService(BehaviorSubject, of(MapState.getInitialStruct())),
  }),
];
