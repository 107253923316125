import { Injectable } from "@angular/core";
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  LegalPersonControlName,
  NaturalPersonControlName,
  getDefaultControlValue,
} from "@gk/gk-modules";
import { DecisionFormType } from "../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model";
import { ShippingFormService } from "../../../../services/shipping-form/shipping-form.service";
import {
  LBRDURNewDataUpdateRequestControlName,
  LBRDURNewDataUpdateRequestFormGroup,
} from "./new-data-update-request-form.model";

@Injectable()
export class LBRDURNewDataUpdateRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): FormGroup<LBRDURNewDataUpdateRequestFormGroup> {
    return this.formBuilder.group({
      [LBRDURNewDataUpdateRequestControlName.LawPerson]:
        this.getLawPersonFormGroup(),
      [LBRDURNewDataUpdateRequestControlName.DecisionForm]: [
        DecisionFormType.NonElectronicForm,
        [Validators.required],
      ],
      [LBRDURNewDataUpdateRequestControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
      [LBRDURNewDataUpdateRequestControlName.ChangeScope]: [
        "",
        [Validators.required],
      ],
      [LBRDURNewDataUpdateRequestControlName.DemonstrationOfApplicantRight]: [
        undefined,
        [Validators.required],
      ],
      [LBRDURNewDataUpdateRequestControlName.DeterminationOfLocation]: [""],
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group({
        [LegalPersonControlName.TypeId]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LegalPersonControlName.Name]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.Address]: this.formBuilder.group({
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.StreetAbsence]: false,
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
      }),
    });
  }
}
