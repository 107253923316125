import { FormControl } from '@angular/forms';
import { FieldId, LawPerson } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum BPNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  ConstructionIntentionType = 'constructionIntentionType',
  ConstructionProjectName = 'constructionProjectName',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export enum ElectronicCorrespondenceStatement {
  Consent = 'electronicCorrespondenceStatementConsent',
  NotConsent = 'electronicCorrespondenceStatementNotConsent',
}

export interface BPNewRequestFormGroup {
  [BPNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [BPNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [BPNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [BPNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [BPNewRequestControlName.ConstructionIntentionType]: FormControl<FieldId[]>;
  [BPNewRequestControlName.ConstructionProjectName]: FormControl<string>;
  [BPNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [BPNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
}
