import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonControlName,
  LawPersonType,
  NaturalPersonControlName,
  getContactDataFormGroup,
  getDefaultControlValue,
} from '@gk/gk-modules';
import { ShippingFormService } from '../../../../services/shipping-form/shipping-form.service';
import {
  GACSDRNewRequestControlName,
  GACSDRNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class GACSDRNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService,
  ) {}

  getFormGroup(): FormGroup<GACSDRNewRequestFormGroup> {
    return this.formBuilder.group({
      [GACSDRNewRequestControlName.LawPerson]: this.getLawPersonFormGroup(),
      [GACSDRNewRequestControlName.ContactData]: getContactDataFormGroup(),
      [GACSDRNewRequestControlName.WorkSignature]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.WorkPurpose]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.Materials]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.DataRange]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.AdditionalInfo]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
      }),
    });
  }
}
