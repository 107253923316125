import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SystemSession } from '@gk/gk-modules';
import * as _ from 'lodash';
import { Observable, catchError, combineLatestWith, map, of } from 'rxjs';
import { PortalConfig } from '../../services/portal-config/portal-config.model';
import { PortalConfigService } from '../../services/portal-config/portal-config.service';
import { SessionService } from '../../services/session/session.service';
import { MainRouteParams, MainRoutes } from '../guard.models';

@Injectable()
export class SignInConfigAccessGuard {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private portalConfigService: PortalConfigService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const path = this.getRoutePath(route);

    return this.sessionService.getSystemSession().pipe(
      combineLatestWith(
        this.portalConfigService.getPortalConfByMainRoute(path),
      ),
      map(([sessionState, portalConfig]) => {
        if (
          !sessionState ||
          !sessionState.isAuthenticated() ||
          !this.isSessionValid(sessionState, portalConfig)
        ) {
          return true;
        }

        const expectedRoute =
          route.params?.[MainRouteParams.ParentRoute] ||
          MainRoutes.OfficeDepartmentsPortal;

        this.router.navigate([expectedRoute]);

        return false;
      }),
      catchError(() => of(false)),
    );
  }

  isSessionValid(
    sessionState: SystemSession,
    portalConfig: PortalConfig,
  ): boolean {
    const isAuthenticated =
      (!portalConfig.sessionTypes ||
        portalConfig.sessionTypes.includes(sessionState.sessionType)) &&
      !sessionState.isGuest;

    return isAuthenticated;
  }

  getRoutePath(route: ActivatedRouteSnapshot): MainRoutes {
    return _.get(route.url, '[0].path', '') as MainRoutes;
  }
}
