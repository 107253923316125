import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MainRoutes, PzInitRoutes } from '../../guards/guard.models';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import {
  defaultPortalConfig,
  PortalConfig,
} from '../../services/portal-config/portal-config.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Injectable({
  providedIn: 'root',
})
export class GrantingRightsPortalConfigService implements PortalConfigGetter {
  constructor(private webPortalService: WebPortalService) {}

  getPortalConfig(): Observable<PortalConfig> {
    return this.webPortalService
      .getWebPortalDataByCurrentRoute(MainRoutes.GrantingRights)
      .pipe(
        map((webPortal) => ({
          ...defaultPortalConfig,
          pzInitRoute: PzInitRoutes.GrantingRights,
          isPerunAuthenticationAvailable: webPortal.ewidLogging,
          isPzAuthenticationAvailable: webPortal.pzLogging,
          portalId: webPortal.portalId,
        })),
      );
  }
}
