export class WebRequest {
  constructor(
    public uuid: string,
    public name: string,
  ) {}
  static fromApiToApp(webRequestDto: WebRequestDto): WebRequest {
    return new this(String(webRequestDto.PlikId), webRequestDto.NazwaWysw);
  }
}

export interface WebRequestDto {
  PlikId: number;
  WydzialId: number;
  NazwaWysw: string;
}
