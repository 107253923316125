import { TemplateRef } from '@angular/core';

export interface ConfirmationConfigWithKeys {
  titleKey?: string;
  contentKey?: string;
  width?: number;
  height?: number | string;
  minWidth?: number;
  confirmSuccessCallback?: () => void;
  confirmCancelCallback?: () => void;
  cssClass?: string;
  content?: string | TemplateRef<any> | Function;
}

export interface ConfirmationConfig {
  title?: string;
  content?: string;
  width?: number | string;
  height?: number | string;
  minWidth?: number;
  confirmSuccessCallback?: () => void;
  confirmCancelCallback?: () => void;
  cssClass?: string;
  textNo?: string;
  textYes?: string;
  textOk?: string;
}

export enum DialogConfirmState {
  alert = 'alert',
  confirmation = 'confirmation',
  dialogComponent = 'dialogComponent',
  dialogComponentWithConfirmation = 'dialogComponentWithConfirmation',
}
