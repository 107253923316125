import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ngfModule } from 'angular-file';
import { ModalCommunicatorComponent } from '../communicator-modal/modal-communicator.component';
import { GkTermsOfUseInitializerDirective } from '../gk-modal-terms-of-use-initializer/terms-of-use-initializer.directive';
import { GkModalTermsOfUseComponent } from '../gk-modal-terms-of-use/gk-modal-terms-of-use.component';
import { GkModalTermsOfUseService } from '../gk-modal-terms-of-use/gk-modal-terms-of-use.service';
import { PaymentSuccessComponent } from '../gk-payments/payment-success/payment-success.component';
import { RodoClauseComponent } from '../gk-rodo-clause/components/rodo-clause/rodo-clause.component';
import { RodoClauseService } from '../gk-rodo-clause/services/rodo-clause/rodo-clause.service';
import { SystemConfigGuestService } from '../services/system-config-guest/system-config-guest.service';
import { DateWithoutTime } from '../utils/date-without-time/date-without-time';
import { AccessibilityStatementWrapperComponent } from './accessibility-statement-wrapper/accessibility-statement-component-wrapper';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';
import { CookiesBarComponent } from './cookies-bar/cookies-bar.component';
import { GkFileInputComponent } from './gk-file-input/gk-file-input.component';
import { PropertyPriceRegisterFormModule } from './property-price-register-form/property-price-register-form.module';
import { SessionToolsModule } from './session-tools/session-tools.module';
import { SkipLinksComponent } from './skip-links/skip-links.component';
import { TableTooltipDataCellComponent } from './table/table-tooltip-data-cell/table-tooltip-data-cell.component';
import { TableComponent } from './table/table.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    SessionToolsModule,
    RouterModule.forChild([]),
    ngfModule,
    FormsModule,
    PropertyPriceRegisterFormModule,
  ],
  declarations: [
    PaymentSuccessComponent,
    RodoClauseComponent,
    TableComponent,
    TableTooltipDataCellComponent,
    AccessibilityStatementComponent,
    AccessibilityStatementWrapperComponent,
    CookiesBarComponent,
    DateWithoutTime,
    GkFileInputComponent,
    ModalCommunicatorComponent,
    GkModalTermsOfUseComponent,
    GkTermsOfUseInitializerDirective,
    SkipLinksComponent,
  ],
  exports: [
    PaymentSuccessComponent,
    RodoClauseComponent,
    TableComponent,
    TableTooltipDataCellComponent,
    AccessibilityStatementComponent,
    AccessibilityStatementWrapperComponent,
    CookiesBarComponent,
    SessionToolsModule,
    DateWithoutTime,
    GkFileInputComponent,
    ModalCommunicatorComponent,
    GkTermsOfUseInitializerDirective,
    PropertyPriceRegisterFormModule,
    SkipLinksComponent,
  ],
  providers: [
    RodoClauseService,
    SystemConfigGuestService,
    GkModalTermsOfUseService,
  ],
})
export class GkComponentsModule {}
