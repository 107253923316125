import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ApiUploadDocType,
  UploadDocType,
} from './gk-kendo-upload-doc-type-dictionary.model';
import { UploadDocTypeName } from '../gk-kendo-upload.model';
import { ApiResponse } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class GkKendoUploadDocTypeDictionaryService {
  [UploadDocTypeName.Default] = new BehaviorSubject<UploadDocType[]>([]);
  [UploadDocTypeName.WorkReports] = new BehaviorSubject<UploadDocType[]>([]);
  [UploadDocTypeName.Work] = new BehaviorSubject<UploadDocType[]>([]);
  [UploadDocTypeName.FeeConfirmation] = new BehaviorSubject<UploadDocType[]>(
    [],
  );
  [UploadDocTypeName.CoordinationMeetingPortalCase] = new BehaviorSubject<
    UploadDocType[]
  >([]);

  constructor(private httpClient: HttpClient) {
    this.fetchDefaultDocTypes();
    this.fetchWorkReportsDocTypes();
    this.fetchWorkDocTypes();
    this.fetchFeeConfirmationDocTypes();
    this.fetchCoordinationMeetingPortalCaseDocTypes();
  }

  private fetchDefaultDocTypes(): void {
    this.fetchDocTypes('/api/eod/slo/kdokrodz').subscribe((data) => {
      this[UploadDocTypeName.Default].next(data);
    });
  }

  private fetchWorkReportsDocTypes(): void {
    this.fetchDocTypes('/api/geodeta/slo/operat/kdokrodz').subscribe((data) => {
      this[UploadDocTypeName.WorkReports].next(data);
    });
  }

  private fetchWorkDocTypes(): void {
    this.fetchDocTypes('/api/geodeta/slo/praca/kdokrodz').subscribe((data) => {
      this[UploadDocTypeName.Work].next(data);
    });
  }

  private fetchFeeConfirmationDocTypes(): void {
    this.fetchDocTypes('/api/eod/slo/kdokrodz/potwierdzeniezaplaty').subscribe(
      (data) => {
        this[UploadDocTypeName.FeeConfirmation].next(data);
      },
    );
  }

  private fetchCoordinationMeetingPortalCaseDocTypes(): void {
    this.fetchDocTypes('/api/zudp/branzysta/sprawy/slo/kdokrodz').subscribe(
      (data) => {
        this[UploadDocTypeName.CoordinationMeetingPortalCase].next(data);
      },
    );
  }

  private fetchDocTypes(url: string): Observable<UploadDocType[]> {
    return this.httpClient.get<ApiResponse<ApiUploadDocType[]>>(url).pipe(
      map((res) => res.Response),
      map((apiFields) =>
        apiFields.map((apiField) => UploadDocType.fromApiToApp(apiField)),
      ),
    );
  }
}
