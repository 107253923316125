import { Injectable } from '@angular/core';
import {
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFormOptions,
} from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  throwError,
} from 'rxjs';
import { Place } from '../../../gk-dynamic-form/services/place/place.model';
import { PlaceService } from '../../../gk-dynamic-form/services/place/place.service';
import { Street } from '../../../gk-dynamic-form/services/street/street.model';
import { StreetService } from '../../../gk-dynamic-form/services/street/street.service';
import {
  FormFieldWrappers,
  InputAutocompleteProps,
} from '../../../gk-kendo/formly';
import { PropertyPriceRegisterFormService } from '../property-price-register-form.service';
import { PprfDictionaryKey } from '../services/pprf-form-dictionary/pprf-form-dictionary.model';
import { PprfFormDictionaryService } from '../services/pprf-form-dictionary/pprf-form-dictionary.service';
import { PprfPropertyFormControlName } from './pprf-property-form.model';

@Injectable({
  providedIn: 'root',
})
export class PprfPropertyFormService {
  constructor(
    private pprfFormDictionaryService: PprfFormDictionaryService,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private streetService: StreetService,
    private propertyPriceRegisterFormService: PropertyPriceRegisterFormService,
  ) {}

  getFormConfig(): Observable<{
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  }> {
    const fields = [
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfPropertyFormControlName.PropertyType,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.PropertyType,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfPropertyFormControlName.RightType,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.OwnershipRightRealEstate,
          ),
          multiple: true,
        },
      },
      {
        fieldGroupClassName:
          'd-xl-flex d-lg-block d-sm-flex d-block flex-row column-gap-1',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfPropertyFormControlName.ShareNumeratorFrom,
            type: 'number',
            props: {
              min: 0,
            },
          },
          {
            key: PprfPropertyFormControlName.Slash,
            className: 'display-6 p-2 d-lg-none d-xl-block d-sm-block d-none',
            template: `/`,
          },
          {
            className: 'flex-grow-1',
            key: PprfPropertyFormControlName.ShareDenominatorFrom,
            type: 'number',
            props: {
              min: 0,
            },
          },
        ],
      },
      {
        fieldGroupClassName:
          'd-xl-flex d-lg-block d-sm-flex d-block flex-row column-gap-1',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfPropertyFormControlName.ShareNumeratorTo,
            type: 'number',
            props: {
              min: 0,
            },
          },
          {
            key: PprfPropertyFormControlName.Slash,
            className: 'display-6 p-2 d-lg-none d-xl-block d-sm-block d-none',
            template: `/`,
          },
          {
            className: 'flex-grow-1',
            key: PprfPropertyFormControlName.ShareDenominatorTo,
            type: 'number',
            props: {
              min: 0,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1 mat-small-label',
            key: PprfPropertyFormControlName.LandRegistryAreaFrom,
            type: 'number',
            props: {
              min: 0,
              addonRight: {
                text: 'm²',
              },
            },
          },
          {
            className: 'flex-grow-1 mat-small-label',
            key: PprfPropertyFormControlName.LandRegistryAreaTo,
            type: 'number',
            props: {
              min: 0,
              addonRight: {
                text: 'm²',
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-xl-flex d-block flex-wrap',
        fieldGroup: [
          {
            key: PprfPropertyFormControlName.Place,
            type: 'autocomplete',
            className: 'col-xxl-4 col-lg-12 col-md-4',
            wrappers: [FormFieldWrappers.FormField],
            props: {
              labelProp: 'name',
              filter: (filter: string): Observable<Place[]> => {
                return this.placeService
                  .getPlacesByTerm(
                    filter,
                    '/api/rciwn/slo/miejscowosci/cachedautocomplete',
                  )
                  .pipe(debounceTime(200), distinctUntilChanged());
              },
            },
          },
          {
            key: PprfPropertyFormControlName.Street,
            type: 'autocomplete',
            className: 'col-xxl-4 col-lg-12 col-md-4',
            wrappers: [FormFieldWrappers.FormField],
            props: {
              labelProp: 'name',
              filter: (
                filter: string,
                field: FieldTypeConfig<InputAutocompleteProps>,
              ): Observable<Street[]> => {
                return field.parent.model?.place?.id
                  ? this.streetService
                      .getStreets(
                        filter,
                        undefined,
                        `/api/rciwn/slo/miejscowosci/${field.parent.model.place.id}/ulice/cachedautocomplete`,
                      )
                      .pipe(debounceTime(200), distinctUntilChanged())
                  : of([]);
              },
            },
            expressions: {
              //https://github.com/ngx-formly/ngx-formly/issues/704#issuecomment-364661614
              'props.disabled': (field: FormlyFieldConfig): boolean => {
                return !field.parent.model?.place;
              },
            },
          },
          {
            key: PprfPropertyFormControlName.Number,
            type: 'input',
            className: 'col-xxl-4 col-lg-12 col-md-4',
            wrappers: [FormFieldWrappers.FormField],
          },
        ],
      },
    ];

    return this.propertyPriceRegisterFormService
      .getFieldsWithCustomConfig(fields, this.getLabel)
      .pipe(
        map((fieldsWithCustomConfig) => {
          return { fields: fieldsWithCustomConfig };
        }),
        catchError((error) => {
          console.error(error);

          return throwError(() => error);
        }),
      );
  }

  getLabel = (controlKey: string): Observable<string> => {
    return this.translateService.stream(
      `PROPERTY_PRICE_REGISTER_FORM.PROPERTY.${controlKey.toUpperCase()}`,
    );
  };
}
