import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        async (error: HttpErrorResponse) => {
          let message =
            _.get(
              error.error instanceof Blob
                ? JSON.parse(await error.error.text())
                : error.error,
              'ResponseStatus.Message',
              ''
            ) || error.message;
          message = message?.replace(/\r\n/g, '<br>');
          if (error.status === 401 || !message) {
            return;
          }
          this.toastr.error(message, null, {
            enableHtml: true,
            closeButton: true,
            progressBar: true,
          });
        }
      )
    );
  }
}
