<div class="modal-header">
  <h4 class="modal-title">
    {{ 'GK.USER_SETTINGS.TFA_RESET_CODE.TITLE' | translate }}
  </h4>
</div>
<div class="modal-body">
  @if (tfaService.isTotpEnabled() | async) {
    <p>
      {{ 'GK.USER_SETTINGS.TFA_RESET_CODE.INFO_TOTP' | translate }}
    </p>
  }
  @if (tfaService.isWebAuthnEnabled() | async) {
    <p>
      {{ 'GK.USER_SETTINGS.TFA_RESET_CODE.INFO_WEB_AUTHN' | translate }}
    </p>
  }
  <div class="mt-1 alert alert-secondary" role="alert">
    <strong class="text-break">
      @if (tfaService.isTotpEnabled() | async) {
        <!-- A <span> element has been added to prevent Firefox from appending a space character when copying text.
            This behavior is related to a known issue in Firefox (see: https://bugzilla.mozilla.org/show_bug.cgi?id=452948).
        -->
        <span>{{
          (tfaTotpInitializationCredentialsService.get() | async)?.resetToken
        }}</span>
      }
      @if (tfaService.isWebAuthnEnabled() | async) {
        <span>{{
          (tfaWebAuthnInitializationCredentialsService.get() | async)
            ?.resetToken
        }}</span>
      }
    </strong>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-color-from-db" (click)="modal.close()">
    {{ 'OK' | translate }}
  </button>
</div>
