<section>
  <div
    class="card mb-4"
    [ngClass]="
      !contactDataFormGroup.valid && submitted
        ? 'border-danger border-2'
        : borderClass
    "
  >
    <div class="card-header text-white" [ngClass]="backgroundClass">
      {{ 'CONTACT_DATA' | translate }}
    </div>
    <div class="card-body">
      <gk-law-person-simple-form
        [lawPersonFormGroup]="contactDataFormGroup"
        [naturalPersonOnly]="true"
        [submitted]="submitted"
      >
      </gk-law-person-simple-form>
    </div>
  </div>
</section>
