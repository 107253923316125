import { ColumnSetting, ColumnType } from '../gk-kendo-grid.model';

export const gkKendoDocumentationGridColumns: ColumnSetting[] = [
  {
    field: 'Nazwa',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.NAME',
    type: 'text' as ColumnType,
    width: 200,
  },
  {
    field: 'Rozmiar',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.SIZE',
    type: 'text' as ColumnType,
  },
  {
    field: 'Rodzaj',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.TYPE',
    type: 'text' as ColumnType,
  },
  {
    field: 'Opis',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.DESCRIPTION',
    type: 'text' as ColumnType,
  },
  {
    field: 'DataD',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.ADD_DATE',
    type: 'date' as ColumnType,
  },
  {
    field: 'DodanyPrzez',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.ADDED_BY',
    type: 'text' as ColumnType,
  },
  {
    field: 'Wl',
    title: 'GK_KENDO_GRID.DOCUMENTS.COLUMNS.CONCERN',
    type: 'text' as ColumnType,
    cellTemplate: (item) => (item === 'dzzgl' ? 'Wniosek' : 'Sprawa'),
  },
];

export enum DocumentOrigin {
  Blob = 'blob',
  Edok = 'edok',
}
