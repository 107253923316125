import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonControlName,
  LawPersonType,
  NaturalPersonControlName,
  getContactDataFormGroup,
  getDefaultControlValue,
} from '@gk/gk-modules';
import { GRPPortal } from '../portals/portals.model';
import {
  GRPNewGrantRequestControlName,
  GRPNewGrantRequestFormGroup,
  GRPPermissionControlName,
  GRPPermissionFormGroup,
} from './new-grant-request-form.model';

@Injectable()
export class GRPNewGrantRequestFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): FormGroup<GRPNewGrantRequestFormGroup> {
    return this.formBuilder.group({
      [GRPNewGrantRequestControlName.LawPerson]: this.getLawPersonFormGroup(),
      [GRPNewGrantRequestControlName.ContactData]: getContactDataFormGroup(),
      [GRPNewGrantRequestControlName.Permission]: this.formBuilder.array([]),
      [GRPNewGrantRequestControlName.PortalIds]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }

  getPermissionFormGroup(item: GRPPortal): FormGroup<GRPPermissionFormGroup> {
    return this.formBuilder.group({
      [GRPPermissionControlName.PortalName]: item.name,
      [GRPPermissionControlName.PortalId]: item.id,
      [GRPPermissionControlName.PermissionNumber]: [
        getDefaultControlValue(!item.requiredDocumentTypeId),
        item.requiredDocumentTypeId ? Validators.required : null,
      ],
      [GRPPermissionControlName.RequiredDocumentTypeId]:
        item.requiredDocumentTypeId,
      [GRPPermissionControlName.IsPermissionNumberRequired]:
        item.isPermissionNumberRequired,
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
      }),
    });
  }
}
