import { PortalId } from '../../utils/portal-id/portal-id.model';

export enum StampDutyPaymentStatus {
  NotApplicable,
  Paid,
  Pending,
  Unpaid,
}

export interface StampDutyPayment {
  documentSectionId: string | number;
  portalId: PortalId;
  ownerId: string | number;
}
export class StampDutyPaymentDto {
  constructor(
    public KdokRodzId: string | number,
    public PortalId: PortalId,
    public OwnerId?: string | number,
  ) {}

  static fromAppToApi(payment: Partial<StampDutyPayment>): StampDutyPaymentDto {
    return new this(
      payment.documentSectionId,
      payment.portalId,
      payment.ownerId,
    );
  }
}
