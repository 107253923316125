import { Injectable } from '@angular/core';
import { GkKendoDocumentationGridDto } from './gk-kendo-documentation-grid-data-model';
import { GridDataService } from '../../services/grid-data.service';

@Injectable()
export class GkKendoDocumentationGridDataService extends GridDataService<GkKendoDocumentationGridDto> {
  constructor() {
    super(undefined, 'GET');
  }
}
