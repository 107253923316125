export interface PortalsPermissionsFromApi {
  AdministracjaWglad: boolean; // n1
  IntraEwid: boolean; // n2
  PortalGeodety: boolean; // n3
  PortalArchiwisty: boolean; // n4
  PortalGesut: boolean; // n5
  PortalKomornika: boolean; // n6
  PortalRzeczoznawcy: boolean; // n7
  PortalNaradaKoordynacyjna: boolean; // n8
  RejestrArchitekturyWglad: boolean; // n9
  RejestrGospodarkiMieniemKomunalnymWglad: boolean; // n10
  RejestrZabytkowWglad: boolean; // n11
  RejestrGospodarkiKomunalnejWglad: boolean; // n12
  RejestrGospodarkiKomunalnejDodawanie: boolean; // n13
  RejestrGospodarkiKomunalnejEdycja: boolean; // n14
  RejestrGospodarkiKomunalnejUsuwanie: boolean; // n15
  RejestrGospodarkiKomunalnejWydruk: boolean; // n16
  RejestrGospodarkiKomunalnejBlobyWglad: boolean; // n17
  RejestrGospodarkiKomunalnejBlobyDodawanie: boolean; // n18
  RejestrGospodarkiKomunalnejBlobyUsuwanie: boolean; // n19
  RejestrGospodarkiKomunalnejExportShp: boolean; // n20
  RejestrGospodarkiKomunalnejExportCsv: boolean; // n21
  RejestrGospodarkiKomunalnejExportHtml: boolean; // n22
  RejestrGospodarkiKomunalnejExportGeojson: boolean; // n23
  RejestrGospodarkiKomunalnejExportDxf: boolean; // n24
  RejestrGospodarkiKomunalnejExportGml: boolean; // n25
  RejestrGospodarkiKomunalnejExportPdf: boolean; // n26
  RejestrOchronySrodowiskaWglad: boolean; // n27
  RejestrOchronySrodowiskaWydruk: boolean; // n28
  RejestrOchronySrodowiskaBlobyWglad: boolean; // n29
  RejestrOchronySrodowiskaExportShp: boolean; // n30
  RejestrOchronySrodowiskaExportCsv: boolean; // n31
  RejestrOchronySrodowiskaExportHtml: boolean; // n32
  RejestrOchronySrodowiskaExportGeojson: boolean; // n33
  RejestrOchronySrodowiskaExportDxf: boolean; // n34
  RejestrOchronySrodowiskaExportGml: boolean; // n35
  RejestrOchronySrodowiskaExportPdf: boolean; // n36
  RejestrOswiatyWglad: boolean; // n37
  RejestrOswiatyDodawanie: boolean; // n38
  RejestrOswiatyEdycja: boolean; // n39
  RejestrOswiatyUsuwanie: boolean; // n40
  RejestrOswiatyWydruk: boolean; // n41
  RejestrOswiatyBlobyWglad: boolean; // n42
  RejestrOswiatyBlobyDodawanie: boolean; // n43
  RejestrOswiatyBlobyUsuwanie: boolean; // n44
  RejestrOswiatyExportShp: boolean; // n45
  RejestrOswiatyExportCsv: boolean; // n46
  RejestrOswiatyExportHtml: boolean; // n47
  RejestrOswiatyExportGeojson: boolean; // n48
  RejestrOswiatyExportDxf: boolean; // n49
  RejestrOswiatyExportGml: boolean; // n50
  RejestrOswiatyExportPdf: boolean; // n51
  RejestrInwestycjiWglad: boolean; // n52
  RejestrInwestycjiDodawanie: boolean; // n53
  RejestrInwestycjiEdycja: boolean; // n54
  RejestrInwestycjiUsuwanie: boolean; // n55
  RejestrInwestycjiWydruk: boolean; // n56
  RejestrInwestycjiBlobyWglad: boolean; // n57
  RejestrInwestycjiBlobyDodawanie: boolean; // n58
  RejestrInwestycjiBlobyUsuwanie: boolean; // n59
  RejestrInwestycjiExportShp: boolean; // n60
  RejestrInwestycjiExportCsv: boolean; // n61
  RejestrInwestycjiExportHtml: boolean; // n62
  RejestrInwestycjiExportGeojson: boolean; // n63
  RejestrInwestycjiExportDxf: boolean; // n64
  RejestrInwestycjiExportGml: boolean; // n65
  RejestrInwestycjiExportPdf: boolean; // n66
  RejestrOfertInwestycyjnychWglad: boolean; // n67
  RejestrOfertInwestycyjnychDodawanie: boolean; // n68
  RejestrOfertInwestycyjnychEdycja: boolean; // n69
  RejestrOfertInwestycyjnychUsuwanie: boolean; // n70
  RejestrOfertInwestycyjnychWydruk: boolean; // n71
  RejestrOfertInwestycyjnychBlobyWglad: boolean; // n72
  RejestrOfertInwestycyjnychBlobyDodawanie: boolean; // n73
  RejestrOfertInwestycyjnychBlobyUsuwanie: boolean; // n74
  RejestrOfertInwestycyjnychExportShp: boolean; // n75
  RejestrOfertInwestycyjnychExportCsv: boolean; // n76
  RejestrOfertInwestycyjnychExportHtml: boolean; // n77
  RejestrOfertInwestycyjnychExportGeojson: boolean; // n78
  RejestrOfertInwestycyjnychExportDxf: boolean; // n79
  RejestrOfertInwestycyjnychExportGml: boolean; // n80
  RejestrOfertInwestycyjnychExportPdf: boolean; // n81
  RejestrSprawAdministracyjnychWglad: boolean; // n82
  RejestrSprawAdministracyjnychDodawanie: boolean; // n83
  RejestrSprawAdministracyjnychEdycja: boolean; // n84
  RejestrSprawAdministracyjnychUsuwanie: boolean; // n85
  RejestrSprawAdministracyjnychWydruk: boolean; // n86
  RejestrSprawAdministracyjnychBlobyWglad: boolean; // n87
  RejestrSprawAdministracyjnychBlobyDodawanie: boolean; // n88
  RejestrSprawAdministracyjnychBlobyUsuwanie: boolean; // n89
  RejestrSprawAdministracyjnychExportShp: boolean; // n90
  RejestrSprawAdministracyjnychExportCsv: boolean; // n91
  RejestrSprawAdministracyjnychExportHtml: boolean; // n92
  RejestrSprawAdministracyjnychExportGeojson: boolean; // n93
  RejestrSprawAdministracyjnychExportDxf: boolean; // n94
  RejestrSprawAdministracyjnychExportGml: boolean; // n95
  RejestrSprawAdministracyjnychExportPdf: boolean; // n96
  RejestrOrganizacjiUrzeduWglad: boolean; // n97
  RejestrOrganizacjiUrzeduDodawanie: boolean; // n98
  RejestrOrganizacjiUrzeduEdycja: boolean; // n99
  RejestrOrganizacjiUrzeduUsuwanie: boolean; // n100
  RejestrOrganizacjiUrzeduWydruk: boolean; // n101
  RejestrOrganizacjiUrzeduBlobyWglad: boolean; // n102
  RejestrOrganizacjiUrzeduBlobyDodawanie: boolean; // n103
  RejestrOrganizacjiUrzeduBlobyUsuwanie: boolean; // n104
  RejestrOrganizacjiUrzeduExportShp: boolean; // n105
  RejestrOrganizacjiUrzeduExportCsv: boolean; // n106
  RejestrOrganizacjiUrzeduExportHtml: boolean; // n107
  RejestrOrganizacjiUrzeduExportGeojson: boolean; // n108
  RejestrOrganizacjiUrzeduExportDxf: boolean; // n109
  RejestrOrganizacjiUrzeduExportGml: boolean; // n110
  RejestrOrganizacjiUrzeduExportPdf: boolean; // n111
  RejestrSprawSpolecznychWglad: boolean; // n112
  RejestrSprawSpolecznychDodawanie: boolean; // n113
  RejestrSprawSpolecznychEdycja: boolean; // n114
  RejestrSprawSpolecznychUsuwanie: boolean; // n115
  RejestrSprawSpolecznychWydruk: boolean; // n116
  RejestrSprawSpolecznychBlobyWglad: boolean; // n117
  RejestrSprawSpolecznychBlobyDodawanie: boolean; // n118
  RejestrSprawSpolecznychBlobyUsuwanie: boolean; // n119
  RejestrSprawSpolecznychExportShp: boolean; // n120
  RejestrSprawSpolecznychExportCsv: boolean; // n121
  RejestrSprawSpolecznychExportHtml: boolean; // n122
  RejestrSprawSpolecznychExportGeojson: boolean; // n123
  RejestrSprawSpolecznychExportDxf: boolean; // n124
  RejestrSprawSpolecznychExportGml: boolean; // n125
  RejestrSprawSpolecznychExportPdf: boolean; // n126
  RejestrObiektowSportowychWglad: boolean; // n127
  RejestrObiektowSportowychDodawanie: boolean; // n128
  RejestrObiektowSportowychEdycja: boolean; // n129
  RejestrObiektowSportowychUsuwanie: boolean; // n130
  RejestrObiektowSportowychWydruk: boolean; // n131
  RejestrObiektowSportowychBlobyWglad: boolean; // n132
  RejestrObiektowSportowychBlobyDodawanie: boolean; // n133
  RejestrObiektowSportowychBlobyUsuwanie: boolean; // n134
  RejestrObiektowSportowychExportShp: boolean; // n135
  RejestrObiektowSportowychExportCsv: boolean; // n136
  RejestrObiektowSportowychExportHtml: boolean; // n137
  RejestrObiektowSportowychExportGeojson: boolean; // n138
  RejestrObiektowSportowychExportDxf: boolean; // n139
  RejestrObiektowSportowychExportGml: boolean; // n140
  RejestrObiektowSportowychExportPdf: boolean; // n141
  RejestrObiektowHotelarskichWglad: boolean; // n142
  RejestrObiektowHotelarskichDodawanie: boolean; // n143
  RejestrObiektowHotelarskichEdycja: boolean; // n144
  RejestrObiektowHotelarskichUsuwanie: boolean; // n145
  RejestrObiektowHotelarskichWydruk: boolean; // n146
  RejestrObiektowHotelarskichBlobyWglad: boolean; // n147
  RejestrObiektowHotelarskichBlobyDodawanie: boolean; // n148
  RejestrObiektowHotelarskichBlobyUsuwanie: boolean; // n149
  RejestrObiektowHotelarskichExportShp: boolean; // n150
  RejestrObiektowHotelarskichExportCsv: boolean; // n151
  RejestrObiektowHotelarskichExportHtml: boolean; // n152
  RejestrObiektowHotelarskichExportGeojson: boolean; // n153
  RejestrObiektowHotelarskichExportDxf: boolean; // n154
  RejestrObiektowHotelarskichExportGml: boolean; // n155
  RejestrObiektowHotelarskichExportPdf: boolean; // n156
  RejestrObiektowKulturyWglad: boolean; // n157
  RejestrObiektowKulturyDodawanie: boolean; // n158
  RejestrObiektowKulturyEdycja: boolean; // n159
  RejestrObiektowKulturyUsuwanie: boolean; // n160
  RejestrObiektowKulturyWydruk: boolean; // n161
  RejestrObiektowKulturyBlobyWglad: boolean; // n162
  RejestrObiektowKulturyBlobyDodawanie: boolean; // n163
  RejestrObiektowKulturyBlobyUsuwanie: boolean; // n164
  RejestrObiektowKulturyExportShp: boolean; // n165
  RejestrObiektowKulturyExportCsv: boolean; // n166
  RejestrObiektowKulturyExportHtml: boolean; // n167
  RejestrObiektowKulturyExportGeojson: boolean; // n168
  RejestrObiektowKulturyExportDxf: boolean; // n169
  RejestrObiektowKulturyExportGml: boolean; // n170
  RejestrObiektowKulturyExportPdf: boolean; // n171
  RejestrGranicOkregowObwodowILokaliWyborczychWglad: boolean; // n172
  RejestrGranicOkregowObwodowILokaliWyborczychDodawanie: boolean; // n173
  RejestrGranicOkregowObwodowILokaliWyborczychEdycja: boolean; // n174
  RejestrGranicOkregowObwodowILokaliWyborczychUsuwanie: boolean; // n175
  RejestrGranicOkregowObwodowILokaliWyborczychWydruk: boolean; // n176
  RejestrGranicOkregowObwodowILokaliWyborczychBlobyWglad: boolean; // n177
  RejestrGranicOkregowObwodowILokaliWyborczychBlobyDodawanie: boolean; // n178
  RejestrGranicOkregowObwodowILokaliWyborczychBlobyUsuwanie: boolean; // n179
  RejestrGranicOkregowObwodowILokaliWyborczychExportShp: boolean; // n180
  RejestrGranicOkregowObwodowILokaliWyborczychExportCsv: boolean; // n181
  RejestrGranicOkregowObwodowILokaliWyborczychExportHtml: boolean; // n182
  RejestrGranicOkregowObwodowILokaliWyborczychExportGeojson: boolean; // n183
  RejestrGranicOkregowObwodowILokaliWyborczychExportDxf: boolean; // n184
  RejestrGranicOkregowObwodowILokaliWyborczychExportGml: boolean; // n185
  RejestrGranicOkregowObwodowILokaliWyborczychExportPdf: boolean; // n186
  RejestrGruntowWglad: boolean; // n187
  RejestrBudynkowWglad: boolean; // n188
  RejestrLokaliWglad: boolean; // n189
  RejestrEmuiaWglad: boolean; // n190
  RejestrEodZgloszenieZmianWglad: boolean; // n191
  RejestrEodDokumentyPrzychodzaceWglad: boolean; // n192
  RejestrEodDokumentyWychodzaceWglad: boolean; // n193
  KartotekaDzialekWglad: boolean; // n194
  KartotekaOsobWglad: boolean; // n195
  KartotekaInteresantowWglad: boolean; // n196
  WszystkiePraceGeodezyjneWglad: boolean; // n197
  MapaWglad: boolean; // n198
  MapaPomiarOdleglosciPowierzchni: boolean; // n199
  MapaZapisAktualnegoWidokuDoPliku: boolean; // n200
  MapaGenerowanieWydruku: boolean; // n201
  GesutWglad: boolean; // n202
  ZglaszanieRobot: boolean; // n203
  ObiektyUzytkownikaWglad: boolean; // n204
  PrzegladSut: boolean; // n205
  PrzegladBdot500: boolean; // n206
  PodgladGraniceIPunktyPomierzoneINiepomierzone: boolean; // n207
  PodgladTerenowZamknietychJawnych: boolean; // n208
  PodgladTerenowZamknietychNiejawnych: boolean; // n209
  WidocznoscObszarowTrwajacychPracIOperatow: boolean; // n210
  WidocznoscRamekSekcyjnych: boolean; // n211
  PlanZagospodarowaniaWglad: boolean; // n212
  PlanZagospodarowaniaDokumentyWglad: boolean; // n213
  PlanZagospodarowaniaDokumentyDodawanie: boolean; // n214
  PlanZagospodarowaniaDokumentyUsuwanie: boolean; // n215
  EodDokumentyPrzychodzaceEdycja: boolean; // n216
  EodDokumentyPrzychodzaceUsuwanie: boolean; // n217
  EodDokumentyWychodzaceEdycja: boolean; // n218
  EodDokumentyWychodzaceUsuwanie: boolean; // n219
  RaportInformacjaZRejestruGruntow: boolean; // n220
  RaportWypisZRejestruGruntow: boolean; // n221
  RaportInformacjaDlaOsoby: boolean; // n222
  RaportZestawienieGruntow: boolean; // n223
  WysylanieWiadomosci: boolean; // n224
  RciwnWgladDoZbioruDokumentow: boolean; // n225
  PortalKomornikaRaportWladanieOsobyBezposrednio: boolean; // n226
  WgladDaneOsobowe: boolean; // n227
  WgladDoArchiwumEgib: boolean; // n228
  WgladNumerKw: boolean; // n229
  PortalProjektanta: boolean; // n230
}

export class PortalsPermissions {
  constructor(
    public administrationView: boolean, // n1
    public intraEwid: boolean, // n2
    public surveyorPortal: boolean, // n3
    public archivistPortal: boolean, // n4
    public gesutPortal: boolean, // n5
    public bailiffPortal: boolean, // n6
    public valuerPortal: boolean, // n7
    public coordinationMeetingPortal: boolean, // n8
    public architectureRegistryView: boolean, // n9
    public propertyManagementRegistryView: boolean, // n10
    public monumentsRegistryView: boolean, // n11
    public municipalEconomyRegistryView: boolean, // n12
    public municipalEconomyAdd: boolean, // n13
    public municipalEconomyEdit: boolean, // n14
    public municipalEconomyDelete: boolean, // n15
    public municipalEconomyPrint: boolean, // n16
    public municipalEconomyBlobsView: boolean, // n17
    public municipalEconomyBlobsAdd: boolean, // n18
    public municipalEconomyBlobsDelete: boolean, // n19
    public municipalEconomyExportShp: boolean, // n20
    public municipalEconomyExportCsv: boolean, // n21
    public municipalEconomyExportHtml: boolean, // n22
    public municipalEconomyExportGeoJson: boolean, // n23
    public municipalEconomyExportDxf: boolean, // n24
    public municipalEconomyExportGml: boolean, // n25
    public municipalEconomyExportPdf: boolean, // n26
    public environmentalRegistryView: boolean, // n27
    public environmentalRegistryPrint: boolean, // n28
    public environmentalRegistryBlobsView: boolean, // n29
    public environmentalRegistryExportShp: boolean, // n30
    public environmentalRegistryExportCsv: boolean, // n31
    public environmentalRegistryExportHtml: boolean, // n32
    public environmentalRegistryExportGeoJson: boolean, // n33
    public environmentalRegistryExportDxf: boolean, // n34
    public environmentalRegistryExportGml: boolean, // n35
    public environmentalRegistryExportPdf: boolean, // n36
    public educationRegistryView: boolean, // n37
    public educationAdd: boolean, // n38
    public educationEdit: boolean, // n39
    public educationDelete: boolean, // n40
    public educationPrint: boolean, // n41
    public educationBlobsView: boolean, // n42
    public educationBlobsAdd: boolean, // n43
    public educationBlobsDelete: boolean, // n44
    public educationExportShp: boolean, // n45
    public educationExportCsv: boolean, // n46
    public educationExportHtml: boolean, // n47
    public educationExportGeoJson: boolean, // n48
    public educationExportDxf: boolean, // n49
    public educationExportGml: boolean, // n50
    public educationExportPdf: boolean, // n51
    public investmentRegistryView: boolean, // n52
    public investmentAdd: boolean, // n53
    public investmentEdit: boolean, // n54
    public investmentDelete: boolean, // n55
    public investmentPrint: boolean, // n56
    public investmentBlobsView: boolean, // n57
    public investmentBlobsAdd: boolean, // n58
    public investmentBlobsDelete: boolean, // n59
    public investmentExportShp: boolean, // n60
    public investmentExportCsv: boolean, // n61
    public investmentExportHtml: boolean, // n62
    public investmentExportGeoJson: boolean, // n63
    public investmentExportDxf: boolean, // n64
    public investmentExportGml: boolean, // n65
    public investmentExportPdf: boolean, // n66
    public investmentOfferRegistryView: boolean, // n67
    public investmentOfferAdd: boolean, // n68
    public investmentOfferEdit: boolean, // n69
    public investmentOfferDelete: boolean, // n70
    public investmentOfferPrint: boolean, // n71
    public investmentOfferBlobsView: boolean, // n72
    public investmentOfferBlobsAdd: boolean, // n73
    public investmentOfferBlobsDelete: boolean, // n74
    public investmentOfferExportShp: boolean, // n75
    public investmentOfferExportCsv: boolean, // n76
    public investmentOfferExportHtml: boolean, // n77
    public investmentOfferExportGeoJson: boolean, // n78
    public investmentOfferExportDxf: boolean, // n79
    public investmentOfferExportGml: boolean, // n80
    public investmentOfferExportPdf: boolean, // n81
    public administrativeMattersRegistryView: boolean, // n82
    public administrativeMattersAdd: boolean, // n83
    public administrativeMattersEdit: boolean, // n84
    public administrativeMattersDelete: boolean, // n85
    public administrativeMattersPrint: boolean, // n86
    public administrativeMattersBlobsView: boolean, // n87
    public administrativeMattersBlobsAdd: boolean, // n88
    public administrativeMattersBlobsDelete: boolean, // n89
    public administrativeMattersExportShp: boolean, // n90
    public administrativeMattersExportCsv: boolean, // n91
    public administrativeMattersExportHtml: boolean, // n92
    public administrativeMattersExportGeoJson: boolean, // n93
    public administrativeMattersExportDxf: boolean, // n94
    public administrativeMattersExportGml: boolean, // n95
    public administrativeMattersExportPdf: boolean, // n96
    public townHallRegistryView: boolean, // n97
    public townHallAdd: boolean, // n98
    public townHallEdit: boolean, // n98
    public townHallDelete: boolean, // n100
    public townHallPrint: boolean, // n101
    public townHallBlobsView: boolean, // n102
    public townHallBlobsAdd: boolean, // n103
    public townHallBlobsDelete: boolean, // n104
    public townHallExportShp: boolean, // n105
    public townHallExportCsv: boolean, // n106
    public townHallExportHtml: boolean, // n107
    public townHallExportGeoJson: boolean, // n108
    public townHallExportDxf: boolean, // n109
    public townHallExportGml: boolean, // n110
    public townHallExportPdf: boolean, // n111
    public healthAndSocialRegistryView: boolean, // n112
    public healthAndSocialAdd: boolean, // n113
    public healthAndSocialEdit: boolean, // n114
    public healthAndSocialDelete: boolean, // n115
    public healthAndSocialPrint: boolean, // n116
    public healthAndSocialBlobsView: boolean, // n117
    public healthAndSocialBlobsAdd: boolean, // n118
    public healthAndSocialBlobsDelete: boolean, // n119
    public healthAndSocialExportShp: boolean, // n120
    public healthAndSocialExportCsv: boolean, // n121
    public healthAndSocialExportHtml: boolean, // n122
    public healthAndSocialExportGeoJson: boolean, // n123
    public healthAndSocialExportDxf: boolean, // n124
    public healthAndSocialExportGml: boolean, // n125
    public healthAndSocialExportPdf: boolean, // n126
    public sportRegistryView: boolean, // n127
    public sportAdd: boolean, // n128
    public sportEdit: boolean, // n129
    public sportDelete: boolean, // n130
    public sportPrint: boolean, // n131
    public sportBlobsView: boolean, // n132
    public sportBlobsAdd: boolean, // n133
    public sportBlobsDelete: boolean, // n134
    public sportExportShp: boolean, // n135
    public sportExportCsv: boolean, // n136
    public sportExportHtml: boolean, // n137
    public sportExportGeoJson: boolean, // n138
    public sportExportDxf: boolean, // n139
    public sportExportGml: boolean, // n140
    public sportExportPdf: boolean, // n141
    public hotelsRegistryView: boolean, // n142
    public hotelsAdd: boolean, // n143
    public hotelsEdit: boolean, // n144
    public hotelsDelete: boolean, // n145
    public hotelsPrint: boolean, // n146
    public hotelsBlobsView: boolean, // n147
    public hotelsBlobsAdd: boolean, // n148
    public hotelsBlobsDelete: boolean, // n149
    public hotelsExportShp: boolean, // n150
    public hotelsExportCsv: boolean, // n151
    public hotelsExportHtml: boolean, // n152
    public hotelsExportGeoJson: boolean, // n153
    public hotelsExportDxf: boolean, // n154
    public hotelsExportGml: boolean, // n155
    public hotelsExportPdf: boolean, // n156
    public culturalFacilitiesRegistryView: boolean, // n157
    public culturalFacilitiesAdd: boolean, // n158
    public culturalFacilitiesEdit: boolean, // n159
    public culturalFacilitiesDelete: boolean, // n160
    public culturalFacilitiesPrint: boolean, // n161
    public culturalFacilitiesBlobsView: boolean, // n162
    public culturalFacilitiesBlobsAdd: boolean, // n163
    public culturalFacilitiesBlobsDelete: boolean, // n164
    public culturalFacilitiesExportShp: boolean, // n165
    public culturalFacilitiesExportCsv: boolean, // n166
    public culturalFacilitiesExportHtml: boolean, // n167
    public culturalFacilitiesExportGeoJson: boolean, // n168
    public culturalFacilitiesExportDxf: boolean, // n169
    public culturalFacilitiesExportGml: boolean, // n170
    public culturalFacilitiesExportPdf: boolean, // n171
    public districtsAndElectionsCentersBoundariesRegistryView: boolean, // n172
    public districtsAndElectionsCentersBoundariesAdd: boolean, // n173
    public districtsAndElectionsCentersBoundariesEdit: boolean, // n174
    public districtsAndElectionsCentersBoundariesDelete: boolean, // n175
    public districtsAndElectionsCentersBoundariesPrint: boolean, // n176
    public districtsAndElectionsCentersBoundariesBlobsView: boolean, // n177
    public districtsAndElectionsCentersBoundariesBlobsAdd: boolean, // n178
    public districtsAndElectionsCentersBoundariesBlobsDelete: boolean, // n179
    public districtsAndElectionsCentersBoundariesExportShp: boolean, // n180
    public districtsAndElectionsCentersBoundariesExportCsv: boolean, // n181
    public districtsAndElectionsCentersBoundariesExportHtml: boolean, // n182
    public districtsAndElectionsCentersBoundariesExportGeoJson: boolean, // n183
    public districtsAndElectionsCentersBoundariesExportDxf: boolean, // n184
    public districtsAndElectionsCentersBoundariesExportGml: boolean, // n185
    public districtsAndElectionsCentersBoundariesExportPdf: boolean, // n186
    public landRegisterView: boolean, // n187
    public buildingRegisterView: boolean, // n188
    public premisesRegisterView: boolean, // n189
    public emuiaRegisterView: boolean, // n190
    public eodChangeRequestsRegisterView: boolean, // n191
    public eodIncomingDocumentsRegisterView: boolean, // n192
    public eodOutgoingDocumentsRegisterView: boolean, // n193
    public parcelsFileView: boolean, // n194
    public peopleFileView: boolean, // n195
    public clientsFileView: boolean, // n196
    public allSurveyorWorksView: boolean, // n197
    public mapView: boolean, // n198
    public surfaceDistanceMeasurementMap: boolean, // n199
    public mapSavingCurrentViewToFile: boolean, // n200
    public mapGeneratingPrintout: boolean, // n201
    public gesutView: boolean, // n202
    public worksReport: boolean, // n203
    public userObjectsView: boolean, // n204
    public sutOverview: boolean, // n205
    public bdot500Overview: boolean, // n206
    public bordersAndMeasuredAndNotMeasuredPointsPreview: boolean, // n207
    public enclosedPublicAreasPreview: boolean, // n208
    public enclosedNonPublicAreasPreview: boolean, // n209
    public workAndOperationsAreasVisibility: boolean, // n210
    public sectionalFramesVisibility: boolean, // n211
    public developmentPlanView: boolean, // n212
    public developmentPlanDocumentsView: boolean, // n213
    public developmentPlanDocumentsAdding: boolean, // n214
    public developmentPlanDocumentsRemoving: boolean, // n215
    public eodIncomingDocumentsEdit: boolean, // n216
    public eodIncomingDocumentsDelete: boolean, // n217
    public eodOutgoingDocumentsEdit: boolean, // n218
    public eodOutgoingDocumentsDelete: boolean, // n219
    public landRegisterInformationReport: boolean, // n220
    public landRegisterExtractReport: boolean, // n221
    public informationForPersonReport: boolean, // n222
    public landSummaryReport: boolean, // n223
    public messageSending: boolean, // n224
    public documentCollectionView: boolean, // n225
    public bailiffsPortalPersonPossessionReport: boolean, // n226
    public personalData: boolean, // n227
    public egbilArchiveView: boolean, // n228
    public landAndMortgageRegisterNumberView: boolean, // n229
    public designerPortal: boolean, // n230
  ) {}

  static fromApi(
    portalsPermissionsFromApi: PortalsPermissionsFromApi,
  ): PortalsPermissions {
    return new this(
      portalsPermissionsFromApi.AdministracjaWglad, // n1
      portalsPermissionsFromApi.IntraEwid, // n2
      portalsPermissionsFromApi.PortalGeodety, // n3
      portalsPermissionsFromApi.PortalArchiwisty, // n4
      portalsPermissionsFromApi.PortalGesut, // n5
      portalsPermissionsFromApi.PortalKomornika, // n6
      portalsPermissionsFromApi.PortalRzeczoznawcy, // n7
      portalsPermissionsFromApi.PortalNaradaKoordynacyjna, // n8
      portalsPermissionsFromApi.RejestrArchitekturyWglad, // n9
      portalsPermissionsFromApi.RejestrGospodarkiMieniemKomunalnymWglad, // n10
      portalsPermissionsFromApi.RejestrZabytkowWglad, // n11
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejWglad, // n12
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejDodawanie, // n13
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejEdycja, // n14
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejUsuwanie, // n15
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejWydruk, // n16
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejBlobyWglad, // n17
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejBlobyDodawanie, // n18
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejBlobyUsuwanie, // n19
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportShp, // n20
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportCsv, // n21
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportHtml, // n22
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportGeojson, // n23
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportDxf, // n24
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportGml, // n25
      portalsPermissionsFromApi.RejestrGospodarkiKomunalnejExportPdf, // n26
      portalsPermissionsFromApi.RejestrOchronySrodowiskaWglad, // n27
      portalsPermissionsFromApi.RejestrOchronySrodowiskaWydruk, // n28
      portalsPermissionsFromApi.RejestrOchronySrodowiskaBlobyWglad, // n29
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportShp, // n30
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportCsv, // n31
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportHtml, // n32
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportGeojson, // n33
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportDxf, // n34
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportGml, // n35
      portalsPermissionsFromApi.RejestrOchronySrodowiskaExportPdf, // n36
      portalsPermissionsFromApi.RejestrOswiatyWglad, // n37
      portalsPermissionsFromApi.RejestrOswiatyDodawanie, // n38
      portalsPermissionsFromApi.RejestrOswiatyEdycja, // n39
      portalsPermissionsFromApi.RejestrOswiatyUsuwanie, // n40
      portalsPermissionsFromApi.RejestrOswiatyWydruk, // n41
      portalsPermissionsFromApi.RejestrOswiatyBlobyWglad, // n42
      portalsPermissionsFromApi.RejestrOswiatyBlobyDodawanie, // n43
      portalsPermissionsFromApi.RejestrOswiatyBlobyUsuwanie, // n44
      portalsPermissionsFromApi.RejestrOswiatyExportShp, // n45
      portalsPermissionsFromApi.RejestrOswiatyExportCsv, // n46
      portalsPermissionsFromApi.RejestrOswiatyExportHtml, // n47
      portalsPermissionsFromApi.RejestrOswiatyExportGeojson, // n48
      portalsPermissionsFromApi.RejestrOswiatyExportDxf, // n49
      portalsPermissionsFromApi.RejestrOswiatyExportGml, // n50
      portalsPermissionsFromApi.RejestrOswiatyExportPdf, // n51
      portalsPermissionsFromApi.RejestrInwestycjiWglad, // n52
      portalsPermissionsFromApi.RejestrInwestycjiDodawanie, // n53
      portalsPermissionsFromApi.RejestrInwestycjiEdycja, // n54
      portalsPermissionsFromApi.RejestrInwestycjiUsuwanie, // n55
      portalsPermissionsFromApi.RejestrInwestycjiWydruk, // n56
      portalsPermissionsFromApi.RejestrInwestycjiBlobyWglad, // n57
      portalsPermissionsFromApi.RejestrInwestycjiBlobyDodawanie, // n58
      portalsPermissionsFromApi.RejestrInwestycjiBlobyUsuwanie, // n59
      portalsPermissionsFromApi.RejestrInwestycjiExportShp, // n60
      portalsPermissionsFromApi.RejestrInwestycjiExportCsv, // n61
      portalsPermissionsFromApi.RejestrInwestycjiExportHtml, // n62
      portalsPermissionsFromApi.RejestrInwestycjiExportGeojson, // n63
      portalsPermissionsFromApi.RejestrInwestycjiExportDxf, // n64
      portalsPermissionsFromApi.RejestrInwestycjiExportGml, // n65
      portalsPermissionsFromApi.RejestrInwestycjiExportPdf, // n66
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychWglad, // n67
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychDodawanie, // n68
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychEdycja, // n69
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychUsuwanie, // n70
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychWydruk, // n71
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychBlobyWglad, // n72
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychBlobyDodawanie, // n73
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychBlobyUsuwanie, // n74
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportShp, // n75
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportCsv, // n76
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportHtml, // n77
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportGeojson, // n78
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportDxf, // n79
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportGml, // n80
      portalsPermissionsFromApi.RejestrOfertInwestycyjnychExportPdf, // n81
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychWglad, // n82
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychDodawanie, // n83
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychEdycja, // n84
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychUsuwanie, // n85
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychWydruk, // n86
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychBlobyWglad, // n87
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychBlobyDodawanie, // n88
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychBlobyUsuwanie, // n89
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportShp, // n90
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportCsv, // n91
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportHtml, // n92
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportGeojson, // n93
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportDxf, // n94
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportGml, // n95
      portalsPermissionsFromApi.RejestrSprawAdministracyjnychExportPdf, // n96
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduWglad, // n97
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduDodawanie, // n98
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduEdycja, // n99
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduUsuwanie, // n100
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduWydruk, // n101
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduBlobyWglad, // n102
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduBlobyDodawanie, // n103
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduBlobyUsuwanie, // n104
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportShp, // n105
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportCsv, // n106
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportHtml, // n107
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportGeojson, // n108
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportDxf, // n109
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportGml, // n110
      portalsPermissionsFromApi.RejestrOrganizacjiUrzeduExportPdf, // n111
      portalsPermissionsFromApi.RejestrSprawSpolecznychWglad, // n112
      portalsPermissionsFromApi.RejestrSprawSpolecznychDodawanie, // n113
      portalsPermissionsFromApi.RejestrSprawSpolecznychEdycja, // n114
      portalsPermissionsFromApi.RejestrSprawSpolecznychUsuwanie, // n115
      portalsPermissionsFromApi.RejestrSprawSpolecznychWydruk, // n116
      portalsPermissionsFromApi.RejestrSprawSpolecznychBlobyWglad, // n117
      portalsPermissionsFromApi.RejestrSprawSpolecznychBlobyDodawanie, // n118
      portalsPermissionsFromApi.RejestrSprawSpolecznychBlobyUsuwanie, // n119
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportShp, // n120
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportCsv, // n121
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportHtml, // n122
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportGeojson, // n123
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportDxf, // n124
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportGml, // n125
      portalsPermissionsFromApi.RejestrSprawSpolecznychExportPdf, // n126
      portalsPermissionsFromApi.RejestrObiektowSportowychWglad, // n127
      portalsPermissionsFromApi.RejestrObiektowSportowychDodawanie, // n128
      portalsPermissionsFromApi.RejestrObiektowSportowychEdycja, // n129
      portalsPermissionsFromApi.RejestrObiektowSportowychUsuwanie, // n130
      portalsPermissionsFromApi.RejestrObiektowSportowychWydruk, // n131
      portalsPermissionsFromApi.RejestrObiektowSportowychBlobyWglad, // n132
      portalsPermissionsFromApi.RejestrObiektowSportowychBlobyDodawanie, // n133
      portalsPermissionsFromApi.RejestrObiektowSportowychBlobyUsuwanie, // n134
      portalsPermissionsFromApi.RejestrObiektowSportowychExportShp, // n135
      portalsPermissionsFromApi.RejestrObiektowSportowychExportCsv, // n136
      portalsPermissionsFromApi.RejestrObiektowSportowychExportHtml, // n137
      portalsPermissionsFromApi.RejestrObiektowSportowychExportGeojson, // n138
      portalsPermissionsFromApi.RejestrObiektowSportowychExportDxf, // n139
      portalsPermissionsFromApi.RejestrObiektowSportowychExportGml, // n140
      portalsPermissionsFromApi.RejestrObiektowSportowychExportPdf, // n141
      portalsPermissionsFromApi.RejestrObiektowHotelarskichWglad, // n142
      portalsPermissionsFromApi.RejestrObiektowHotelarskichDodawanie, // n143
      portalsPermissionsFromApi.RejestrObiektowHotelarskichEdycja, // n144
      portalsPermissionsFromApi.RejestrObiektowHotelarskichUsuwanie, // n145
      portalsPermissionsFromApi.RejestrObiektowHotelarskichWydruk, // n146
      portalsPermissionsFromApi.RejestrObiektowHotelarskichBlobyWglad, // n147
      portalsPermissionsFromApi.RejestrObiektowHotelarskichBlobyDodawanie, // n148
      portalsPermissionsFromApi.RejestrObiektowHotelarskichBlobyUsuwanie, // n149
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportShp, // n150
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportCsv, // n151
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportHtml, // n152
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportGeojson, // n153
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportDxf, // n154
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportGml, // n155
      portalsPermissionsFromApi.RejestrObiektowHotelarskichExportPdf, // n156
      portalsPermissionsFromApi.RejestrObiektowKulturyWglad, // n157
      portalsPermissionsFromApi.RejestrObiektowKulturyDodawanie, // n158
      portalsPermissionsFromApi.RejestrObiektowKulturyEdycja, // n159
      portalsPermissionsFromApi.RejestrObiektowKulturyUsuwanie, // n160
      portalsPermissionsFromApi.RejestrObiektowKulturyWydruk, // n161
      portalsPermissionsFromApi.RejestrObiektowKulturyBlobyWglad, // n162
      portalsPermissionsFromApi.RejestrObiektowKulturyBlobyDodawanie, // n163
      portalsPermissionsFromApi.RejestrObiektowKulturyBlobyUsuwanie, // n164
      portalsPermissionsFromApi.RejestrObiektowKulturyExportShp, // n165
      portalsPermissionsFromApi.RejestrObiektowKulturyExportCsv, // n166
      portalsPermissionsFromApi.RejestrObiektowKulturyExportHtml, // n167
      portalsPermissionsFromApi.RejestrObiektowKulturyExportGeojson, // n168
      portalsPermissionsFromApi.RejestrObiektowKulturyExportDxf, // n169
      portalsPermissionsFromApi.RejestrObiektowKulturyExportGml, // n170
      portalsPermissionsFromApi.RejestrObiektowKulturyExportPdf, // n171
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychWglad, // n172
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychDodawanie, // n173
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychEdycja, // n174
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychUsuwanie, // n175
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychWydruk, // n176
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychBlobyWglad, // n177
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychBlobyDodawanie, // n178
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychBlobyUsuwanie, // n179
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportShp, // n180
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportCsv, // n181
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportHtml, // n182
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportGeojson, // n183
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportDxf, // n184
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportGml, // n185
      portalsPermissionsFromApi.RejestrGranicOkregowObwodowILokaliWyborczychExportPdf, // n186
      portalsPermissionsFromApi.RejestrGruntowWglad, // n187
      portalsPermissionsFromApi.RejestrBudynkowWglad, // n188
      portalsPermissionsFromApi.RejestrLokaliWglad, // n189
      portalsPermissionsFromApi.RejestrEmuiaWglad, // n190
      portalsPermissionsFromApi.RejestrEodZgloszenieZmianWglad, // n191
      portalsPermissionsFromApi.RejestrEodDokumentyPrzychodzaceWglad, // n192
      portalsPermissionsFromApi.RejestrEodDokumentyWychodzaceWglad, // n193
      portalsPermissionsFromApi.KartotekaDzialekWglad, // n194
      portalsPermissionsFromApi.KartotekaOsobWglad, // n195
      portalsPermissionsFromApi.KartotekaInteresantowWglad, // n196
      portalsPermissionsFromApi.WszystkiePraceGeodezyjneWglad, // n197
      portalsPermissionsFromApi.MapaWglad, // n198
      portalsPermissionsFromApi.MapaPomiarOdleglosciPowierzchni, // n199
      portalsPermissionsFromApi.MapaZapisAktualnegoWidokuDoPliku, // n200
      portalsPermissionsFromApi.MapaGenerowanieWydruku, // n201
      portalsPermissionsFromApi.GesutWglad, // n202
      portalsPermissionsFromApi.ZglaszanieRobot, // n203
      portalsPermissionsFromApi.ObiektyUzytkownikaWglad, // n204
      portalsPermissionsFromApi.PrzegladSut, // n205
      portalsPermissionsFromApi.PrzegladBdot500, // n206
      portalsPermissionsFromApi.PodgladGraniceIPunktyPomierzoneINiepomierzone, // n207
      portalsPermissionsFromApi.PodgladTerenowZamknietychJawnych, // n208
      portalsPermissionsFromApi.PodgladTerenowZamknietychNiejawnych, // n209
      portalsPermissionsFromApi.WidocznoscObszarowTrwajacychPracIOperatow, // n210
      portalsPermissionsFromApi.WidocznoscRamekSekcyjnych, // n211
      portalsPermissionsFromApi.PlanZagospodarowaniaWglad, // n212
      portalsPermissionsFromApi.PlanZagospodarowaniaDokumentyWglad, // n213
      portalsPermissionsFromApi.PlanZagospodarowaniaDokumentyDodawanie, // n214
      portalsPermissionsFromApi.PlanZagospodarowaniaDokumentyUsuwanie, // n215
      portalsPermissionsFromApi.EodDokumentyPrzychodzaceEdycja, // n216
      portalsPermissionsFromApi.EodDokumentyPrzychodzaceUsuwanie, // n217
      portalsPermissionsFromApi.EodDokumentyWychodzaceEdycja, // n218
      portalsPermissionsFromApi.EodDokumentyWychodzaceUsuwanie, // n219
      portalsPermissionsFromApi.RaportInformacjaZRejestruGruntow, // n220
      portalsPermissionsFromApi.RaportWypisZRejestruGruntow, // n221
      portalsPermissionsFromApi.RaportInformacjaDlaOsoby, // n222
      portalsPermissionsFromApi.RaportZestawienieGruntow, // n223
      portalsPermissionsFromApi.WysylanieWiadomosci, // n224
      portalsPermissionsFromApi.RciwnWgladDoZbioruDokumentow, // n225
      portalsPermissionsFromApi.PortalKomornikaRaportWladanieOsobyBezposrednio, // n226
      portalsPermissionsFromApi.WgladDaneOsobowe, // n227
      portalsPermissionsFromApi.WgladDoArchiwumEgib, // n228
      portalsPermissionsFromApi.WgladNumerKw, // n229
      portalsPermissionsFromApi.PortalProjektanta, // n230
    );
  }
}
