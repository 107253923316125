import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageFromApi } from '../../../communicator-modal/communicator.model';
import {
  NewMessageDto,
  RecipientsFromApi,
} from './gk-kendo-communicator.model';

@Injectable({
  providedIn: 'any',
})
export class GkKendoCommunicatorService {
  constructor(private httpClient: HttpClient) {}

  fetchCommunicatorMessages(
    communicatorMessagesUrl: string,
  ): Observable<MessageFromApi[]> {
    return this.httpClient.get<MessageFromApi[]>(communicatorMessagesUrl);
  }

  getRecipients(recipientsUrl: string): Observable<RecipientsFromApi> {
    return this.httpClient.get<RecipientsFromApi>(recipientsUrl);
  }

  sendMessage(data: NewMessageDto, sendMessageUrl: string): Observable<void> {
    return this.httpClient.post<void>(sendMessageUrl, data);
  }
}
