import { FormControl } from "@angular/forms";
import { FieldId, LawPerson } from "@gk/gk-modules";
import { DecisionFormType } from "../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model";
import { Shipping } from "../../../../shared/shipping-form/shipping-form.model";

export enum LBRDURNewDataUpdateRequestControlName {
  LawPerson = "lawPerson",
  DecisionForm = "decisionForm",
  ShippingForm = "shippingForm",
  ChangeScope = "changeScope",
  DemonstrationOfApplicantRight = "demonstrationOfApplicantRight",
  DeterminationOfLocation = "determinationOfLocation",
}

export interface LBRDURNewDataUpdateRequestFormGroup {
  [LBRDURNewDataUpdateRequestControlName.LawPerson]: FormControl<LawPerson>;
  [LBRDURNewDataUpdateRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [LBRDURNewDataUpdateRequestControlName.ShippingForm]: FormControl<Shipping>;
  [LBRDURNewDataUpdateRequestControlName.ChangeScope]: FormControl<string>;
  [LBRDURNewDataUpdateRequestControlName.DemonstrationOfApplicantRight]: FormControl<FieldId>;
  [LBRDURNewDataUpdateRequestControlName.DeterminationOfLocation]: FormControl<string>;
}
