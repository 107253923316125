@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label
      >:
    </div>
    <div gkControlValue [showLabel]="controlConfig.showLabel">
      <div class="d-flex flex-column attachment">
        @if (lastInvalids?.length) {
          <div class="row alert alert-danger" role="alert">
            {{ getGeneratedInvalidTypeText() }}
          </div>
        }
        <div
          ngfDrop
          selectable
          class="file-drop-zone-with"
          [accept]="getAcceptedMimeTypes()"
          [(file)]="file"
          [(lastInvalids)]="lastInvalids"
          (fileChange)="handleFileInputChangeAction()"
        >
          <button
            [disabled]="isUploading || resSucceeded"
            class="btn btn-success btn-sm"
            [attr.aria-label]="
              'GK.DYNAMIC_LIST.CONTROL_INPUT_FILE.ADD_FILE' | translate
            "
            [attr.title]="
              'GK.DYNAMIC_LIST.CONTROL_INPUT_FILE.ADD_FILE' | translate
            "
          >
            {{ 'GK.DYNAMIC_LIST.CONTROL_INPUT_FILE.ADD_FILE' | translate }}

            @if (isUploading) {
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
              </div>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
}
