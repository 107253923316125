import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  PropertyPriceRegisterFormConfig,
  PropertyPriceRegisterFormControlNames,
} from './property-price-register-form.model';
import {
  PprfAttributesSearchFormModel,
  getAttributesSearchFormModelNewInstance,
} from './services/pprf-attributes-search-form/pprf-attributes-search-form.model';

@Injectable({
  providedIn: 'root',
})
export class PropertyPriceRegisterFormService {
  private formModelCurrentState =
    new BehaviorSubject<PprfAttributesSearchFormModel>(
      getAttributesSearchFormModelNewInstance(),
    );
  private config = new BehaviorSubject<PropertyPriceRegisterFormConfig>(
    {} as PropertyPriceRegisterFormConfig,
  );

  emitConfig(config: PropertyPriceRegisterFormConfig): void {
    this.config.next(config);
  }

  getConfig(): Observable<PropertyPriceRegisterFormConfig> {
    return this.config.asObservable();
  }

  getFieldsWithCustomConfig(
    fields: FormlyFieldConfig[],
    getLabelFunction: (key: string) => Observable<string>,
  ): Observable<FormlyFieldConfig[]> {
    return this.getConfig().pipe(
      map((config) => {
        const processField = (field: FormlyFieldConfig): FormlyFieldConfig => {
          if (field.fieldGroup && Array.isArray(field.fieldGroup)) {
            field.fieldGroup = field.fieldGroup.map(processField);
          } else {
            const key = field.key as PropertyPriceRegisterFormControlNames;
            const propertiesToOverride = config.propertiesToOverride[key];
            if (key) {
              field.expressions = {
                ...this.getCommonExpressions(
                  field.key as PropertyPriceRegisterFormControlNames,
                  getLabelFunction,
                  propertiesToOverride?.['labelTranslationKey'] as string,
                ),
                ...(field.expressions || {}),
              };
            }

            if (propertiesToOverride) {
              Object.keys(propertiesToOverride).forEach((key) => {
                _.set(field, key, propertiesToOverride[key]);
              });
            }
          }
          return field;
        };

        return fields.map(processField);
      }),
    );
  }

  getCommonExpressions(
    controlKey: PropertyPriceRegisterFormControlNames,
    getLabelFunction: (
      key: string | PropertyPriceRegisterFormControlNames,
    ) => Observable<string>,
    customLabelTranslationKey?: string,
  ): { 'props.label': Observable<string>; hide: Observable<boolean> } {
    return {
      'props.label': getLabelFunction(customLabelTranslationKey || controlKey),
      hide: this.shouldFieldBeHidden(controlKey),
    };
  }

  shouldFieldBeHidden(
    field: PropertyPriceRegisterFormControlNames,
  ): Observable<boolean> {
    return this.getConfig().pipe(
      map((config) =>
        config.fieldsToHide?.includes(field) ? true : undefined,
      ),
    );
  }

  saveFormModel(formModel: PprfAttributesSearchFormModel): void {
    this.formModelCurrentState.next(formModel);
  }

  getFormModel(): Observable<PprfAttributesSearchFormModel> {
    return this.formModelCurrentState.asObservable();
  }
}
