import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { map, Observable, of, switchMap } from 'rxjs';
import { RecaptchaService } from './recaptcha.service';

export abstract class BaseRecaptchaInterceptor implements HttpInterceptor {
  protected urlSubstringWithRequiredReCaptcha: string[] = [];
  protected urlSubstringWithoutReCaptcha: string[] = [];

  protected static readonly dynamicParameter = ':id';
  private static readonly dynamicParameterRegex = '[^/]+';

  constructor(
    public reCaptchaV3Service: ReCaptchaV3Service,
    public recaptchaService: RecaptchaService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.addApiToken(request).pipe(
      switchMap((clonedRequest) => next.handle(clonedRequest)),
    );
  }

  adjustActionString(inputString: string): string {
    console.log('adjustActionString', inputString);

    const apiIndex = inputString.indexOf('/api');
    const questionMarkIndex = inputString.indexOf('?');
    const adjustedString = inputString.substring(
      apiIndex,
      questionMarkIndex !== -1 ? questionMarkIndex : inputString.length,
    );

    return adjustedString.replace(/[^A-Za-z_/?=&]/g, '_');
  }

  addApiToken(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    return this.isRequired(request).pipe(
      switchMap((isRecaptchaRequired) =>
        isRecaptchaRequired && this.recaptchaService.siteKey
          ? this.reCaptchaV3Service.execute(
              this.adjustActionString(request.url),
            )
          : of(null),
      ),
      map((token) =>
        request.clone({
          setHeaders: token
            ? {
                ApiToken: token,
              }
            : undefined,
        }),
      ),
    );
  }

  isRequired(params: any): Observable<boolean> {
    if (
      params.url &&
      this.urlSubstringWithoutReCaptcha.some((substring) =>
        params.url.includes(substring),
      )
    ) {
      return of(false);
    }
    if (params.url) {
      for (const substring of this.urlSubstringWithRequiredReCaptcha) {
        const pattern = new RegExp(
          substring.replace(
            BaseRecaptchaInterceptor.dynamicParameter,
            BaseRecaptchaInterceptor.dynamicParameterRegex,
          ),
        );
        if (pattern.test(params.url)) {
          return of(true);
        }
      }
    }

    return of(false);
  }
}
