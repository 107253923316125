import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { FormFieldWrappers } from '../../../gk-kendo/formly';
import { BooleanDictionaryService } from '../../../services/boolean-dictionary/boolean-dictionary.service';
import { PropertyPriceRegisterFormService } from '../property-price-register-form.service';
import { PprfDictionaryKey } from '../services/pprf-form-dictionary/pprf-form-dictionary.model';
import { PprfFormDictionaryService } from '../services/pprf-form-dictionary/pprf-form-dictionary.service';
import { PprfTransactionAndPricingFormControlName } from './pprf-transaction-and-pricing-form.model';

@Injectable({
  providedIn: 'root',
})
export class PprfTransactionAndPricingFormService {
  shouldHideMapTools = new Subject<boolean>();
  constructor(
    private pprfFormDictionaryService: PprfFormDictionaryService,
    private translateService: TranslateService,
    private booleanDictionaryService: BooleanDictionaryService,
    private propertyPriceRegisterFormService: PropertyPriceRegisterFormService,
  ) {}

  getFormConfig(): Observable<{
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  }> {
    const fields = [
      {
        fieldGroupClassName:
          'd-flex flex-column flex-md-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfTransactionAndPricingFormControlName.RegistrationDateFrom,
            type: 'datepicker',
          },
          {
            className: 'flex-grow-1',
            key: PprfTransactionAndPricingFormControlName.RegistrationDateTo,
            type: 'datepicker',
          },
        ],
      },
      {
        fieldGroupClassName:
          'd-flex flex-column flex-md-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfTransactionAndPricingFormControlName.TransactionAndPricingDateFrom,
            type: 'datepicker',
          },
          {
            className: 'flex-grow-1',
            key: PprfTransactionAndPricingFormControlName.TransactionAndPricingDateTo,
            type: 'datepicker',
          },
        ],
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.Type,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.Type,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.TransactionType,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.SaleMethod,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.MarketType,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.MarketType,
          ),
          multiple: true,
        },
      },
      {
        type: '#communities',
        key: PprfTransactionAndPricingFormControlName.CadastralUnit,
        props: {
          change: (field: FormlyFieldConfig): void => {
            this.shouldHideMapTools.next(!!field.formControl.value?.length);
          },
        },
        expressions: {
          hide: (field: FormlyFieldConfig): boolean => {
            return field.model.mapObject;
          },
        },
      },
      {
        type: '#districts',
        key: PprfTransactionAndPricingFormControlName.District,
        props: {
          change: (field: FormlyFieldConfig): void => {
            this.shouldHideMapTools.next(!!field.formControl.value?.length);
          },
        },
        expressions: {
          hide: (field: FormlyFieldConfig): boolean => {
            return field.model.mapObject;
          },
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.ValuationPurpose,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.TransactionType,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.Seller,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.Parties,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.Buyer,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.Parties,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.HasDigitalDocumentation,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.booleanDictionaryService.getBooleanDictionary(),
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.HasPrice,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.booleanDictionaryService.getBooleanDictionary(),
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfTransactionAndPricingFormControlName.GrossTransactionPrice,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.TransactionPrice,
          ),
          multiple: true,
        },
      },
      {
        key: PprfTransactionAndPricingFormControlName.MapObject,
        expressions: {
          hide: (field: FormlyFieldConfig): boolean => {
            const { cadastralUnit, district } = field.model;

            return cadastralUnit?.length || district?.length;
          },
        },
      },
    ];

    return this.propertyPriceRegisterFormService
      .getFieldsWithCustomConfig(fields, this.getLabel)
      .pipe(
        map((fieldsWithCustomConfig) => {
          return { fields: fieldsWithCustomConfig };
        }),
        catchError((error) => {
          console.error(error);

          return throwError(() => error);
        }),
      );
  }

  getLabel = (controlKey: string): Observable<string> => {
    return this.translateService.stream(
      `PROPERTY_PRICE_REGISTER_FORM.TRANSACTION_AND_PRICING.${controlKey.toUpperCase()}`,
    );
  };
}
