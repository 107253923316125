<div class="container container-e-service-request">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="mt-5 mb-3">
      {{ 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.TITLE' | translate }}
    </h2>

    <br />

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.TITLE' | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [legalPersonEnabled]="false"
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>

        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label class="text-nowrap" [attr.for]="controlName.BirthDate">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.FIELDS.BIRTH_DATE'
                  | translate
              }}
            </label>

            <div class="input-group">
              <input
                class="form-control form-control-sm required"
                aria-required="true"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="controlName.BirthDate"
                [name]="controlName.BirthDate"
                [formControlName]="controlName.BirthDate"
                ngbDatepicker
                #d="ngbDatepicker"
              />

              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>

            @if (shouldShowRequiredAlert(this.getBirthDateFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label class="text-nowrap" [attr.for]="controlName.BirthPlace">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.FIELDS.BIRTH_PLACE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.BirthPlace"
              [id]="controlName.BirthPlace"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.BirthPlace"
            />

            @if (shouldShowRequiredAlert(this.getBirthPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <app-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.FIELDS.DECISION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.FIELDS.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.FIELDS.ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.FIELDS.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
      </section>
    }

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <button type="submit" class="btn btn-primary">
      {{
        'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <div class="d-flex flex-column">
      @if (docSignMsgTxt) {
        <span [ngClass]="'text-' + docSignMsgType">
          {{ docSignMsgTxt }}
        </span>
      }

      @if (docSignUrl) {
        <span class="my-2 text-info">
          {{ docSignTranslations['SIGN_INFO'] }}
        </span>
      }

      @if (docSignUrl) {
        <a class="link" [href]="docSignUrl" target="_blank">
          {{ docSignTranslations['SIGN'] }}
        </a>
      }

      @if (docSignPending) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
