import { Observable } from 'rxjs';

export class SkipLinksConfiguration {
  constructor(
    public routingUrlToShowSkipLink: string,
    public skipLinkItems: Observable<SkipLinkItem[]>,
  ) {}
}

export class SkipLinkItem {
  constructor(
    public label: string,
    public href: string,
  ) {
    this.href = window.location.pathname + href;
  }
}
