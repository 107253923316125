//https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/kendo/form-field/src/form-field.wrapper.ts
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormFieldComponent,
  Orientation,
} from '@progress/kendo-angular-inputs';
import {
  FieldWrapper,
  FormlyFieldConfig,
  FormlyFieldProps as CoreFormlyFieldProps,
  ɵdefineHiddenProp as defineHiddenProp,
} from '@ngx-formly/core';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
  orientation?: Orientation;
  formFieldClassName?: string;
}

@Component({
  selector: 'gk-formly-wrapper-kendo-form-field',
  template: `
    <kendo-formfield
      [orientation]="props.orientation!"
      [ngClass]="props.formFieldClassName!"
      class="align-items-center"
    >
      @if (props.label && props.hideLabel !== true) {
        <label [for]="id">
          {{ props.label | translate }}
          <!--        <span-->
          <!--          *ngIf="props.required && props.hideRequiredMarker !== true"-->
          <!--          aria-hidden="true"-->
          <!--          class="k-required"-->
          <!--          >*</span-->
          <!--        >-->
        </label>
      }

      <ng-container #fieldComponent></ng-container>

      @if (props.description) {
        <kendo-formhint>{{ props.description | translate }} </kendo-formhint>
      }
    </kendo-formfield>
    @if (showError) {
      <kendo-formerror>
        <formly-validation-message
          #formlyValidationMessage
          [field]="field"
          [style.display]="'none'"
        ></formly-validation-message>
        {{ formlyValidationMessage.errorMessage$ | async | translate }}
      </kendo-formerror>
    }
  `,
})
export class FormlyWrapperFormFieldComponent
  extends FieldWrapper<FormlyFieldConfig<FormlyFieldProps>>
  implements OnInit
{
  @ViewChild(FormFieldComponent, { static: true })
  formfield!: FormFieldComponent;

  ngOnInit(): void {
    defineHiddenProp(this.field, '_formField', this.formfield);
    defineHiddenProp(this.formfield, 'formControls', undefined);
    // this.formfield['showErrorsInitial'] = () => this.showError;
    // this.formfield['showHintsInitial'] = () => !this.showError;

    const disabledElement = this.formfield['disabledElement'].bind(this);
    this.formfield['disabledElement'] = () => {
      if (this.formfield.controlElementRefs.length === 0) {
        return false;
      }

      return disabledElement();
    };
  }
}
