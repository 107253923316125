export enum AddingProofOfPaymentModes {
  Invisible = 0,
  Visible = 1,
  VisibleFromWebEwid = 2,
}

export interface ClientSettingsDto {
  FileMaxSize: number;
  FileMaxCount: number;
  WieleZakresowGeom: boolean;
  MainPageAddress: string;
  AreTermsOfUse: boolean;
  FeeDepartmentName: string;
  FeeDepartmentBank: string;
  AddingProofOfPaymentMode: AddingProofOfPaymentModes;
  IsApplicantFromRegonEnabled: boolean;
}

export class ClientSettings {
  constructor(
    public fileMaxSize: number,
    public fileMaxCount: number,
    public wieleZakresowGeom: boolean,
    public mainPageAddress: string,
    public areTermsOfUse: boolean,
    public feeDepartmentName: string,
    public feeDepartmentBank: string,
    public addingProofOfPaymentMode: AddingProofOfPaymentModes,
    public isApplicantFromRegonEnabled: boolean,
  ) {}

  static fromApiToApp(data: ClientSettingsDto): ClientSettings {
    return new this(
      data.FileMaxSize,
      data.FileMaxCount,
      data.WieleZakresowGeom,
      data.MainPageAddress,
      data.AreTermsOfUse,
      data.FeeDepartmentName,
      data.FeeDepartmentBank,
      data.AddingProofOfPaymentMode,
      data.IsApplicantFromRegonEnabled,
    );
  }
}
