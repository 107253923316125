import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { DefaultConfigGuestService } from '../../../services';

@Injectable()
export class RecaptchaService {
  siteKey: string;
  constructor(private defaultConfigGuestService: DefaultConfigGuestService) {}

  getSiteKey(): Observable<string> {
    return this.defaultConfigGuestService.getConfig().pipe(
      map((config) => config.webKey),
      tap((siteKey) => (this.siteKey = siteKey)),
    );
  }
}
