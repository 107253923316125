import { FormControl, FormGroup } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum BOPUCNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  CurrentUse = 'currentUse',
  IntendedUse = 'intendedUse',
  ConstructionWorksInformation = 'constructionWorksInformation',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export enum BOPUCConstructionWorksInformationControlName {
  ExecutionType = 'executionType',
  PlannedStartDate = 'plannedStartDate',
}

export interface BOPUCNewRequestFormGroup {
  [BOPUCNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [BOPUCNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [BOPUCNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [BOPUCNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [BOPUCNewRequestControlName.CurrentUse]: FormControl<string>;
  [BOPUCNewRequestControlName.IntendedUse]: FormControl<string>;
  [BOPUCNewRequestControlName.ConstructionWorksInformation]: FormGroup<BOPUCConstructionWorksInformationFormGroup>;
  [BOPUCNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [BOPUCNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
}

export interface BOPUCConstructionWorksInformationFormGroup {
  [BOPUCConstructionWorksInformationControlName.ExecutionType]: FormControl<string>;
  [BOPUCConstructionWorksInformationControlName.PlannedStartDate]: FormControl<NgbDate>;
}
