import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonFormService,
  LawPersonType,
  LegalPersonControlName,
  ValidatePostalCode,
  atLeastOneValidator,
  emailValidator,
  getDefaultControlValue,
  nipValidator,
  phoneValidator,
  regonValidator,
  streetFromDictionaryValidator,
} from '@gk/gk-modules';
import { InvestorDetailsControlName } from './investor-details-form.model';

@Injectable()
export class InvestorDetailsFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private lawPersonFormService: LawPersonFormService,
  ) {}

  getFormGroup(
    isPhoneNumberRequired = true,
    isPhoneNumberValid = true,
    primitivePostalCode?: boolean,
    isEmailRequired = true,
  ): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: [LawPersonType.Natural],
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group(
        {
          [LegalPersonControlName.Name]: [getDefaultControlValue()],
          [LegalPersonControlName.Nip]: [
            getDefaultControlValue(),
            nipValidator(),
          ],
          [LegalPersonControlName.Regon]: [
            getDefaultControlValue(),
            regonValidator(),
          ],
          [LegalPersonControlName.TypeId]: [
            getDefaultControlValue(true),
            Validators.required,
          ],
        },
        { validator: atLeastOneValidator([LegalPersonControlName.TypeId]) },
      ),
      [LawPersonControlName.NaturalPersonData]:
        this.lawPersonFormService.getNaturalPersonFormGroup(),
      [InvestorDetailsControlName.IdNumber]: [getDefaultControlValue()],
      [InvestorDetailsControlName.PhoneNumber]: [
        getDefaultControlValue(),
        [
          isPhoneNumberRequired ? Validators.required : null,
          isPhoneNumberValid ? phoneValidator : null,
        ].filter(Boolean),
      ],
      [InvestorDetailsControlName.Email]: [
        getDefaultControlValue(),
        [isEmailRequired ? Validators.required : null, emailValidator].filter(
          Boolean,
        ),
      ],
      [InvestorDetailsControlName.EPUAPAddress]: [getDefaultControlValue()],
      [LawPersonControlName.Address]: this.formBuilder.group(
        {
          [LawPersonAddressControlName.Place]: [
            getDefaultControlValue(),
            Validators.required,
          ],
          [LawPersonAddressControlName.Street]: [
            getDefaultControlValue(),
            Validators.required,
          ],
          [LawPersonAddressControlName.StreetAbsence]: false,
          [LawPersonAddressControlName.BuildingNumber]: [
            getDefaultControlValue(),
            Validators.required,
          ],
          [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
          [LawPersonAddressControlName.PostalCode]: [
            getDefaultControlValue(),
            primitivePostalCode
              ? [Validators.required, ValidatePostalCode]
              : [Validators.required],
          ],
          [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
        },
        { validator: streetFromDictionaryValidator() },
      ),
    });
  }
}
