<section
  class="card mb-4"
  [formGroup]="formGroup"
  [ngClass]="
    isProxyDetailsInvalidAndValidated() ? 'border-danger border-2' : borderClass
  "
>
  <div class="card-header text-white" [ngClass]="backgroundClass">
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        [id]="controlName.ProxyDetailsCheckbox"
        [formControlName]="controlName.ProxyDetailsCheckbox"
      />

      <label
        class="form-check-label checkbox"
        [attr.for]="controlName.ProxyDetailsCheckbox"
      >
        {{ 'PROXY_DETAILS' | translate }}
      </label>
    </div>
  </div>

  @if (isProxyDetailsVisible()) {
    <div class="card-body">
      <app-investor-details
        [legalPersonEnabled]="false"
        [investorDetailsFromGroup]="getProxyDetailsFormGroup()"
        [automaticCopyingDataFromPz]="false"
        [isPeselVisible]="false"
        [isIdVisible]="false"
        [isEPUAPAddressVisible]="true"
        [isPhoneNumberRequired]="false"
        [isEmailRequired]="false"
      ></app-investor-details>
      <div class="alert alert-info mt-2" role="alert">
        @if (
          documentSectionService.isProxyAttachmentAvailableToPayOnline(portalId)
            | async
        ) {
          {{ 'PROXY_DETAILS_INFO_ONLINE_PAYMENT' | translate }}
        } @else {
          {{ 'PROXY_DETAILS_INFO' | translate }}
        }
      </div>
    </div>
  }
</section>
