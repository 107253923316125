import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { FormFieldWrappers } from '../../../gk-kendo/formly';
import { PropertyPriceRegisterFormService } from '../property-price-register-form.service';
import { PprfDictionaryKey } from '../services/pprf-form-dictionary/pprf-form-dictionary.model';
import { PprfFormDictionaryService } from '../services/pprf-form-dictionary/pprf-form-dictionary.service';
import { PprfPremisesFormControlName } from './pprf-premises-form.model';

@Injectable({
  providedIn: 'root',
})
export class PprfPremisesFormService {
  constructor(
    private pprfFormDictionaryService: PprfFormDictionaryService,
    private translateService: TranslateService,
    private propertyPriceRegisterFormService: PropertyPriceRegisterFormService,
  ) {}

  getFormConfig(): Observable<{
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  }> {
    const fields = [
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfPremisesFormControlName.PremisesFunction,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.PremisesType,
          ),
          multiple: true,
        },
      },
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfPremisesFormControlName.Floor,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.FloorCount,
          ),
          multiple: true,
        },
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfPremisesFormControlName.RoomsNumberFrom,
            type: 'number',
            props: {
              min: 0,
              maxlength: 3,
            },
            expressions: {
              'props.label': this.getLabel(
                PprfPremisesFormControlName.RoomsNumberFrom,
              ),
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfPremisesFormControlName.RoomsNumberTo,
            type: 'number',
            props: {
              min: 0,
              maxlength: 3,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfPremisesFormControlName.PremisesUsableAreaFrom,
            type: 'number',
            props: {
              min: 0,
              maxlength: 8,
              addonRight: {
                text: 'm²',
              },
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfPremisesFormControlName.PremisesUsableAreaTo,
            type: 'number',
            props: {
              min: 0,
              maxlength: 8,
              addonRight: {
                text: 'm²',
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfPremisesFormControlName.AssociatedPremisesAreaFrom,
            type: 'number',
            props: {
              min: 0,
              addonRight: {
                text: 'm²',
              },
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfPremisesFormControlName.AssociatedPremisesAreaTo,
            type: 'number',
            props: {
              min: 0,
              addonRight: {
                text: 'm²',
              },
            },
          },
        ],
      },
    ];

    return this.propertyPriceRegisterFormService
      .getFieldsWithCustomConfig(fields, this.getLabel)
      .pipe(
        map((fieldsWithCustomConfig) => {
          return { fields: fieldsWithCustomConfig };
        }),
        catchError((error) => {
          console.error(error);

          return throwError(() => error);
        }),
      );
  }

  getLabel = (controlKey: string): Observable<string> => {
    return this.translateService.stream(
      `PROPERTY_PRICE_REGISTER_FORM.PREMISES.${controlKey.toUpperCase()}`,
    );
  };
}
