export interface ValuerConfigsDto {
  MaxDetailsRecordCount: number;
  MaxTransactionsCount: number;
  MaxOtherTransactionsByDoc: number;
  InsightCommunityPrice: number;
  InsightLengthInHours: number;
  InsightEndTime: string;
  IsPaymentEnabled: boolean;
  IsDocumentInsightDownloadingEnabled: boolean;
  AppraiserPurposeOfSharing: number;
  WyborFormatuZbioruDanych: SetDataFormat;
  IsExtractPrinted: boolean;
}

export class ValuerConfigs {
  constructor(
    public maxDetailsRecordCount: number,
    public maxTransactionsCount: number,
    public maxOtherTransactionsByDoc: number,
    public insightCommunityPrice: number,
    public insightLengthInHours: number,
    public insightEndTime: string,
    public isPaymentEnabled: boolean,
    public isDocumentInsightDownloadingEnabled: boolean,
    public appraiserPurposeOfSharing: number,
    public staticDataSetFormat: SetDataFormat,
    public isExtractPrinted: boolean,
  ) {}

  static fromApiToApp(data: ValuerConfigsDto): ValuerConfigs {
    return new this(
      data.MaxDetailsRecordCount,
      data.MaxTransactionsCount,
      data.MaxOtherTransactionsByDoc,
      data.InsightCommunityPrice,
      data.InsightLengthInHours,
      data.InsightEndTime,
      data.IsPaymentEnabled,
      data.IsDocumentInsightDownloadingEnabled,
      data.AppraiserPurposeOfSharing,
      data.WyborFormatuZbioruDanych,
      data.IsExtractPrinted,
    );
  }
}

export enum SetDataFormat {
  GmlAndXlsx = 0,
  SelectByUser = 1,
  Gml = 2,
  Xlsx = 3,
}
