<div class="container">
  <h3 class="mt-2 mb-2">
    {{ 'ECO_PORTAL.SEARCH_DOCUMENT.TITLE' | translate }}
  </h3>
  <section>
    <form [formGroup]="searchDocumentFormGroup" (ngSubmit)="onFormSubmit()">
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.SerialNumber"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.SERIAL_NUMBER' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.SerialNumber"
            [id]="ecoDocumentSearchControlName.SerialNumber"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.SerialNumber"
          />
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.CardIssueYear"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.CARD_ISSUE_YEAR' | translate
            }}</label
          >
          <input
            type="number"
            [name]="ecoDocumentSearchControlName.CardIssueYear"
            [id]="ecoDocumentSearchControlName.CardIssueYear"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.CardIssueYear"
          />
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.CaseMark"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.CASE_MARK' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.CaseMark"
            [id]="ecoDocumentSearchControlName.CaseMark"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.CaseMark"
          />
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.DocumentName"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_NAME' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.DocumentName"
            [id]="ecoDocumentSearchControlName.DocumentName"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.DocumentName"
          />
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.DocumentCreator"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_CREATOR' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.DocumentCreator"
            [id]="ecoDocumentSearchControlName.DocumentCreator"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.DocumentCreator"
          />
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.DocumentApprover"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_APPROVER' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.DocumentApprover"
            [id]="ecoDocumentSearchControlName.DocumentApprover"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.DocumentApprover"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.DocumentType"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_TYPE' | translate
            }}</label
          >
          <select
            [name]="ecoDocumentSearchControlName.DocumentType"
            [id]="ecoDocumentSearchControlName.DocumentType"
            [formControlName]="ecoDocumentSearchControlName.DocumentType"
            class="form-select form-select-sm"
          >
            <option [ngValue]="null" selected>
              {{ 'ECO_PORTAL.SEARCH_DOCUMENT.PLACEHOLDERS.SELECT' | translate }}
            </option>
            @for (documentType of documentTypes; track documentType.id) {
              <option [ngValue]="documentType.id">
                {{ documentType.name }}
              </option>
            }
          </select>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.DocumentSubject"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_SUBJECT' | translate
            }}</label
          >
          <select
            [name]="ecoDocumentSearchControlName.DocumentSubject"
            [id]="ecoDocumentSearchControlName.DocumentSubject"
            [formControlName]="ecoDocumentSearchControlName.DocumentSubject"
            class="form-select form-select-sm"
          >
            <option [ngValue]="null" selected>
              {{ 'ECO_PORTAL.SEARCH_DOCUMENT.PLACEHOLDERS.SELECT' | translate }}
            </option>
            @for (
              documentSubject of documentSubjects;
              track documentSubject.id
            ) {
              <option [ngValue]="documentSubject.id">
                {{ documentSubject.name }}
              </option>
            }
          </select>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.OfficeName"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.OFFICE_NAME' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.OfficeName"
            [id]="ecoDocumentSearchControlName.OfficeName"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.OfficeName"
          />
        </div>
      </div>
      <div class="row my-1">
        <label
          class="text-nowrap col-12"
          [attr.for]="ecoDocumentSearchControlName.Voivodeship"
        >
          {{ 'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.REGION' | translate }}
        </label>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <select
            [name]="ecoDocumentSearchControlName.Voivodeship"
            [id]="ecoDocumentSearchControlName.Voivodeship"
            [formControlName]="ecoDocumentSearchControlName.Voivodeship"
            class="form-select form-select-sm"
          >
            <option [ngValue]="null" selected>
              {{
                'ECO_PORTAL.SEARCH_DOCUMENT.PLACEHOLDERS.SELECT_VOIVODESHIP'
                  | translate
              }}
            </option>
            @for (voivodeship of administrativeDivision; track voivodeship.id) {
              <option [ngValue]="voivodeship">
                {{ voivodeship.name }}
              </option>
            }
          </select>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <select
            [name]="ecoDocumentSearchControlName.County"
            [id]="ecoDocumentSearchControlName.County"
            [formControlName]="ecoDocumentSearchControlName.County"
            class="form-select form-select-sm"
          >
            <option [ngValue]="null" selected>
              {{
                'ECO_PORTAL.SEARCH_DOCUMENT.PLACEHOLDERS.SELECT_COUNTY'
                  | translate
              }}
            </option>
            @for (county of getCountiesDictionary(); track county.id) {
              <option [ngValue]="county">
                {{ county.name }}
              </option>
            }
          </select>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <select
            [name]="ecoDocumentSearchControlName.Community"
            [id]="ecoDocumentSearchControlName.Community"
            [formControlName]="ecoDocumentSearchControlName.Community"
            class="form-select form-select-sm"
          >
            <option [ngValue]="null" selected>
              {{
                'ECO_PORTAL.SEARCH_DOCUMENT.PLACEHOLDERS.SELECT_COMMUNITY'
                  | translate
              }}
            </option>
            @for (community of getCommunitiesDictionary(); track community.id) {
              <option [ngValue]="community">
                {{ community.name }}
              </option>
            }
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="ecoDocumentSearchControlName.Description"
            >{{
              'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DESCRIPTION' | translate
            }}</label
          >
          <input
            type="text"
            [name]="ecoDocumentSearchControlName.Description"
            [id]="ecoDocumentSearchControlName.Description"
            class="form-control form-control-sm"
            [formControlName]="ecoDocumentSearchControlName.Description"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <span>{{
            'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_DATE' | translate
          }}</span>
          <div class="form-group mt-2">
            <app-date-range-picker
              [flexDirectionClass]="'flex-column'"
              (dateRangeChanged)="
                updateDateRangeFormControl(
                  $event,
                  ecoDocumentSearchControlName.DocumentDate
                )
              "
            ></app-date-range-picker>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <span>{{
            'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.APPROVAL_DATE' | translate
          }}</span>
          <div class="form-group mt-2">
            <app-date-range-picker
              [flexDirectionClass]="'flex-column'"
              (dateRangeChanged)="
                updateDateRangeFormControl(
                  $event,
                  ecoDocumentSearchControlName.ApprovalDate
                )
              "
            ></app-date-range-picker>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <span>{{
            'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.PUBLICATION_DATE' | translate
          }}</span>
          <div class="form-group mt-2">
            <app-date-range-picker
              [flexDirectionClass]="'flex-column'"
              (dateRangeChanged)="
                updateDateRangeFormControl(
                  $event,
                  ecoDocumentSearchControlName.PublicationDate
                )
              "
            ></app-date-range-picker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end my-3">
        <button
          type="button"
          class="btn btn-secondary me-2 btn-sm"
          (click)="clearForm()"
        >
          {{ 'ECO_PORTAL.SEARCH_DOCUMENT.RESET' | translate }}
        </button>
        <button type="submit" class="btn btn-color-from-db btn-sm">
          {{ 'ECO_PORTAL.SEARCH_DOCUMENT.SEARCH' | translate }}
        </button>
      </div>
    </form>
  </section>
</div>
<app-eco-documents-list [filters]="filters"></app-eco-documents-list>
