export interface InterModLoginTypeDto {
  LogowanieEwid: boolean;
  LogowanieWk: boolean;
}

export class InterModLoginType {
  constructor(
    public ewidLogging: boolean,
    public wkLogging: boolean,
  ) {}

  static fromApiToApp(data: InterModLoginTypeDto): InterModLoginType {
    return new this(data?.LogowanieEwid, data?.LogowanieWk);
  }
}
