<div class="d-flex flex-column justify-content-between h-100">
  <div
    #messagesContainer
    class="my-2 h-100 d-flex flex-column justify-content-between overflow-auto"
  >
    <ul class="list-unstyled m-0">
      @for (message of messages; track $index) {
        <li
          class="message-row rounded p-1 mx-1 mb-2"
          [ngClass]="getMessageBackgroundColorClass(message.author)"
          #messagesQueryList
        >
          <div class="d-flex message-details">
            <div>
              <p>
                {{ message.addPerson }}
                {{ message.addDate | date: 'dd-MM-yyyy HH:mm:ss' }}
              </p>
            </div>
            <div>
              @if (recipientsUrl) {
                <div>
                {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.RECIPIENT' | translate }}:
                {{ message.recipientsName }}
              </div>
              }
              <div>
                {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.READ_IT_2' | translate }}:
                {{ message.readPerson }}
                {{ message.readDate | date: 'dd-MM-yyyy HH:mm:ss' }}
              </div>
            </div>
          </div>
          <div>
            <p class="m-0 formatting-new-line">{{ message.messageContent }}</p>
          </div>
        </li>
      }
    </ul>
  </div>
  @if (messages?.length) {
    <div class="mt-2">
      <h6 class="mb-1">
        {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.LEGEND' | translate }}
      </h6>
      <ul class="list-unstyled d-flex flex-row gap-2 w-100 mb-0">
        @if (shouldShowLegendItem(messages, messageAuthor.Odgik)) {
          <li>
            <div class="form-control form-control-sm message-color-red">
              {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.ODGIK' | translate }}
            </div>
          </li>
        }
        @if (shouldShowLegendItem(messages, messageAuthor.Surveyor)) {
          <li>
            <div class="form-control form-control-sm message-color-blue">
              {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.SURVEYOR_PORTAL' | translate }}
            </div>
          </li>
        }
        @if (shouldShowLegendItem(messages, messageAuthor.DesignerPortal)) {
          <li>
            <div class="form-control form-control-sm message-color-blue">
              {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.DESIGNER_PORTAL' | translate }}
            </div>
          </li>
        }
        @if (
          shouldShowLegendItem(messages, messageAuthor.CoordinationMeeting)
        ) {
          <li>
            <div class="form-control form-control-sm message-color-green">
              {{
                'GK.DYNAMIC_LIST.COMMUNICATOR.COORDINATION_MEEETING_PORTAL'
                  | translate
              }}
            </div>
          </li>
        }
      </ul>
    </div>
  }
  <hr />
  <div class="modal-footer flex-column flex-nowrap">
    <textarea
      class="form-control form-control-sm new-message-input required m-0 new-message-input"
      placeholder="{{
        'GK.DYNAMIC_LIST.COMMUNICATOR.PLACEHOLDER_NEW_MESSAGE' | translate
      }}"
      name="new-message"
      id="new-message"
      rows="2"
      (keydown.enter)="handleSendMessage($event)"
      [(ngModel)]="newMessage"
    ></textarea>
    <div
      class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100 gap-2 m-2"
    >
      @if (recipientsUrl) {
        <ng-container>
          <label for="recipientSelect" class="w-35"
            >{{
              'GK.DYNAMIC_LIST.COMMUNICATOR.MESSAGE_RECIPIENT' | translate
            }}:</label
          >
          <select
            [(ngModel)]="selectedRecipientId"
            class="mb-1 mb-sm-0 form-select"
            id="recipientSelect"
            name="recipientSelect"
          >
            <option [ngValue]="undefined" selected></option>
            @for (recipient of recipients; track recipient.id) {
              <option [ngValue]="recipient.id">
                {{ recipient.name }}
              </option>
            }
          </select>
        </ng-container>
      }
      <button
        type="button"
        class="btn btn-success"
        (click)="sendMessage()"
        [disabled]="!newMessage"
      >
        {{
          messagesSending
            ? ''
            : ('GK.DYNAMIC_LIST.COMMUNICATOR.SEND' | translate)
        }}
        <i [ngClass]="messagesSending ? 'fa fa-spinner fa-spin fa-sm' : ''"></i>
      </button>
    </div>
  </div>
</div>

<gk-loading-panel [isLoading]="messagesLoading"></gk-loading-panel>
