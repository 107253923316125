import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebRequest, WebRequestDto } from './web-request.model';

@Injectable()
export class WebRequestService {
  readonly webRequestUrl = '/api/system/web/wydzial';
  readonly webRequestDownloadUrl = '/api/system/web/portal/plik/download';
  webRequestData = new ReplaySubject<WebRequest[]>();

  constructor(private httpClient: HttpClient) {}

  public fetchWebRequestData(departmentId: string): void {
    this.getWebRequestData(departmentId).subscribe((webRequestData) =>
      this.webRequestData.next(webRequestData),
    );
  }

  private getWebRequestUrl(departmentId: string): string {
    return `${this.webRequestUrl}/${departmentId}/pliki`;
  }

  private getWebRequestData(departmentId: string): Observable<WebRequest[]> {
    return this.httpClient
      .get<WebRequestDto[]>(this.getWebRequestUrl(departmentId))
      .pipe(
        map((apiFields) =>
          apiFields.map((apiField) => WebRequest.fromApiToApp(apiField)),
        ),
      );
  }

  downloadBlob(webRequest: WebRequest[]): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      this.webRequestDownloadUrl,
      { FileUuid: webRequest[0].uuid },
      { responseType: 'blob', observe: 'response' },
    );
  }

  fetchArrayBufferData(relativeUrl: string): Observable<ArrayBuffer> {
    return this.httpClient.get(relativeUrl, {
      responseType: 'arraybuffer',
    });
  }
}
