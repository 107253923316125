import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeWhile } from 'rxjs';
import { PropertyPriceRegisterFormConfig } from './property-price-register-form.model';
import { PropertyPriceRegisterFormService } from './property-price-register-form.service';
import {
  PprfAttributesSearchFormModel,
  PprfFormNavId,
  getAttributesSearchFormModelNewInstance,
} from './services/pprf-attributes-search-form/pprf-attributes-search-form.model';

@Component({
  selector: 'gk-property-price-register-form',
  templateUrl: './property-price-register-form.component.html',
  styleUrl: './property-price-register-form.component.scss',
})
export class PropertyPriceRegisterFormComponent implements OnInit, OnDestroy {
  @Input() set config(value: PropertyPriceRegisterFormConfig) {
    this.propertyPriceRegisterFormService.emitConfig(value);
  }
  @Output() formValueChanges =
    new EventEmitter<PprfAttributesSearchFormModel>();
  form = new FormGroup({});
  formModel: PprfAttributesSearchFormModel;
  pprfFormNavId = PprfFormNavId;
  private isAlive = true;

  constructor(
    public propertyPriceRegisterFormService: PropertyPriceRegisterFormService,
  ) {}

  ngOnInit(): void {
    this.initializeFormModel();
    this.emitFormValueChanges();
  }

  initializeFormModel(): void {
    this.propertyPriceRegisterFormService
      .getFormModel()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((formModel) => {
        if (formModel) {
          this.formModel = formModel;
        }
      });
  }

  emitFormValueChanges(): void {
    this.form.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      this.formValueChanges.emit(this.formModel);
    });
  }

  getFormValue(): PprfAttributesSearchFormModel | null {
    if (this.form.valid) {
      return this.formModel;
    }

    return null;
  }

  resetForm(): void {
    this.form.reset();
    this.formModel = getAttributesSearchFormModelNewInstance();
  }

  ngOnDestroy(): void {
    this.saveFormModel();
    this.isAlive = false;
  }

  saveFormModel(): void {
    this.propertyPriceRegisterFormService.saveFormModel(this.formModel);
  }
}
