import { FormControl, FormGroup } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum NONROTNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  CurrentInvestorName = 'currentInvestorName',
  RequestInformation = 'requestInformation',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export enum NONROTRequestInformationControlName {
  WorksType = 'worksType',
  RequestDate = 'requestDate',
}

export interface NONROTNewRequestFormGroup {
  [NONROTNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [NONROTNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [NONROTNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [NONROTNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [NONROTNewRequestControlName.CurrentInvestorName]: FormControl<string>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [NONROTNewRequestControlName.RequestInformation]: FormGroup<NONROTRequestInformationFormGroup>;
  [NONROTNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [NONROTNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
}

export interface NONROTRequestInformationFormGroup {
  [NONROTRequestInformationControlName.WorksType]: FormControl<string>;
  [NONROTRequestInformationControlName.RequestDate]: FormControl<NgbDate>;
}
