import { FormControl } from '@angular/forms';
import { FieldId, LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum SFB70CCNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  ConstructionIntentionType = 'constructionIntentionType',
  PlannedStartDate = 'plannedStartDate',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export interface SFB70CCNewRequestFormGroup {
  [SFB70CCNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [SFB70CCNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [SFB70CCNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [SFB70CCNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [SFB70CCNewRequestControlName.ConstructionIntentionType]: FormControl<
    FieldId[]
  >;
  [SFB70CCNewRequestControlName.PlannedStartDate]: FormControl<NgbDate>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [SFB70CCNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [SFB70CCNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
}
