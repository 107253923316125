import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, shareReplay, switchMap } from 'rxjs';
import { ApiResponseList } from '../../../../gk-dynamic-list/services/services.model';
import {
  PprfDictionaryContainer,
  PprfDictionaryContainerDto,
  PprfDictionaryField,
  PprfDictionaryKey,
  PprfStaticDictionaryField,
} from './pprf-form-dictionary.model';

@Injectable({
  providedIn: 'root',
})
export class PprfFormDictionaryService {
  private dictionaryCache: Observable<PprfDictionaryContainer[]>;
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
  ) {}

  getDictionaryByKey(
    key: PprfDictionaryKey,
  ): Observable<PprfDictionaryField[]> {
    return this.getDictionary().pipe(
      map((dicts) => dicts.find((dict) => dict.key === key)?.dictionary || []),
    );
  }

  getDictionary(): Observable<PprfDictionaryContainer[]> {
    if (!this.dictionaryCache) {
      this.dictionaryCache = this.httpClient
        .get<
          ApiResponseList<PprfDictionaryContainerDto>
        >('/api/rciwn/searchformdict')
        .pipe(
          map((dicts) =>
            dicts.Response.map((dict) =>
              PprfDictionaryContainer.fromApiToApp(dict),
            ),
          ),
          switchMap((dicts) =>
            this.getStaticDictionaries().pipe(
              map((staticDicts) => [...dicts, ...staticDicts]),
            ),
          ),
          shareReplay(1),
        );
    }

    return this.dictionaryCache;
  }

  private getStaticDictionaries(): Observable<PprfDictionaryContainer[]> {
    return this.translateService
      .get('PROPERTY_PRICE_REGISTER_FORM.STATIC_DICTIONARY')
      .pipe(
        map((staticDictionary) => [
          new PprfDictionaryContainer(PprfDictionaryKey.Type, [
            new PprfStaticDictionaryField('T', staticDictionary['TRANSACTION']),
            new PprfStaticDictionaryField('W', staticDictionary['VALUATION']),
          ]),
        ]),
      );
  }
}
