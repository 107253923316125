import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProxyInterceptor implements HttpInterceptor {
  private proxyErrorMessage = 'Operacja trwała zbyt długo i została przerwana';
  private proxyErrorTitle = 'Błąd';

  constructor(private toastr: ToastrService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  private handleError(error: HttpErrorResponse): void {
    if (this.isErrorProxyError(error)) {
      this.displayProxyErrorMessage();
    }
  }

  private isErrorProxyError(error: HttpErrorResponse): boolean {
    return error.status === 502;
  }

  private displayProxyErrorMessage(): void {
    this.toastr.error(this.proxyErrorMessage, this.proxyErrorTitle, {
      closeButton: true,
      progressBar: true,
    });
  }
}
