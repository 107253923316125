import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PprfTransactionAndPricingFormService } from './pprf-transaction-and-pricing-form.service';

import { PprfTransactionAndPricingFormModel } from './pprf-transaction-and-pricing-form.model';

@Component({
  selector: 'gk-pprf-transaction-and-pricing-form',
  templateUrl: './pprf-transaction-and-pricing-form.component.html',
})
export class PprfTransactionAndPricingFormComponent {
  @Input() form: FormGroup;
  @Input() formModel: PprfTransactionAndPricingFormModel;
  formlyFormConfig = this.pprfTransactionAndPricingFormService.getFormConfig();

  constructor(
    private pprfTransactionAndPricingFormService: PprfTransactionAndPricingFormService,
  ) {}
}
