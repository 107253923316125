import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemCommunicatorPayload,
  GenericGridToolbarItemName,
  GridToolBarItem,
} from '../gk-kendo-grid.model';
import { GkKendoMessagesListGridComponent } from './gk-kendo-messages-list-grid.component';

export const gkKendoMessagesListGridColumns: ColumnSetting[] = [
  {
    field: 'NazwaSprawy',
    title: 'NEW_MESSAGES_LIST.GRID_COLUMN_SETTINGS.WORK_ID',
    type: ColumnType.Text,
    width: 180,
    cellTemplate: (_item, dataItem): string => {
      return dataItem.NazwaSprawy || dataItem.SygnaturaSprawy;
    },
  },
  {
    field: 'WiadomoscOd',
    title: 'NEW_MESSAGES_LIST.GRID_COLUMN_SETTINGS.FROM',
    type: ColumnType.Text,
    cellTemplate: (_item, dataItem): string => {
      return dataItem.WiadomoscUserOd || dataItem.WiadomoscOd;
    },
  },
  {
    field: 'DataD',
    title: 'NEW_MESSAGES_LIST.GRID_COLUMN_SETTINGS.DATE',
    type: ColumnType.Date,
    format: 'yyyy-MM-dd HH:mm:ss',
    width: 150,
  },
];

export const getGkKendoMessagesListToolBarItems = (
  c: GkKendoMessagesListGridComponent,
): GridToolBarItem[] => [
  {
    spacer: true,
    name: GenericGridToolbarItemName.Refresh,
  },
  {
    name: GenericGridToolbarItemName.Communicator,
    text: 'Odczytaj',
    svgIcon: undefined,
    showText: 'both',
    buttonThemeColor: 'primary',
    disabled: () => !c.selectedNewMessage,
    payload: (): GenericGridToolbarItemCommunicatorPayload =>
      c.payload.communicator,
  },
];
