@if (logoImageTextNavItemConfig.imgSrc) {
  <img
    [src]="logoImageTextNavItemConfig.imgSrc"
    [alt]="logoImageTextNavItemConfig.imgAlt | translate"
  />
}
<h1
  [ngClass]="[getBgClass(), getTextColor()]"
  class="d-flex flex-column"
  [ngClass]="getTextColor()"
>
  <span class="d-none d-sm-inline-block">{{
    logoImageTextNavItemConfig.textPart1 | translate
  }}</span>
  @if (logoImageTextNavItemConfig.textPart2) {
    <span class="d-none d-sm-inline-block">{{
      logoImageTextNavItemConfig.textPart2 | translate
    }}</span>
  }

  <span class="d-inline-block small d-sm-none">
    {{ logoImageTextNavItemConfig.textPart1 | translate }}
  </span>
  <span class="d-inline-block small d-sm-none">
    @if (logoImageTextNavItemConfig.textPart2) {
      {{ logoImageTextNavItemConfig.textPart2 | translate }}
    }
  </span>
</h1>
