import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { FormFieldWrappers } from '../../../gk-kendo/formly';
import { PropertyPriceRegisterFormService } from '../property-price-register-form.service';
import { PprfDictionaryKey } from '../services/pprf-form-dictionary/pprf-form-dictionary.model';
import { PprfFormDictionaryService } from '../services/pprf-form-dictionary/pprf-form-dictionary.service';
import { PprfBuildingFormControlName } from './pprf-building-form.model';

@Injectable({
  providedIn: 'root',
})
export class PprfBuildingFormService {
  constructor(
    private pprfFormDictionaryService: PprfFormDictionaryService,
    private translateService: TranslateService,
    private propertyPriceRegisterFormService: PropertyPriceRegisterFormService,
  ) {}

  getFormConfig(): Observable<{
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  }> {
    const fields = [
      {
        wrappers: [FormFieldWrappers.FormField],
        key: PprfBuildingFormControlName.BuildingType,
        type: 'select',
        props: {
          valueProp: 'id',
          labelProp: 'description',
          options: this.pprfFormDictionaryService.getDictionaryByKey(
            PprfDictionaryKey.BuildingType,
          ),
          multiple: true,
        },
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.ConstructionYearFrom,
            type: 'number',
            props: {
              min: 0,
              maxlength: 4,
            },
            expressions: {
              'props.label': this.getLabel(
                PprfBuildingFormControlName.ConstructionYearFrom,
              ),
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.ConstructionYearTo,
            type: 'number',
            props: {
              min: 0,
              maxlength: 4,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.FloorsAboveFrom,
            type: 'number',
            props: {
              min: 0,
              maxlength: 3,
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.FloorsAboveTo,
            type: 'number',
            props: {
              min: 0,
              maxlength: 3,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.FloorsBelowFrom,
            type: 'number',
            props: {
              min: 0,
              maxlength: 3,
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.FloorsBelowTo,
            type: 'number',
            props: {
              min: 0,
              maxlength: 3,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex flex-row column-gap-1 flex-wrap',
        fieldGroup: [
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.UsableAreaFrom,
            type: 'number',
            props: {
              min: 0,
              maxlength: 8,
              addonRight: {
                text: 'm²',
              },
            },
          },
          {
            className: 'flex-grow-1',
            key: PprfBuildingFormControlName.UsableAreaTo,
            type: 'number',
            props: {
              min: 0,
              maxlength: 8,
              addonRight: {
                text: 'm²',
              },
            },
          },
        ],
      },
    ];

    return this.propertyPriceRegisterFormService
      .getFieldsWithCustomConfig(fields, this.getLabel)
      .pipe(
        map((fieldsWithCustomConfig) => {
          return { fields: fieldsWithCustomConfig };
        }),
        catchError((error) => {
          console.error(error);

          return throwError(() => error);
        }),
      );
  }

  getLabel = (controlKey: string): Observable<string> => {
    return this.translateService.stream(
      `PROPERTY_PRICE_REGISTER_FORM.BUILDING.${controlKey.toUpperCase()}`,
    );
  };
}
