<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">
      {{
        'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.TITLE'
          | translate
      }}
    </h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isChangeScopeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ChangeScope">
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.CHANGE_SCOPE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.ChangeScope"
            [id]="controlName.ChangeScope"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.ChangeScope"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getChangeScopeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.DeterminationOfLocation">
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.DETERMINATION_OF_LOCATION'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.DeterminationOfLocation"
            [id]="controlName.DeterminationOfLocation"
            class="form-control"
            [formControlName]="controlName.DeterminationOfLocation"
          ></textarea>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isDemonstrationOfApplicantRightFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.DemonstrationOfApplicantRight"
          >
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.DEMONSTRATION_OF_APPLICANT_RIGHT'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="d-flex row-select">
            <select
              [attr.id]="controlName.DemonstrationOfApplicantRight"
              [title]="'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate"
              [name]="controlName.DemonstrationOfApplicantRight"
              [formControlName]="controlName.DemonstrationOfApplicantRight"
              class="form-select form-select-sm required"
              aria-required="true"
            >
              @for (
                field of demonstrationOfApplicantRightService.getDictionary()
                  | async;
                track field.id
              ) {
                <option [ngValue]="field.id">
                  {{ field.name }}
                </option>
              }
            </select>
          </div>
        </div>

        @if (
          shouldShowRequiredAlert(getDemonstrationOfApplicantRightFormControl())
        ) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.DECISION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{
                'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.SHIPPING_FORM_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.SUBMIT'
            | translate
        }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
