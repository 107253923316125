import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, shareReplay } from 'rxjs';
import { BooleanDictionaryItem } from './boolean-dictionary.model';

@Injectable({
  providedIn: 'root',
})
export class BooleanDictionaryService {
  private booleanDictionary: Observable<BooleanDictionaryItem[]>;
  constructor(private translateService: TranslateService) {}

  getBooleanDictionary(): Observable<BooleanDictionaryItem[]> {
    if (!this.booleanDictionary) {
      this.booleanDictionary = this.translateService.get(['YES', 'NO']).pipe(
        map((translations) => {
          return [
            new BooleanDictionaryItem(true, translations.YES),
            new BooleanDictionaryItem(false, translations.NO),
          ];
        }),
        shareReplay(1),
      );
    }
    return this.booleanDictionary;
  }
}
