import {
  BuildingDto,
  EgibObject,
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ApiNewDokumentPowiazanyDalDto } from '../../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface BOPUCNewRequestDto {
  DaneInwestora:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  Dzialki: ZamowienieDzialkaDto[];
  Budynki: BuildingDto[];
  DotychczasowySposobUzytkowania: string;
  ZamierzonySposobUzytkowania: string;
  InformacjaRobotyBudowlane: string;
  TerminRozpoczeciaRobotBudowlanych: string;
  ZgodaNaKorespondencjeElektroniczna: boolean;
  DanePelnomocnika: InvestorDetailsRequestDto;
}
export class BOPUCConstructionWorksInformation {
  constructor(
    public executionType: string,
    public plannedStartDate: NgbDate,
  ) {}
}

export class BOPUCNewRequest {
  constructor(
    public lawPerson: LawPerson,
    public contactData: LawPerson,
    public currentUse: string,
    public intendedUse: string,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public constructionWorksInformation: BOPUCConstructionWorksInformation,
    public electronicCorrespondenceStatement: boolean,
    public proxyDetails: InvestorDetails,
    public proxyDetailsCheckbox: boolean,
  ) {}

  static fromAppToApi(
    data: BOPUCNewRequest,
    parcels: EgibObject[],
    buildings: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): BOPUCNewRequestDto {
    return {
      DaneInwestora: {
        ...(data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson)),
        Telefon: data.contactData.naturalPersonData.phoneNumber,
        Email: data.contactData.naturalPersonData.email,
      },
      DanePelnomocnika: data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      DotychczasowySposobUzytkowania: data.currentUse,
      ZamierzonySposobUzytkowania: data.intendedUse,
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel),
      ),
      Budynki: buildings.map((building) => BuildingDto.fromAppToApi(building)),
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      InformacjaRobotyBudowlane:
        data.constructionWorksInformation.executionType,
      ZgodaNaKorespondencjeElektroniczna:
        data.electronicCorrespondenceStatement,
      TerminRozpoczeciaRobotBudowlanych: DateHelperUtil.ngbDateToApiDateTime(
        data.constructionWorksInformation.plannedStartDate,
      ),
    };
  }
}
