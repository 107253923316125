import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PprfPropertyFormModel } from './pprf-property-form.model';
import { PprfPropertyFormService } from './pprf-property-form.service';

@Component({
  selector: 'gk-pprf-property-form',
  templateUrl: './pprf-property-form.component.html',
})
export class PprfPropertyFormComponent {
  @Input() form: FormGroup;
  @Input() formModel: PprfPropertyFormModel;
  formlyFormConfig = this.pprfPropertyFormService.getFormConfig();

  constructor(private pprfPropertyFormService: PprfPropertyFormService) {}
}
