import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { b64toBlob, fileToBase64 } from '../../utils/files/files.util';
import {
  DocFile,
  UndeterminedDocFile,
} from '../new-designer-request/new-designer-request.model';
import { ApiNewDokumentPowiazanyDalDto } from './designer-incoming-documents.model';

@Injectable()
export class DesignerIncomingDocumentsService {
  constructor(private httpClient: HttpClient) {}

  sendAttachment(apiDok: ApiNewDokumentPowiazanyDalDto): Observable<any> {
    return this.httpClient.post(
      '/api/zudp/projektant/wniosek/dokprzych/add',
      apiDok,
    );
  }

  async docFileToDtoDocument(
    docFile: DocFile,
    docTypeId?: string | number,
  ): Promise<ApiNewDokumentPowiazanyDalDto | Error> {
    const fileBase = await fileToBase64(docFile).catch(
      () => new Error('Could not convert into base64 :('),
    );

    if (fileBase instanceof Error) {
      return fileBase;
    }

    return new ApiNewDokumentPowiazanyDalDto(
      docFile.name,
      fileBase,
      docTypeId || docFile.docTypeId,
      docFile.docDescription,
    );
  }

  async stringifyDocFile(docFile: DocFile): Promise<string> {
    const undeterminedDocFile: UndeterminedDocFile = {
      base64: await fileToBase64(docFile),
      docTypeId: docFile.docTypeId,
      docDescription: docFile.docDescription,
      name: docFile.name,
    };

    return JSON.stringify(undeterminedDocFile);
  }

  async parseDocFile(stringifiedDocFile: string): Promise<DocFile> {
    const { base64, docTypeId, docDescription, name } = JSON.parse(
      stringifiedDocFile,
    ) as UndeterminedDocFile;

    const parsedFile = (await b64toBlob(base64)) as Partial<DocFile>;
    parsedFile.docTypeId = docTypeId;
    parsedFile.docDescription = docDescription;
    (parsedFile as any).name = name;

    return parsedFile as DocFile;
  }
}
