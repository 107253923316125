import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BaseWebPortalService,
  ColumnHeader,
  DepartmentCode,
  TableSelectionMode,
  getFileNameFromResponseHeaders,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { takeWhile } from 'rxjs';
import { WebRequest } from '../../../../../services/web-request/web-request.model';
import { WebRequestService } from '../../../../../services/web-request/web-request.service';

@Component({
  selector: 'app-modal-requests-list',
  templateUrl: './modal-requests-list.component.html',
  styleUrls: ['./modal-requests-list.component.scss'],
})
export class ModalRequestsListComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @ViewChild('modalContent') modal: NgbModalRef;
  tableSelectionMode = TableSelectionMode;
  downloadingInProgress = false;
  columnHeaderTranslation: string;

  constructor(
    private modalService: NgbModal,
    public webRequestService: WebRequestService,
    private translateService: TranslateService,
    private fileSaverService: FileSaverService,
    private webPortalService: BaseWebPortalService,
  ) {}

  ngOnInit(): void {
    this.loadWebPortalData();
    this.subscribeToColumnHeaderTranslation();
  }

  private loadWebPortalData(): void {
    this.webPortalService
      .getWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (webPortalData) => {
          const department = webPortalData?.find(
            (portal) => portal.departmentCode === DepartmentCode.Geodesy,
          );

          const departmentId = department?.departmentId?.toString();

          if (departmentId) {
            this.webRequestService.fetchWebRequestData(departmentId);
          } else {
            console.error('Department ID not found for Geodesy.');
          }
        },
        (error) => console.error('Failed to load web portal data:', error),
      );
  }

  subscribeToColumnHeaderTranslation(): void {
    this.translateService
      .get('OFFICE_DEPARTMENTS_PORTAL.GEODESY.REQUESTS_LIST.COLUMN_HEADER')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) => (this.columnHeaderTranslation = translations),
      );
  }

  showModal(): void {
    this.modalService.open(this.modal);
  }

  getColumnHeaders(): ColumnHeader[] {
    return [new ColumnHeader('name', this.columnHeaderTranslation)];
  }

  selectFile(webRequest: WebRequest[]): void {
    this.downloadingInProgress = true;
    this.webRequestService
      .downloadBlob(webRequest)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (webRequestHttpResponse) => {
          const fileName = getFileNameFromResponseHeaders(
            webRequestHttpResponse.headers,
          );
          this.fileSaverService.save(webRequestHttpResponse.body, fileName);
          this.downloadingInProgress = false;
        },
        error: () => (this.downloadingInProgress = false),
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
