import { HttpClient } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  GkComponentsModule,
  GkDynamicListModule,
  GkMapModule,
} from '@gk/gk-modules';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../../shared/shared.module';
import { FreeServicesMapComponent } from './free-services-map/free-services-map.component';
import { FreeServicesPortalRoutingModule } from './free-services-portal-routing.module';
import { FreeServicesPortalComponent } from './free-services-portal.component';
import { NewRequestSummaryComponent } from './new-request-summary/new-request-summary.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { FSPNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { UserRequestsDynamicComponent } from './user-requests-dynamic/user-requests-dynamic.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    GkDynamicListModule,
    GkMapModule,
    FreeServicesPortalRoutingModule,
    GkComponentsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  declarations: [
    FreeServicesPortalComponent,
    NewRequestComponent,
    UserRequestsDynamicComponent,
    NewRequestSummaryComponent,
    FreeServicesMapComponent,
  ],
  providers: [FSPNewRequestFormService],
})
export class FreeServicesPortalModule {}
