import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  PortalsPermissions,
  PortalsPermissionsFromApi,
} from './portals-permissions.model';

@Injectable()
export class PortalsPermissionsService {
  private permissions: Observable<PortalsPermissions>;

  constructor(private httpClient: HttpClient) {}

  getPermissions(): Observable<PortalsPermissions> {
    if (!this.permissions) {
      this.permissions = this.httpClient
        .get<PortalsPermissionsFromApi>('/api/system/user/permissions/portale')
        .pipe(
          map((data) => PortalsPermissions.fromApi(data)),
          shareReplay(1),
        );
    }

    return this.permissions;
  }
}
