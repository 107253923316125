import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserPermissions } from './user-permissions.model';

@Injectable()
export class UserPermissionsService {
  constructor(private httpClient: HttpClient) {}

  getUserPermissions(id: UserPermissions): Observable<boolean> {
    return this.httpClient.get<boolean>(`/api/system/user/permission/${id}`);
  }
}
