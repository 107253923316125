import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map } from 'rxjs';
import { PortalId } from '../../utils/portal-id/portal-id.model';
import { PaymentsService } from '../payments.service';
import { StampDutyService } from './../../services/stamp-duty/stamp-duty.service';

@Component({
  selector: 'gk-payment-owner-modal',
  templateUrl: './payment-owner-modal.component.html',
  styleUrl: './payment-owner-modal.component.scss',
})
export class PaymentOwnerModalComponent implements OnInit {
  @Input() portalId: PortalId;
  paymentOwnerId: number | string;

  constructor(
    public stampDutyService: StampDutyService,
    private modal: NgbActiveModal,
    private paymentsService: PaymentsService,
  ) {}

  ngOnInit(): void {
    this.setPaymentOwnerIdIfOneOwner();
  }

  setPaymentOwnerIdIfOneOwner(): void {
    this.stampDutyService
      .getOwners(this.portalId)
      .pipe(
        filter((owners) => owners.length === 1),
        map((owners) => owners[0].id),
      )
      .subscribe((ownerId) => {
        this.paymentOwnerId = ownerId;
      });
  }

  close(): void {
    this.modal.close();
  }

  dismiss(): void {
    this.modal.dismiss();
    this.paymentsService.isPaymentInProgress.emit(false);
  }
}
