import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fileToBase64 } from '../../../utils/files/files.util';

@Injectable()
export class ConfirmationDocumentService {
  constructor(private httpClient: HttpClient) {}

  sendFile(url: string, file: File, docId: number): Promise<Observable<any>> {
    return fileToBase64(file).then((base64) =>
      this.httpClient.post(url, {
        Plik: base64,
        OwnerUuid: docId,
        NazwaPliku: file.name,
      }),
    );
  }
}
