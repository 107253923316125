import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import {
  ApplicantType,
  ColumnHeader,
  CombinedPersonFormComponent,
  DictionaryField,
  DistrictMapObject,
  DocSignService,
  EgibObject,
  Enablement,
  FormAlertService,
  LawPersonService,
  LawPersonType,
  LegalPersonControlName,
  LegalPersonSearch,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  NaturalPersonSearch,
  PzService,
  SourceType,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import {
  Observable,
  filter,
  map,
  of,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { AuthorizedPersonToRegistryView } from '../../../services/authorized-person-to-registry-view/authorized-person-to-registry-view.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import {
  EmptyToolType,
  NewRequestModel,
  NgbDateRange,
  emptyToolType,
} from '../../../services/new-request/new-request.model';
import { NewRequestService } from '../../../services/new-request/new-request.service';
import { RangeType } from '../../../services/parcel/parcel.model';
import { PublicRegisterService } from '../../../services/public-register/public-register.service';
import {
  BsMessageType,
  PrzpNewRequestFormWorkspaceState,
  StringifiedDocFile,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
  maxWorkspaceStateSizeInBytes,
} from '../../../services/request-workspace-state/request-workspace-state.model';
import { RequestWorkspaceStateService } from '../../../services/request-workspace-state/request-workspace-state.service';
import { SettingsService } from '../../../services/settings/settings.service';
import {
  SharingPurpose,
  SharingPurposeControlBindId,
  SharingPurposeDynamicField,
} from '../../../services/sharing-purpose/sharing-purpose.model';
import { SharingPurposeService } from '../../../services/sharing-purpose/sharing-purpose.service';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { DateRangePickerComponent } from '../../../shared/date-range-picker/date-range-picker.component';
import { EnablementComponent } from '../../../shared/enablement/enablement.component';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { stringifyFile } from '../../../utils/files/files.util';
import { getJsonMemorySize } from '../../../utils/json.util';
import {
  FSPNewRequestControlName,
  FSPNewRequestFormValue,
  LegalInterestAutocompleteItem,
} from '../services/new-request-form/new-request-form.model';
import { FSPNewRequestFormService } from '../services/new-request-form/new-request-form.service';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class NewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  legalInterestAutocompleteItems: LegalInterestAutocompleteItem[] = [];
  filteredLegalInterestAutocompleteItems: Observable<
    LegalInterestAutocompleteItem[]
  > = of([]);
  selectedPredefinedLegalInterest: LegalInterestAutocompleteItem;
  ommitAuthorisedPerson = false;

  readonly publicRegistersIdsWithAvailablePersonalData = [1];
  readonly emptyToolType = emptyToolType;
  selectedLegalPersonApplicant: LegalPersonSearch;
  sharingPurposes: SharingPurpose[] = [];
  documentConvertLoading: boolean;
  validatedToPreview = false;
  downloadingPreviewFile = false;
  errorPreviewMessage = '';
  parcelsMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'GK.MAP.REFERENCE_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel],
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    false,
  );
  districtsMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'GK.MAP.REFERENCE_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.District],
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    false,
  );
  rangesMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.ExtentOrPolygon],
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    false,
  );
  mapParcelsObjectTableStateIndex = 0;
  mapDistrictsObjectTableStateIndex = 1;
  mapRangesObjectTableStateIndex = 2;
  enablementDocumentFile: File;
  stringifiedEnablementDocumentFile: StringifiedDocFile;
  workspaceStateTranslations: { [key: string]: string } = {};
  workspaceStateLoading = false;
  workspaceMsgType: BsMessageType;
  workspaceMsgTxt = '';
  @ViewChild(DateRangePickerComponent)
  dateRangePicker: DateRangePickerComponent;
  enablementForm: Enablement;
  initialEnablement = Enablement.getInitialStatutory();
  authorizedPersonsToRegistryView: AuthorizedPersonToRegistryView[] = [];
  publicRegistersDictionary: DictionaryField[];
  toolType = ToolType;
  override controlName = FSPNewRequestControlName;
  @ViewChild(EnablementComponent) enablementComponent: EnablementComponent;
  @ViewChild(CombinedPersonFormComponent)
  combinedPersonFormComponent: CombinedPersonFormComponent;
  principalSetByUser = false;
  applicantType: ApplicantType;
  legalPersonControlName = LegalPersonControlName;

  constructor(
    private sharingPurposeService: SharingPurposeService,
    private newRequestService: NewRequestService,
    private changeDetectorRef: ChangeDetectorRef,
    private requestWorkspaceStateService: RequestWorkspaceStateService,
    private publicRegisterService: PublicRegisterService,
    private fspNewRequestFormService: FSPNewRequestFormService,
    private lawPersonService: LawPersonService,
    public settingsService: SettingsService,
    protected override pzService: PzService,
    protected override newRequestHelperService: NewRequestHelperService,
    protected override docSignService: DocSignService,
    protected override router: Router,
    protected override translateService: TranslateService,
    protected override mapSettingsService: MapSettingsService,
    protected override mapStateService: MapStateService,
    public override formAlertService: FormAlertService,
    protected override fileSaverService: FileSaverService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.fetchPersonTypeMode();
    this.createFormGroup();
    this.subscribeToWorkspaceStateTranslations();
    this.subscribeToMapSettings();
    this.subscribeToSharingPurposeList();
    this.fetchPublicRegistersDictionary();
    this.setInititalAuthorizedPerson();
    this.setInitialPar2Options();
    this.onPar2Change();

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.fetchLastPrzpPrincipal();
  }

  fetchPersonTypeMode(): void {
    this.settingsService
      .getHubSettings()
      .pipe(
        takeWhile(() => this.isAlive),
        map((hubSettings) => hubSettings.przpApplicantType),
      )
      .subscribe((applicantType) => {
        this.applicantType = applicantType;
        this.fetchLastPrzpApplicant();
      });
  }

  createFormGroup(): void {
    this.formGroup = this.fspNewRequestFormService.getFormGroup();
  }

  fetchLastPrzpApplicant(): void {
    this.lawPersonService
      .getLastPrzpApplicant()
      .pipe(
        takeWhile(() => this.isAlive),
        filter((applicant) => !!applicant),
      )
      .subscribe((applicant) => {
        switch (applicant.type) {
          case LawPersonType.Legal: {
            this.combinedPersonFormComponent.updateChosenLegalPersonIfConfigAllows(
              applicant,
            );
          }
        }
      });
  }

  fetchLastPrzpPrincipal(): void {
    this.lawPersonService
      .getLastPrzpPrincipal()
      .pipe(
        takeWhile(() => this.isAlive),
        filter((principal) => !!principal),
      )
      .subscribe((principal) => {
        this.enablementComponent.setValues(
          principal,
          this.enablementForm?.type,
          false,
        );
      });
  }

  setInititalAuthorizedPerson(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => {
        if (_.isEmpty(petentData)) {
          return;
        }

        this.authorizedPersonsToRegistryView = [
          ...this.authorizedPersonsToRegistryView,
          new AuthorizedPersonToRegistryView(
            `${petentData.firstname} ${petentData.lastname}`,
            petentData.pesel,
            true,
          ),
        ];
      });
  }

  public onPar2Change(): void {
    this.getPar2FormControl()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        tap((value) => this.handleNewPar2Value(value)),
        startWith(''),
        map((value) => this._filter(value || '')),
      )
      .subscribe((filteredValue) => {
        this.filteredLegalInterestAutocompleteItems = of(filteredValue);
      });
  }

  public handleNewPar2Value(
    newValue: LegalInterestAutocompleteItem | string,
  ): void {
    if (typeof newValue === 'string') {
      this.selectedPredefinedLegalInterest = null;
      this.resetOmmitAuthorisedPersonToDefaultValue();
      this.checkIfValueExistInDictionary(newValue);
    }
  }

  public checkIfValueExistInDictionary(value: string): void {
    const foundValue = this.legalInterestAutocompleteItems.find(
      (autocompleteItem) => autocompleteItem.value === value,
    );
    if (foundValue) {
      this.handleLegalInterestAutocompleteItem(foundValue);
    }
  }

  public setInitialPar2Options(): void {
    this.fspNewRequestFormService
      .getLegalInterests()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((legalInterests) => {
        this.legalInterestAutocompleteItems = legalInterests.map((item) => ({
          ommitAuthorisedPerson: item.autoEnabled,
          value: item.name,
          id: item.id,
        }));
        this.filteredLegalInterestAutocompleteItems = of(
          this.legalInterestAutocompleteItems,
        );
      });
  }

  public _filter(
    valueToFilter: string | LegalInterestAutocompleteItem,
  ): LegalInterestAutocompleteItem[] {
    const filterValue =
      typeof valueToFilter === 'string'
        ? valueToFilter.toLowerCase()
        : valueToFilter.value.toLowerCase();

    return this.legalInterestAutocompleteItems.filter((autocompleteItem) => {
      return autocompleteItem.value.toLowerCase().includes(filterValue);
    });
  }

  public onValueSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value as LegalInterestAutocompleteItem;
    this.applyAutocompleteOption(selectedOption);
  }

  public applyAutocompleteOption(option: LegalInterestAutocompleteItem): void {
    this.getPar2FormControl().setValue(option.value);
    this.handleLegalInterestAutocompleteItem(option);
  }

  public handleLegalInterestAutocompleteItem(
    option: LegalInterestAutocompleteItem,
  ): void {
    this.selectedPredefinedLegalInterest = option;
    if (option.ommitAuthorisedPerson) {
      this.ommitAuthorisedPerson = true;
    } else {
      this.resetOmmitAuthorisedPersonToDefaultValue();
    }
  }

  public resetOmmitAuthorisedPersonToDefaultValue(): void {
    this.ommitAuthorisedPerson = false;
  }

  subscribeToWorkspaceStateTranslations(): void {
    this.translateService
      .get('WORKSPACE_STATE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) => (this.workspaceStateTranslations = translations),
      );
  }

  subscribeToMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.FreeServices)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((freeServicesMapSettings) => {
        this.setMapState(freeServicesMapSettings);
      });
  }

  fetchPublicRegistersDictionary(): void {
    this.publicRegisterService.publicRegisters
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((publicRegisters) => {
        this.publicRegistersDictionary = publicRegisters;
        this.setFirstPublicRegisterItemIfIsOnlyOne();
      });
  }

  setFirstPublicRegisterItemIfIsOnlyOne(): void {
    if (this.publicRegistersDictionary.length === 1) {
      this.getPublicRegisterFormControl().setValue(
        `${this.publicRegistersDictionary[0].id}`,
      );
    }
  }

  isPersonalDataControlShouldBeVisible(): boolean {
    const currentSelectedId = parseInt(
      this.getPublicRegisterFormControl().value,
      10,
    );
    return (
      currentSelectedId &&
      this.publicRegistersIdsWithAvailablePersonalData.includes(
        currentSelectedId,
      )
    );
  }

  getPublicRegisterFormControl(): FormControl<string> {
    return this.formGroup.get(
      this.controlName.PublicRegister,
    ) as FormControl<string>;
  }

  getPar2FormControl(): FormControl<string> {
    return this.formGroup.get(this.controlName.Par2) as FormControl<string>;
  }

  override setMapState(mapSettings: MapSettings): void {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [SourceType.RangeFromAllParcels]: _,
      ...anyPolygonConfWithoutRangeFromAllParcels
    } = this.initialToolsState[ToolType.AnyPolygon];
    this.mapState = new MapState(
      MapId.NewFreeServicesRequestParcels,
      this.mapStateService.getViewState(
        MapId.NewFreeServicesRequestParcels,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.District, ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      {
        ...this.initialToolsState,
        [ToolType.AnyPolygon]: {
          ...anyPolygonConfWithoutRangeFromAllParcels,
          singleObjectMode: true,
        },
        [ToolType.LandParcel]: {
          ...this.initialToolsState[ToolType.LandParcel],
          isActive: true,
          [SourceType.Point]: {
            ...this.initialToolsState[ToolType.LandParcel][SourceType.Point],
            isActive: true,
          },
        },
      },
      this.mapStateService.getLayersState(
        MapId.NewFreeServicesRequestParcels,
        mapSettings,
        MapPortalName.FreeServices,
      ),
      this.getMapObjectTablesState(),
    );
  }

  getMapObjectTablesState(): MapObjectTableState[] {
    const mapObjectTablesState = [];
    mapObjectTablesState[this.mapParcelsObjectTableStateIndex] =
      this.parcelsMapObjectTableState;
    mapObjectTablesState[this.mapDistrictsObjectTableStateIndex] =
      this.districtsMapObjectTableState;
    mapObjectTablesState[this.mapRangesObjectTableStateIndex] =
      this.rangesMapObjectTableState;

    return mapObjectTablesState;
  }

  subscribeToSharingPurposeList(): void {
    this.sharingPurposeService.sharingPurposes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((sharingPurposes) => {
        this.sharingPurposes = sharingPurposes;
        this.getSharingPurposeFormControl().setValue(sharingPurposes[0]);
      });
  }

  getSharingPurposeFormControl(): FormControl<SharingPurpose> {
    return this.formGroup.get(
      this.controlName.Purpose,
    ) as FormControl<SharingPurpose>;
  }

  shouldShowControl(bindId: SharingPurposeControlBindId): boolean {
    const { value } = this.getSharingPurposeFormControl();

    return !!_.find(
      _.get(value, 'fields'),
      (field: SharingPurposeDynamicField) => field.bindId === bindId,
    );
  }

  getControlLabel(bindId: SharingPurposeControlBindId): string {
    const { value } = this.getSharingPurposeFormControl();

    return _.find(
      _.get(value, 'fields'),
      (field: SharingPurposeDynamicField) => field.bindId === bindId,
    ).label;
  }

  shouldShowErrorAlert(
    formControlName: FSPNewRequestControlName,
    errorName: string,
  ): boolean {
    const formControl = this.formGroup.get(formControlName);
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: AbstractControl): boolean {
    return !!(
      formControl &&
      (formControl.dirty || formControl.touched || this.submitted)
    );
  }

  hasError(formControl: AbstractControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  isDateRangeCorrect(): boolean {
    if (!this.areAllDatesFromRangeDefined()) {
      return false;
    }
    const { from, to } = this.getPeriodFormControl().value;

    const fromTime = new Date(from.year, from.month - 1, from.day).getTime();
    const toTime = new Date(to.year, to.month - 1, to.day).getTime();

    return fromTime <= toTime;
  }

  isPastFromDate(): boolean {
    if (!this.areAllDatesFromRangeDefined()) {
      return false;
    }
    const { from } = this.getPeriodFormControl().value;

    const fromDate = new Date(from.year, from.month - 1, from.day);
    const currentDate = DateHelperUtil.getCurrentDate();
    currentDate.setHours(0, 0, 0, 0);

    return fromDate < currentDate;
  }

  isDateToSameAsToday(): boolean {
    if (!this.areAllDatesFromRangeDefined()) {
      return false;
    }
    const { to } = this.getPeriodFormControl().value;

    const toDate = new Date(to.year, to.month - 1, to.day);
    const currentDate = DateHelperUtil.getCurrentDate();
    currentDate.setHours(0, 0, 0, 0);

    return toDate.getTime() === currentDate.getTime();
  }

  areAllDatesFromRangeDefined(): boolean {
    const period = this.getPeriodFormControl().value || {};
    const { from, to } = period;

    const allDefined = !!(
      from &&
      from.year &&
      from.month &&
      from.day &&
      to &&
      to.year &&
      to.month &&
      to.day
    );

    return allDefined;
  }

  isDateRangeEmpty(): boolean {
    const period = this.getPeriodFormControl().value || {};
    return _.isEmpty(period.from) && _.isEmpty(period.to);
  }

  isDateRangeCorrectOrEmpty(): boolean {
    return this.isDateRangeCorrect() || this.isDateRangeEmpty();
  }

  shoulShowDateRangeAlert(): boolean {
    return (
      this.isSubmittedAndIsDateRangeCorrect() ||
      this.isValidatedToPreviewAndIsDateRangeNotCorrectOrEmpty()
    );
  }

  isSubmittedAndIsDateRangeCorrect(): boolean {
    return this.submitted && !this.isDateRangeCorrect();
  }

  isValidatedToPreviewAndIsDateRangeNotCorrectOrEmpty(): boolean {
    return this.validatedToPreview && !this.isDateRangeCorrectOrEmpty();
  }

  shoulShowDatePastAlert(): boolean {
    return (
      this.isSubmittedAndIsDateFromPast() ||
      this.isValidatedToPreviewAndIsDateFromPastOrEmpty()
    );
  }

  isSubmittedAndIsDateFromPast(): boolean {
    return this.submitted && this.isPastFromDate();
  }

  isValidatedToPreviewAndIsDateFromPastOrEmpty(): boolean {
    return this.validatedToPreview && !this.isDateNotFromPastOrEmpty();
  }

  isDateNotFromPastOrEmpty(): boolean {
    return !this.isPastFromDate() || this.isDateRangeEmpty();
  }

  shoulShowDateToSameAsTodayAlert(): boolean {
    return (
      this.isSubmittedAndIsDateToSameAsToday() ||
      this.isValidatedToPreviewAndIsDateToSameAsTodayOrEmpty()
    );
  }

  isSubmittedAndIsDateToSameAsToday(): boolean {
    return this.submitted && this.isDateToSameAsToday();
  }

  isValidatedToPreviewAndIsDateToSameAsTodayOrEmpty(): boolean {
    return this.validatedToPreview && !this.dateToIsNotSameAsTodayOrEmpty();
  }

  dateToIsNotSameAsTodayOrEmpty(): boolean {
    return !this.isDateToSameAsToday() || this.isDateRangeEmpty();
  }

  handleDateRangeChanged(dateRange: NgbDateRange): void {
    this.getPeriodFormControl().setValue(dateRange);
  }

  getPeriodFormControl(): UntypedFormControl {
    return this.formGroup.get(this.controlName.Period) as UntypedFormControl;
  }

  isFormValid(): boolean {
    return (
      this.formGroup.valid &&
      this.combinedPersonFormComponent.isValid() &&
      !this.getInvalidToolType() &&
      this.isDateRangeCorrect() &&
      (this.ommitAuthorisedPerson ||
        (this.authorizedPersonsToRegistryView.length > 0 &&
          this.enablementForm.valid))
    );
  }

  isFormValidToPreview(): boolean {
    return this.isDateRangeCorrectOrEmpty() && this.enablementForm.valid;
  }

  handleSubmit(): void {
    this.submitted = true;

    if (!this.isFormValid()) {
      return;
    }

    this.sendAndValidateDto();
  }

  sendAndValidateDto(): void {
    this.docSignPending = true;
    this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');

    this.getUpdatedModelValue()
      .pipe(
        switchMap((modelValue) =>
          this.newRequestService.valideDtoAndAddToSign(modelValue),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) =>
          this.handleSendAndValidateSuccess(addedDocToSignResponse),
        error: () => this.handleSendAndValidateFailure(),
      });
  }
  getUpdatedModelValue(): Observable<NewRequestModel> {
    return this.combinedPersonFormComponent.getSelectedLegalPerson().pipe(
      map((selectedLegalPerson) => ({
        ...this.getFormValue(),
        applicantId: selectedLegalPerson?.id,
        enablement: {
          ...this.enablementForm,
          principal: this.getPrincipal(selectedLegalPerson),
        },
        districts: this.getDistricts(),
        parcels: this.getParcels(),
        personalData:
          this.isPersonalDataControlShouldBeVisible() &&
          this.getPersonalDataFormControl().value,
        wkt: this.getPolygon(),
        rangeType: this.getRangeType(),
        authorizedPersonsToRegistryView: this.authorizedPersonsToRegistryView,
        predefinedLegalInterest: this.getPredefinedLegalInterest(),
      })),
    );
  }

  public getPredefinedLegalInterest(): LegalInterestAutocompleteItem {
    return this.selectedPredefinedLegalInterest;
  }

  getPrincipal(
    selectedLegalPerson: LegalPersonSearch,
  ): LegalPersonSearch | NaturalPersonSearch {
    const legalPrincipalData =
      this.enablementForm?.principal?.data?.legalPersonData;

    return selectedLegalPerson?.id &&
      !this.enablementForm?.principal?.id &&
      legalPrincipalData?.regon === this.selectedLegalPersonApplicant?.regon
      ? selectedLegalPerson
      : this.enablementForm?.principal;
  }

  getPersonalDataFormControl(): FormControl<boolean> {
    return this.formGroup.get(
      this.controlName.PersonalData,
    ) as FormControl<boolean>;
  }

  getRangeType(): RangeType {
    const activeToolType = this.getActiveToolType();

    switch (activeToolType) {
      case ToolType.LandParcel:
        return RangeType.Parcels;
      case ToolType.District:
        return RangeType.Districts;
      case ToolType.AnyPolygon:
        return RangeType.Wkt;
      default:
        return undefined;
    }
  }

  getInvalidToolType(): ToolType | EmptyToolType {
    switch (this.getActiveToolType()) {
      case ToolType.LandParcel:
        return _.isEmpty(
          this.mapState.mapObjectTablesState[
            this.mapParcelsObjectTableStateIndex
          ].mapObjects,
        )
          ? ToolType.LandParcel
          : undefined;
      case ToolType.District:
        return _.isEmpty(
          this.mapState.mapObjectTablesState[
            this.mapDistrictsObjectTableStateIndex
          ].mapObjects,
        )
          ? ToolType.District
          : undefined;
      case ToolType.AnyPolygon: {
        const mapObjects =
          this.mapState.mapObjectTablesState[
            this.mapRangesObjectTableStateIndex
          ].mapObjects;
        return mapObjects && mapObjects.length !== 1
          ? ToolType.AnyPolygon
          : undefined;
      }
      default:
        return this.emptyToolType;
    }
  }

  getActiveToolType(): ToolType {
    return Object.keys(this.mapState.toolsState).find(
      (object: unknown) =>
        this.mapState.toolsState[object as ToolType].isActive,
    ) as ToolType;
  }

  getDistricts(): DistrictMapObject[] {
    const mapObjectTableState =
      this.mapState.mapObjectTablesState[
        this.mapDistrictsObjectTableStateIndex
      ];

    return mapObjectTableState
      ? (mapObjectTableState.mapObjects as DistrictMapObject[])
      : [];
  }

  getParcels(): EgibObject[] {
    const mapObjectTableState =
      this.mapState.mapObjectTablesState[this.mapParcelsObjectTableStateIndex];

    return mapObjectTableState
      ? (mapObjectTableState.mapObjects as EgibObject[])
      : [];
  }

  getPolygon(): string {
    const polygons = this.getPolygons();

    return polygons && polygons[0] && polygons[0].geom;
  }

  getPolygons(): MapObject[] {
    const mapObjectTableState =
      this.mapState.mapObjectTablesState[this.mapRangesObjectTableStateIndex];

    return mapObjectTableState ? mapObjectTableState.mapObjects : [];
  }

  handlePreviewNewRequest(): void {
    this.validatedToPreview = true;
    if (this.isFormValidToPreview()) {
      this.previewRequest();
    }
  }

  previewRequest(): void {
    this.downloadingPreviewFile = true;
    this.getUpdatedModelValue()
      .pipe(
        switchMap((modelValue) =>
          this.newRequestService.previewNewRequest(modelValue),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (file: Blob) => {
          this.fileSaverService.save(
            file,
            'podglad-nowy-wniosek-portal-przp.pdf',
          );
          this.downloadingPreviewFile = false;
        },
        error: (err) => {
          console.error(err);
          this.errorPreviewMessage =
            this.newRequestService.getResponseStatusMessage(err) ||
            'FAILED_FILE_DOWNLOAD';
          this.downloadingPreviewFile = false;
        },
      });
  }

  setWorkspaceMsg(type: BsMessageType, text: string): void {
    this.workspaceMsgType = type;
    this.workspaceMsgTxt = text;
  }

  setWorkspaceLoading(translationKey: string): void {
    this.setWorkspaceMsg(
      BsMessageType.Info,
      this.workspaceStateTranslations[translationKey],
    );
    this.workspaceStateLoading = true;
  }

  handleWorkspaceJobDone(success: boolean, translationKey: string): void {
    this.setWorkspaceMsg(
      success ? BsMessageType.Success : BsMessageType.Danger,
      this.workspaceStateTranslations[translationKey],
    );
    this.workspaceStateLoading = false;
  }

  async getWorkspaceStateToSave(): Promise<PrzpNewRequestFormWorkspaceState> {
    return {
      formValue: this.getFormValue(),
      authorizedPersonsToRegistryView: this.authorizedPersonsToRegistryView,
      stringifiedEnablementDocumentFile: await stringifyFile(
        this.enablementDocumentFile,
      ),
      enablementType: this.enablementForm.type,
      principal: this.enablementForm.principal,
      mapState: this.mapState,
      selectedLegalPersonApplicant: this.selectedLegalPersonApplicant,
      draftVersion: WorkspaceStateDraftVersion.PrzpNewRequestFormVersion,
    };
  }

  getFormValue(): FSPNewRequestFormValue {
    return this.formGroup.getRawValue();
  }

  hasWorkspaceProperMemorySize(
    workspace: PrzpNewRequestFormWorkspaceState,
  ): boolean {
    const jsonSize = getJsonMemorySize(
      _.omit(_.cloneDeep(workspace), 'stringifiedEnablementDocumentFile'),
    );
    const filesSize = this.enablementDocumentFile
      ? this.enablementDocumentFile.size
      : 0;

    return jsonSize + filesSize <= maxWorkspaceStateSizeInBytes;
  }

  loadWorkspaceState(): void {
    this.setWorkspaceLoading('DOWNLOADING');
    this.requestWorkspaceStateService
      .getWorkspaceState<PrzpNewRequestFormWorkspaceState>(
        WorkspaceStateId.PrzpNewRequestForm,
      )
      .subscribe({
        next: (state) => {
          try {
            this.handleLoadWorkspaceResponse(state);
          } catch {
            this.handleWorkspaceJobDone(false, 'NOT_COMPATIBLE');
          }
        },
        error: () => this.handleWorkspaceJobDone(false, 'ERROR_OCCURED'),
      });
  }

  handleLoadWorkspaceResponse(state: PrzpNewRequestFormWorkspaceState): void {
    if (!state) {
      this.handleWorkspaceJobDone(false, 'EMPTY_WORKSPACE');
      return;
    }
    if (
      state.draftVersion !==
      WorkspaceStateDraftVersion.PrzpNewRequestFormVersion
    ) {
      this.handleWorkspaceJobDone(false, 'NOT_COMPATIBLE');
      return;
    }
    this.setEnablementDocumentFromWorkspace(
      state.stringifiedEnablementDocumentFile,
    );
    if (state.formValue?.period) {
      this.dateRangePicker.setPeriod(state.formValue?.period);
    }
    this.formGroup.patchValue(state.formValue);
    this.updateChosenLegalPersonIfLegalPersonType(state);
    this.handleWorkspaceJobDone(true, 'LOADED_SUCCEED');
    this.authorizedPersonsToRegistryView =
      state.authorizedPersonsToRegistryView;
    this.mapState = state.mapState;
    this.enablementComponent.setValues(state.principal, state.enablementType);
  }

  updateChosenLegalPersonIfLegalPersonType(
    state: PrzpNewRequestFormWorkspaceState,
  ): void {
    if (state?.formValue?.lawPerson?.type === LawPersonType.Legal) {
      this.combinedPersonFormComponent.updateChosenLegalPersonIfConfigAllows(
        state.selectedLegalPersonApplicant,
      );
    }
  }

  setEnablementDocumentFromWorkspace(
    stringifiedDocument: StringifiedDocFile,
  ): void {
    this.setStringifiedEnablementDocument('');
    this.setStringifiedEnablementDocument(stringifiedDocument);
  }

  setStringifiedEnablementDocument(
    stringifiedDocument: StringifiedDocFile,
  ): void {
    this.stringifiedEnablementDocumentFile = stringifiedDocument;
    this.changeDetectorRef.detectChanges();
  }

  async saveWorkspaceState(): Promise<void> {
    this.setWorkspaceLoading('UPLOADING');
    const workspaceToSave = await this.getWorkspaceStateToSave();
    if (!this.hasWorkspaceProperMemorySize(workspaceToSave)) {
      this.handleWorkspaceJobDone(false, 'TOO_BIG');
      return;
    }
    this.requestWorkspaceStateService
      .updateWorkspaceState(
        WorkspaceStateId.PrzpNewRequestForm,
        workspaceToSave,
      )
      .subscribe({
        next: () => this.handleWorkspaceJobDone(true, 'UPLOADED_SUCCEED'),
        error: () => this.handleWorkspaceJobDone(false, 'ERROR_OCCURED'),
      });
  }

  onEnablementFormChange(enablement: Enablement): void {
    if (enablement.principal && enablement.principalSetByUser) {
      this.principalSetByUser = true;
    }
    this.enablementForm = enablement;
  }

  onApplicantChange(lawPerson: LegalPersonSearch): void {
    this.selectedLegalPersonApplicant = lawPerson || undefined;
    if (!lawPerson) {
      return;
    }
    this.updatePrincipalIfNeeded(lawPerson);
  }

  updatePrincipalIfNeeded(lawPerson: LegalPersonSearch): void {
    if (!this.principalSetByUser || !this.enablementForm?.principal) {
      this.principalSetByUser = false;
      this.enablementComponent.setValues(
        lawPerson,
        this.enablementForm?.type,
        false,
      );
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
