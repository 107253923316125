import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import {
  DocumentSection,
  DocumentSectionApi,
  DocumentSectionTypeId,
} from './document-section.model';

@Injectable()
export class DocumentSectionService {
  private documentSectionsMap = new Map<
    PortalId,
    Observable<DocumentSection[]>
  >();
  private proxyAttachmentsSelectedMap = new Map<
    PortalId,
    BehaviorSubject<boolean>
  >();

  constructor(private httpClient: HttpClient) {}

  clearCache(portalId: PortalId): void {
    this.documentSectionsMap.delete(portalId);
    this.proxyAttachmentsSelectedMap.delete(portalId);
  }

  get(portalId: PortalId): Observable<DocumentSection[]> {
    let documentSections$ = this.documentSectionsMap.get(portalId);

    if (!documentSections$) {
      documentSections$ = this.httpClient
        .get<DocumentSectionApi[]>(`/api/eod/kdokrodz/portal/${portalId}/pliki`)
        .pipe(
          map((documentSectionsApi) =>
            documentSectionsApi.map((documentSectionApi) =>
              DocumentSection.fromApiToApp(documentSectionApi),
            ),
          ),
          shareReplay(1),
        );
      this.documentSectionsMap.set(portalId, documentSections$);
    }

    return documentSections$;
  }

  getProxyAttachmentsSelected(portalId: PortalId): BehaviorSubject<boolean> {
    let proxyAttachmentsSelected$ =
      this.proxyAttachmentsSelectedMap.get(portalId);

    if (!proxyAttachmentsSelected$) {
      proxyAttachmentsSelected$ = new BehaviorSubject<boolean>(false);
      this.proxyAttachmentsSelectedMap.set(portalId, proxyAttachmentsSelected$);
    }

    return proxyAttachmentsSelected$;
  }

  isProxyAttachmentAvailableToPayOnline(
    portalId: PortalId,
  ): Observable<boolean> {
    return this.get(portalId).pipe(
      map((documentSections) =>
        documentSections.some(
          (documentSection) =>
            documentSection.id ===
              DocumentSectionTypeId.PowerOfAttorneyStampDuty &&
            documentSection.isStampDutyPaymentEnabled,
        ),
      ),
      shareReplay(1),
    );
  }

  downloadSampleDocument(
    sampleDocumentId: string | number,
  ): Observable<HttpResponse<Blob>> {
    const body = { Id: sampleDocumentId };
    return this.httpClient.post('/api/system/web/plik/download', body, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
