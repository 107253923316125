import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DictionaryField,
  DocSignService,
  EgibObject,
  FormAlertService,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { SimplifiedForestManagementPlanControlName } from '../../../services/environmental-protection/simplified-forest-management-form/simplified-forest-management-form.model';
import { SimplifiedForestManagementFormService } from '../../../services/environmental-protection/simplified-forest-management-form/simplified-forest-management-form.service';
import {
  SimplifiedForestManagementPlan,
  SimplifiedForestManagementPlanRequestDto,
} from '../../../services/environmental-protection/simplified-forest-management-plan/simplified-forest-management-plan.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { ExclusionPurposeService } from '../../../services/exclusion-purpose/exclusion-purpose.service';
import { FormsStateService } from '../../../services/forms-state/forms-state.service';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-simplified-forest-management-plan-request',
  templateUrl: './simplified-forest-management-plan-request.component.html',
})
export class SimplifiedForestManagementPlanRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  override controlName = SimplifiedForestManagementPlanControlName;
  exclusionPurposes: DictionaryField[];
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
    [
      ...this.formsStateService
        .simplifiedForestManagementPlanRequestChosenParcels,
    ],
  );
  formValue: SimplifiedForestManagementPlanRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  override portalId =
    PortalId.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest;

  constructor(
    public simplifiedForestManagementFormService: SimplifiedForestManagementFormService,
    public override mapStateService: MapStateService,
    private exclusionPurposesService: ExclusionPurposeService,
    public override mapSettingsService: MapSettingsService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public formsStateService: FormsStateService,
    public override pzService: PzService,
    public override formAlertService: FormAlertService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToExclusionPurposesList();
    this.fetchApplicantMapSettings();
    this.subscribeToDecisionFormRadioFormControl();
    this.fetchWrongFileText();
  }

  createForm(): void {
    this.formGroup = this.simplifiedForestManagementFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  subscribeToExclusionPurposesList(): void {
    this.exclusionPurposesService.exclusionPurposes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((exclusionPurposes) => {
        this.exclusionPurposes = exclusionPurposes;
      });
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewEnvironmentalProtectionPortalRequest,
      this.mapStateService.getViewState(
        MapId.NewEnvironmentalProtectionPortalRequest,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewEnvironmentalProtectionPortalRequest,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            SimplifiedForestManagementPlanRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/objecieDzialkiUpul/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.attachmentsComponent.areDocumentsValid()
    );
  }

  getFormValue(): SimplifiedForestManagementPlan {
    return {
      ...this.formGroup.value,
      landParcel: this.getParcelMapObject(),
    };
  }

  getParcelMapObject(): ZamowienieDzialkaDto {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        ),
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data))[0];
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      SimplifiedForestManagementPlanControlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  checkInvestorDetailsFormGroupValidAndFormValidated(): boolean {
    return !this.getInvestorDetailsFormGroup().valid && this.submitted;
  }

  checkParcelsAndExclusionAreasValidAndFormValidated(): boolean {
    return !this.areParcelsChosen() && this.submitted;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
