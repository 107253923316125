import { SourceType, ToolType } from './map-state.model';

export class MapAction {
  constructor(
    public type: any,
    public payload?: any,
    public mapObjectTableStateIndex?: number,
  ) {}
}

export interface PayloadOptions {
  toolType?: ToolType;
  sourceType?: SourceType;
}

export enum MapViewActionType {
  MapExtentChange = 'map-extent-change',
  ResolutionChange = 'resolution-change',
  ExtentToFitToChange = 'extent-to-fit-to-change',
  IsSidebarExpandedChange = 'is-sidebar-expanded-change',
  BackScrollIntoViewRefChange = 'back-scroll-into-view-ref-change',
  IsPreviewModeChange = 'is-preview-mode-change',
}

export enum MapToolbarType {
  ToolTypesChange = 'tool-types-change',
}

export enum ToolActionType {
  IsActiveToolChange = 'is-active-tool-change',
  MapObjectsToolChange = 'map-objects-tool-change',
  IsVisiblePopupChange = 'is-visible-popup-change',
  IsVisiblePopupLoaderChange = 'is-visible-popup-loader-change',
  MapObjectsWithAttributesChange = 'map-objects-with-attributes-change',
  PreviewedMapObjectsChange = 'previewed-map-objects-change',
  Clear = 'clear',
  ClearAllMapObjects = 'clear-all-map-objects',
  AddMeasurementStaticOverlay = 'add-measurement-static-overlay',
  ClearMeasurements = 'clear-measurements',
  ToolStateChange = 'tool-state-change',
}

export enum SourceActionType {
  IsActiveSourceChange = 'is-active-source-change',
  LastWktChange = 'last-wkt-change',
  FormValueChange = 'form-value-change',
  MapObjectsSourceChange = 'map-objects-source-change',
  MapObjectTableStateChange = 'map-object-table-state-change',
  IsVisibleTooltipLoaderChange = 'is-visible-tooltip-loader-change',
  IsVisibleAlertChange = 'is-visible-alert-change',
  IsVisibleTooltipChange = 'is-visible-tooltip-change',
}

export enum MapLayersActionType {
  TreeNodeChange = 'tree-node-change',
}

export enum MapObjectsActionType {
  Change = 'change',
  Update = 'update',
}

export enum MapObjectTableActionType {
  SelectAll = 'select-all',
  SelectMultiple = 'select-multiple',
  SelectRange = 'select-range',
  Select = 'select',
  DeselectAll = 'deselect-all',
  DeselectMultiple = 'deselect-multiple',
  Deselect = 'deselect',
  AddNew = 'add-new',
  AddToExisting = 'add-to-existing',
  RemoveAll = 'remove-all',
  RemoveSelected = 'remove-selected',
  Remove = 'remove',
  Edit = 'edit',
  RemoveFromEdition = 'remove-from-edition',
  EditedMapObjectGeometryUpdate = 'edited-map-object-geometry-update',
  EditedMapObjectAreaUpdate = 'edited-map-object-area-update',
  MapObjectsUpdate = 'map-objects-update',
  RestoreInitialEditedMapObject = 'restore-initial-edited-map-object',
  IsVisibleTopologyValidationLoaderChange = 'is-visible-topology-validation-loader-change',
  IsVisibleTopologyValidationErrorChange = 'is-visible-topology-validation-error-change',
  CreateRangeFromSelectedParcels = 'create-range-from-selected-parcels',
  MapGeometryStyleConfigChange = 'map-geometry-style-config-change',
}
