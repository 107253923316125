import {
  iwIconColorNarysujZakres2,
  iwIconModyfikujZakres,
  iwIconPodgladMapa,
  iwIconZobaczZakres,
} from '../../../assets/css/ewid-svg/svg-icons';
import { SourceType } from '../../../gk-map/models';
import {
  GenericToolbarModifyGeometryPayload,
  GenericToolbarShowGeometryPayload,
  GridToolBarItem,
  GridToolbarItemMapDrawingPayload,
} from '../gk-kendo-grid.model';
import { GkKendoGridToolbarComponent } from './gk-kendo-grid-toolbar.component';

export const getGenericToolbarItems = (
  c: GkKendoGridToolbarComponent,
): GridToolBarItem[] => [
  {
    name: GenericToolbarItemName.GetPointGeometryFromMap,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_RANGE_ON_MAP',
    callback: (payload) =>
      c.onGetGeometryFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Point,
      ),
  },
  {
    name: GenericToolbarItemName.GetLineGeometryFromMap,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_RANGE_ON_MAP',
    callback: (payload) =>
      c.onGetGeometryFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.LineString,
      ),
  },
  {
    name: GenericToolbarItemName.GetPolygonGeometryFromMap,
    svgIcon: iwIconColorNarysujZakres2,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_RANGE_ON_MAP',
    callback: (payload) =>
      c.onGetGeometryFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Polygon,
      ),
  },
  {
    name: GenericToolbarItemName.GetCircleGeometryFromMap,
    svgIcon: iwIconPodgladMapa,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_RANGE_ON_MAP',
    callback: (payload) =>
      c.onGetGeometryFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Circle,
      ),
  },
  {
    name: GenericToolbarItemName.GetLandParcelByPointFromMap,
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    callback: (payload) =>
      c.onGetLandParcelFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Point,
      ),
  },
  {
    name: GenericToolbarItemName.GetLandParcelByPolygonFromMap,
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    callback: (payload) =>
      c.onGetLandParcelFromMapToolbarButtonClick(
        payload as GridToolbarItemMapDrawingPayload,
        SourceType.Polygon,
      ),
  },
  {
    name: GenericToolbarItemName.ModifyGeometry,
    svgIcon: iwIconModyfikujZakres,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.MODIFY_GEOM',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.MODIFY_GEOM',
    showText: 'overflow',
    callback: (payload) =>
      c.onModifyGeometryToolbarButtonClick(
        payload as GenericToolbarModifyGeometryPayload,
      ),
  },
  {
    name: GenericToolbarItemName.ShowGeometry,
    svgIcon: iwIconZobaczZakres,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.MAP_PREVIEW',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.MAP_PREVIEW',
    showText: 'overflow',
    callback: (payload) =>
      c.onShowGeometryToolbarButtonClick(
        payload as GenericToolbarShowGeometryPayload,
      ),
  },
];

export enum GenericToolbarItemName {
  GetPointGeometryFromMap = 'getPointGeometryFromMapStandalone',
  GetLineGeometryFromMap = 'getLineGeometryFromMapStandalone',
  GetPolygonGeometryFromMap = 'getPolygonGeometryFromMapStandalone',
  GetCircleGeometryFromMap = 'getCircleGeometryFromMapStandalone',
  GetLandParcelByPointFromMap = 'getLandParcelByPointFromMapStandalone',
  GetLandParcelByPolygonFromMap = 'getLandParcelByPolygonFromMapStandalone',
  ModifyGeometry = 'modifyGeometryStandalone',
  ShowGeometry = 'showGeometryStandalone',
}
