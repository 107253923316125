import {
  EgibObject,
  FieldId,
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ApiNewDokumentPowiazanyDalDto } from '../../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface SFBCCNewRequestDto {
  DaneInwestora:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  Dzialki: ZamowienieDzialkaDto[];
  RodzajZamierzeniaBudowlanego: FieldId[];
  TerminRozpoczeciaRobotBudowlanych: string;
  ZgodaNaKorespondencjeElektroniczna: boolean;
  DanePelnomocnika: InvestorDetailsRequestDto;
}

export class SFBCCNewRequest {
  constructor(
    public lawPerson: LawPerson,
    public contactData: LawPerson,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public constructionIntentionType: FieldId[],
    public plannedStartDate: NgbDate,
    public electronicCorrespondenceStatement: boolean,
    public proxyDetails: InvestorDetails,
    public proxyDetailsCheckbox: boolean,
  ) {}

  static fromAppToApi(
    data: SFBCCNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): SFBCCNewRequestDto {
    return {
      DaneInwestora: {
        ...(data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson)),
        Telefon: data.contactData.naturalPersonData.phoneNumber,
        Email: data.contactData.naturalPersonData.email,
      },
      DanePelnomocnika: data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel),
      ),
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      RodzajZamierzeniaBudowlanego: data.constructionIntentionType,
      TerminRozpoczeciaRobotBudowlanych: DateHelperUtil.ngbDateToApiDateTime(
        data.plannedStartDate,
      ),
      ZgodaNaKorespondencjeElektroniczna:
        data.electronicCorrespondenceStatement,
    };
  }
}
