import { Injectable } from '@angular/core';
import {
  DepartmentCode,
  NavItemsSectionConfig,
  WebPortal,
} from '@gk/gk-modules';
import { map, Observable } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import {
  accessToInformationOnEnvironmentAndItsProtectionNavSections,
  addressNumberRequestPortalNavSections,
  adjacentPropertyNeedEnterDecisionNavSections,
  agriculturalLandUseRequestNavSections,
  appraiserNavSections,
  buildingOrPartUseChangeNavSections,
  buildingPermitModificationDecisionNavSections,
  buildingPermitNavSections,
  buildingPermitTransferPortalNavSections,
  communalPremisePortalNavSections,
  constructionPortalConstructionLogNavSections,
  constructionPortalConstructionNotificationNavSections,
  constructionPortalDemolitionNotificationNavSections,
  constructionPortalPremiseIndependenceNavSections,
  coordinationMeetingPortalNavSections,
  demolitionPermitNavSections,
  designerNavSections,
  ecoPortalNavSections,
  environmentalProtectionPortalFishingCardRequestNavSections,
  environmentalProtectionPortalSimplifiedForestManagementPlanRequestNavSections,
  environmentalProtectionPortalTreesAndShrubsFellingRequestNavSections,
  environmentalProtectionPortalWaterEquipmentRequestNavSections,
  geodeticAndCartographicServiceDataRequestNavSections,
  grantingRightsNavSections,
  landAndBuildingRegisterDataChangesNotificationRequestNavSections,
  laneOccupancyBuildingObjectsPortalNavSections,
  laneOccupancyBuildingWorksPortalNavSections,
  laneOccupancyInfrastructureDevicesPortalNavSections,
  networkServiceOrderNavSections,
  nonAgriculturalLandUseRequestNavSections,
  notObjectedNotificationRightsObligationsTransferNavSections,
  ownershipConversionPortalNavSections,
  planningDepartmentCertificateFromLocalSpatialDevelopmentPlanNavSections,
  planningDepartmentExtractFromLocalSpatialDevelopmentPlanNavSections,
  planningDepartmentExtractFromStudyNavSections,
  plotLandDevArchConstrProjSeparateDecisionNavSections,
  presentationCommentsLandBuildingRegistryProjectRequestNavSections,
  propertyLeasePortalNavSections,
  przpNavSections,
  roadExitPortalNavSections,
  singleFamilyBuilding70ConstructionConversionNavSections,
  singleFamilyBuildingConstructionConversionNavSections,
  soilClassificationOfLandRequestNavSections,
  streetNamingPortalNavSections,
  supplementaryParcelPurchasePortalNavSections,
  surveyorNavSections,
  temporaryStructureBuildingPermitNavSections,
} from '../navbar-provider/dynamic-navbar.configuration';

@Injectable({
  providedIn: 'root',
})
export class AccessibleNavigationService {
  constructor(private webPortalService: WebPortalService) {}

  public isCurrentPathDepartmentPath(): boolean {
    return window.location.pathname.includes(MainRoutes.Department);
  }

  public getDepartmentCodeFromCurrentUrl(): string {
    const url = window.location.pathname;
    const regex = /\/([^/]+)$/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }

  public getDepartmentRequestsByCode(
    departmentCode: DepartmentCode,
  ): Observable<WebPortal[]> {
    return this.webPortalService
      .getWebPortalData()
      .pipe(
        map((mainPortalData) =>
          mainPortalData.filter(
            (department) => department.departmentCode === departmentCode,
          ),
        ),
      );
  }

  public handlePortalTypeChange(
    portalRoute: MainRoutes,
  ): NavItemsSectionConfig[] {
    switch (portalRoute) {
      case MainRoutes.Designer: {
        return designerNavSections;
      }
      case MainRoutes.Przp: {
        return przpNavSections;
      }
      case MainRoutes.GrantingRights: {
        return grantingRightsNavSections;
      }
      case MainRoutes.NetworkServiceOrder: {
        return networkServiceOrderNavSections;
      }
      case MainRoutes.EcoPortal: {
        return ecoPortalNavSections;
      }
      case MainRoutes.RoadExitPortal: {
        return roadExitPortalNavSections;
      }
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse: {
        return agriculturalLandUseRequestNavSections;
      }
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse: {
        return nonAgriculturalLandUseRequestNavSections;
      }
      case MainRoutes.LaneOccupancyPortalInfrastructureDevices: {
        return laneOccupancyInfrastructureDevicesPortalNavSections;
      }
      case MainRoutes.LaneOccupancyPortalBuildingObjects: {
        return laneOccupancyBuildingObjectsPortalNavSections;
      }
      case MainRoutes.BuildingPermitTransfer: {
        return buildingPermitTransferPortalNavSections;
      }
      case MainRoutes.PropertyLease: {
        return propertyLeasePortalNavSections;
      }
      case MainRoutes.SupplementaryParcelPurchase: {
        return supplementaryParcelPurchasePortalNavSections;
      }
      case MainRoutes.LaneOccupancyPortalBuildingWorks: {
        return laneOccupancyBuildingWorksPortalNavSections;
      }
      case MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest: {
        return environmentalProtectionPortalSimplifiedForestManagementPlanRequestNavSections;
      }
      case MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest: {
        return environmentalProtectionPortalTreesAndShrubsFellingRequestNavSections;
      }
      case MainRoutes.EnvironmentalProtectionPortalFishingCardRequest: {
        return environmentalProtectionPortalFishingCardRequestNavSections;
      }
      case MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest: {
        return environmentalProtectionPortalWaterEquipmentRequestNavSections;
      }
      case MainRoutes.ConstructionPortalConstructionNotificationRequest: {
        return constructionPortalConstructionNotificationNavSections;
      }
      case MainRoutes.ConstructionPortalDemolitionNotificationRequest: {
        return constructionPortalDemolitionNotificationNavSections;
      }
      case MainRoutes.ConstructionPortalPremiseIndependenceRequest: {
        return constructionPortalPremiseIndependenceNavSections;
      }
      case MainRoutes.ConstructionPortalConstructionLogRequest: {
        return constructionPortalConstructionLogNavSections;
      }

      case MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan: {
        return planningDepartmentExtractFromLocalSpatialDevelopmentPlanNavSections;
      }
      case MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan: {
        return planningDepartmentCertificateFromLocalSpatialDevelopmentPlanNavSections;
      }
      case MainRoutes.PlanningDepartmentExtractFromStudy: {
        return planningDepartmentExtractFromStudyNavSections;
      }
      case MainRoutes.AddressNumberRequestPortal: {
        return addressNumberRequestPortalNavSections;
      }
      case MainRoutes.SurveyorPortal: {
        return surveyorNavSections;
      }
      case MainRoutes.AppraiserPortal: {
        return appraiserNavSections;
      }
      case MainRoutes.CoordinationMeetingPortal: {
        return coordinationMeetingPortalNavSections;
      }

      case MainRoutes.OwnershipConversion: {
        return ownershipConversionPortalNavSections;
      }

      case MainRoutes.CommunalPremisePurchase: {
        return communalPremisePortalNavSections;
      }

      case MainRoutes.StreetNaming: {
        return streetNamingPortalNavSections;
      }

      case MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest: {
        return landAndBuildingRegisterDataChangesNotificationRequestNavSections;
      }

      case MainRoutes.PresentationCommentsLandBuildingRegistryProject: {
        return presentationCommentsLandBuildingRegistryProjectRequestNavSections;
      }

      case MainRoutes.AccessToInformationOnEnvironmentAndItsProtection: {
        return accessToInformationOnEnvironmentAndItsProtectionNavSections;
      }

      case MainRoutes.BuildingPermit: {
        return buildingPermitNavSections;
      }

      case MainRoutes.DemolitionPermit: {
        return demolitionPermitNavSections;
      }

      case MainRoutes.BuildingOrPartUseChange: {
        return buildingOrPartUseChangeNavSections;
      }

      case MainRoutes.TemporaryStructureBuildingPermit: {
        return temporaryStructureBuildingPermitNavSections;
      }

      case MainRoutes.BuildingPermitModificationDecision: {
        return buildingPermitModificationDecisionNavSections;
      }

      case MainRoutes.NotObjectedNotificationRightsObligationsTransfer: {
        return notObjectedNotificationRightsObligationsTransferNavSections;
      }

      case MainRoutes.PlotLandDevArchConstrProjSeparateDecision: {
        return plotLandDevArchConstrProjSeparateDecisionNavSections;
      }

      case MainRoutes.AdjacentPropertyNeedEnterDecision: {
        return adjacentPropertyNeedEnterDecisionNavSections;
      }

      case MainRoutes.SingleFamilyBuilding70ConstructionConversion: {
        return singleFamilyBuilding70ConstructionConversionNavSections;
      }

      case MainRoutes.SingleFamilyBuildingConstructionConversion: {
        return singleFamilyBuildingConstructionConversionNavSections;
      }

      case MainRoutes.GeodeticAndCartographicServiceDataRequest: {
        return geodeticAndCartographicServiceDataRequestNavSections;
      }

      case MainRoutes.SoilClassificationOfLandRequest: {
        return soilClassificationOfLandRequestNavSections;
      }

      default: {
        return [];
      }
    }
  }
}
