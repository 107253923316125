import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { ValuerConfigs, ValuerConfigsDto } from './valuer-config.model';

@Injectable()
export class ValuerConfigService {
  valuerConfigUrl = '/api/rciwn/konfig';
  private valuerConfigs: Observable<ValuerConfigs>;

  constructor(private http: HttpClient) {}

  public getSettings(): Observable<ValuerConfigs> {
    if (!this.valuerConfigs) {
      this.valuerConfigs = this.http
        .get<ValuerConfigsDto>(this.valuerConfigUrl)
        .pipe(
          map((configsFromApi) => ValuerConfigs.fromApiToApp(configsFromApi)),
          shareReplay(1),
        );
    }

    return this.valuerConfigs;
  }
}
