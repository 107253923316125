import { FormControl, FormGroup } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum TSBPNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  RequestInformation = 'requestInformation',
  WorksStartDate = 'worksStartDate',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export enum TSBPRequestInformationControlName {
  WorksType = 'worksType',
  RequestDate = 'requestDate',
}

export interface TSBPNewRequestFormGroup {
  [TSBPNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [TSBPNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [TSBPNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [TSBPNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [TSBPNewRequestControlName.RequestInformation]: FormGroup<TSBPRequestInformationFormGroup>;
  [TSBPNewRequestControlName.WorksStartDate]: FormControl<NgbDate>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [TSBPNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [TSBPNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
}

export interface TSBPRequestInformationFormGroup {
  [TSBPRequestInformationControlName.WorksType]: FormControl<string>;
  [TSBPRequestInformationControlName.RequestDate]: FormControl<NgbDate>;
}
