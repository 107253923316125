<div
  class="position-relative mb-3 d-flex flex-column justify-content-center overflow-visible"
>
  <div class="overflow-y-visible">
    <div class="row main-content bg-success text-center box-shadow-for-wrapper">
      <div class="col action-window">
        <div class="position-relative">
          <div class="row mt-4">
            <div class="col-12">
              <h1>{{ 'SESSION_TOOLS.NEW_PASSWORD.HEADER' | translate }}</h1>
              <div class="alert alert-info mt-4" role="alert">
                {{ 'SESSION_TOOLS.NEW_PASSWORD.SUB_HEADER' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <form
              id="password-reset-form"
              class="form-group col-12"
              [formGroup]="formGroup"
              (ngSubmit)="newPasswordSubmitHandle()"
            >
              <div class="row password-group">
                <label
                  [attr.for]="controlNameEnum.CurrentPassword"
                  class="sr-only"
                  >{{
                    'SESSION_TOOLS.NEW_PASSWORD.CURRENT_PASSWORD' | translate
                  }}</label
                >
                <input
                  [formControlName]="controlNameEnum.CurrentPassword"
                  [attr.name]="controlNameEnum.CurrentPassword"
                  [attr.id]="controlNameEnum.CurrentPassword"
                  [attr.type]="
                    passwordControlVisibility[controlNameEnum.CurrentPassword]
                      ? 'text'
                      : 'password'
                  "
                  class="form__input form-control"
                  [attr.placeholder]="
                    'SESSION_TOOLS.NEW_PASSWORD.ENTER_CURRENT_PASSWORD'
                      | translate
                  "
                  [ngClass]="{
                    'password-mask-size':
                      !passwordControlVisibility[
                        controlNameEnum.CurrentPassword
                      ]
                  }"
                />
                <div
                  class="password-addon p-0"
                  [ngClass]="{
                    'text-dark':
                      passwordControlVisibility[controlNameEnum.CurrentPassword]
                  }"
                  (mousedown)="
                    togglePasswordVisibility(
                      true,
                      controlNameEnum.CurrentPassword
                    )
                  "
                  (mouseup)="
                    togglePasswordVisibility(
                      false,
                      controlNameEnum.CurrentPassword
                    )
                  "
                  (touchstart)="
                    togglePasswordVisibility(
                      true,
                      controlNameEnum.CurrentPassword
                    )
                  "
                  (touchend)="
                    togglePasswordVisibility(
                      false,
                      controlNameEnum.CurrentPassword
                    )
                  "
                >
                  <i
                    class="fa"
                    [ngClass]="
                      passwordControlVisibility[controlNameEnum.CurrentPassword]
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    "
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="row password-group">
                <label
                  [attr.for]="controlNameEnum.NewPassword"
                  class="sr-only"
                  >{{
                    'SESSION_TOOLS.NEW_PASSWORD.NEW_PASSWORD' | translate
                  }}</label
                >
                <input
                  [formControlName]="controlNameEnum.NewPassword"
                  [attr.name]="controlNameEnum.NewPassword"
                  [attr.id]="controlNameEnum.NewPassword"
                  [attr.type]="
                    passwordControlVisibility[controlNameEnum.NewPassword]
                      ? 'text'
                      : 'password'
                  "
                  class="form__input form-control"
                  [attr.placeholder]="
                    'SESSION_TOOLS.NEW_PASSWORD.ENTER_NEW_PASSWORD' | translate
                  "
                  [ngClass]="{
                    'password-mask-size':
                      !passwordControlVisibility[controlNameEnum.NewPassword]
                  }"
                />
                <div
                  class="password-addon p-0"
                  [ngClass]="{
                    'text-dark':
                      passwordControlVisibility[controlNameEnum.NewPassword]
                  }"
                  (mousedown)="
                    togglePasswordVisibility(true, controlNameEnum.NewPassword)
                  "
                  (mouseup)="
                    togglePasswordVisibility(false, controlNameEnum.NewPassword)
                  "
                  (touchstart)="
                    togglePasswordVisibility(true, controlNameEnum.NewPassword)
                  "
                  (touchend)="
                    togglePasswordVisibility(false, controlNameEnum.NewPassword)
                  "
                >
                  <i
                    class="fa"
                    [ngClass]="
                      passwordControlVisibility[controlNameEnum.NewPassword]
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    "
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="row password-group">
                <label
                  [attr.for]="controlNameEnum.PasswordConfirmation"
                  class="sr-only"
                  >{{
                    'SESSION_TOOLS.NEW_PASSWORD.PASSWORD_CONFIRMATION'
                      | translate
                  }}</label
                >
                <input
                  [formControlName]="controlNameEnum.PasswordConfirmation"
                  [attr.name]="controlNameEnum.PasswordConfirmation"
                  [attr.id]="controlNameEnum.PasswordConfirmation"
                  [attr.type]="
                    passwordControlVisibility[
                      controlNameEnum.PasswordConfirmation
                    ]
                      ? 'text'
                      : 'password'
                  "
                  class="form__input form-control"
                  [attr.placeholder]="
                    'SESSION_TOOLS.NEW_PASSWORD.PASSWORD_CONFIRMATION'
                      | translate
                  "
                  [ngClass]="{
                    'password-mask-size':
                      !passwordControlVisibility[
                        controlNameEnum.PasswordConfirmation
                      ]
                  }"
                />
                <div
                  class="password-addon p-0"
                  [ngClass]="{
                    'text-dark':
                      passwordControlVisibility[
                        controlNameEnum.PasswordConfirmation
                      ]
                  }"
                  (mousedown)="
                    togglePasswordVisibility(
                      true,
                      controlNameEnum.PasswordConfirmation
                    )
                  "
                  (mouseup)="
                    togglePasswordVisibility(
                      false,
                      controlNameEnum.PasswordConfirmation
                    )
                  "
                  (touchstart)="
                    togglePasswordVisibility(
                      true,
                      controlNameEnum.PasswordConfirmation
                    )
                  "
                  (touchend)="
                    togglePasswordVisibility(
                      false,
                      controlNameEnum.PasswordConfirmation
                    )
                  "
                >
                  <i
                    class="fa"
                    [ngClass]="
                      passwordControlVisibility[
                        controlNameEnum.PasswordConfirmation
                      ]
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    "
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="row">
                <div class="alert alert-warning mt-4" role="alert">
                  <b>{{
                    'SESSION_TOOLS.NEW_PASSWORD.ATTENTION' | translate
                  }}</b>
                  <ol class="text-left">
                    <li>
                      {{ 'SESSION_TOOLS.NEW_PASSWORD.COMMENT_1' | translate }}
                    </li>
                    <li>
                      {{ 'SESSION_TOOLS.NEW_PASSWORD.COMMENT_2' | translate }}
                    </li>
                    <li>
                      {{ 'SESSION_TOOLS.NEW_PASSWORD.COMMENT_3' | translate }}
                    </li>
                  </ol>
                  <div class="text-left">
                    {{ 'SESSION_TOOLS.NEW_PASSWORD.DESCRIPTION' | translate }}
                  </div>
                  <div class="text-right">
                    {{
                      'SESSION_TOOLS.NEW_PASSWORD.DESCRIPTION_NOTE' | translate
                    }}
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-sm-6 col-12">
                  @if (!actionRequestInProgress) {
                    <input
                      type="submit"
                      class="btn btn-normal password-reset-ready-info rememberPass"
                      [attr.value]="
                        'SESSION_TOOLS.NEW_PASSWORD.CHANGE_PASSWORD' | translate
                      "
                    />
                  } @else {
                    <button
                      type="button"
                      class="btn btn-normal password-reset-pending-info"
                      [attr.title]="
                        'SESSION_TOOLS.NEW_PASSWORD.PENDING_ACTION' | translate
                      "
                    >
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      @if (messageText) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3"
          [ngClass]="{
            'bg-warning': messageStatus === messageStatusEnum.Warning,
            'bg-normal':
              messageStatus === messageStatusEnum.Success ||
              messageStatus === messageStatusEnum.Pending,
            'bg-danger': messageStatus === messageStatusEnum.Error,
            'text-dark': messageStatus === messageStatusEnum.Warning,
            'text-white':
              messageStatus === messageStatusEnum.Error ||
              messageStatus === messageStatusEnum.Success ||
              messageStatus === messageStatusEnum.Pending
          }"
        >
          <p class="mb-0 fw-bold">
            <i
              class="fa"
              [ngClass]="{
                'fa-exclamation-triangle':
                  messageStatus === messageStatusEnum.Warning,
                'fa-check': messageStatus === messageStatusEnum.Success,
                'fa-lock': messageStatus === messageStatusEnum.Error,
                'fa-spinner fa-spin':
                  messageStatus === messageStatusEnum.Pending,
                'text-white':
                  messageStatus === messageStatusEnum.Error ||
                  messageStatus === messageStatusEnum.Success ||
                  messageStatus === messageStatusEnum.Pending
              }"
            >
            </i>
            {{ messageText }}
          </p>
        </div>
      } @else {
        @if (shouldShowRequiredFieldsInfo()) {
          <div
            class="col-12 text-center d-flex justify-content-center py-3 bg-warning text-dark"
          >
            <p class="mb-0 fw-bold">
              <i class="fa fa-exclamation-triangle"></i>
              {{ 'SESSION_TOOLS.NEW_PASSWORD.FIELDS_REQUIRED' | translate }}
            </p>
          </div>
        } @else {
          @if (shouldShowNewPasswordFormControlPatternError()) {
            <div
              class="col-12 text-center d-flex justify-content-center py-3 bg-warning text-dark"
            >
              <p class="mb-0 fw-bold">
                <i class="fa fa-exclamation-triangle"></i>
                {{ 'SESSION_TOOLS.NEW_PASSWORD.PATTERN_ERROR' | translate }}
              </p>
            </div>
          } @else {
            @if (shouldShowNotSamePasswordsFormGroupError()) {
              <div
                class="col-12 text-center d-flex justify-content-center py-3 bg-warning text-dark"
              >
                <p class="mb-0 fw-bold">
                  <i class="fa fa-exclamation-triangle"></i>
                  {{
                    'SESSION_TOOLS.NEW_PASSWORD.NOT_SAME_PASSWORDS_ERROR'
                      | translate
                  }}
                </p>
              </div>
            }
          }
        }
      }
    </div>
  </div>
</div>
