import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PprfBuildingFormModel } from './pprf-building-form.model';
import { PprfBuildingFormService } from './pprf-building-form.service';

@Component({
  selector: 'gk-pprf-building-form',
  templateUrl: './pprf-building-form.component.html',
})
export class PprfBuildingFormComponent {
  @Input() form: FormGroup;
  @Input() formModel: PprfBuildingFormModel;
  formlyFormConfig = this.pprfBuildingFormService.getFormConfig();

  constructor(private pprfBuildingFormService: PprfBuildingFormService) {}
}
