import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatestWith, filter, map, Observable } from 'rxjs';
import { SessionService } from '../../services/session/session.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class PortalAccessClientGuard {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  checkAccess(loggedUserName: string, isGuest: boolean): boolean {
    if (loggedUserName) {
      return true;
    }

    this.navigateToNoAccess();
    if (!isGuest) {
      this.showIfUserNotBindWithApplicant();
    }
    return false;
  }

  showIfUserNotBindWithApplicant(): void {
    this.translateService
      .get('USER_NOT_BIND_WITH_APPLICANT')
      .subscribe((text) => this.toastr.error(text));
  }

  navigateToNoAccess(): void {
    this.router.navigateByUrl(`/${MainRoutes.NoAccess}`);
  }

  canActivate(): Observable<boolean> {
    return this.sessionService.loggedUserData.pipe(
      combineLatestWith(
        this.sessionService.systemSession.pipe(
          filter((systemSession) => systemSession !== undefined),
        ),
      ),
      map(([loggedUser, systemSession]) =>
        this.checkAccess(loggedUser.client.name, systemSession.isGuest),
      ),
    );
  }
}
