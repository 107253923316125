import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of, switchMap, takeWhile } from 'rxjs';
import { Place } from '../../gk-dynamic-form/services/place/place.model';
import { GkPostalCode } from '../../gk-dynamic-form/services/postal-code/postal-code.model';
import { Street } from '../../gk-dynamic-form/services/street/street.model';
import { FormAlertService } from '../../services/form-alert/form-alert.service';
import { PzService } from '../../services/pz/pz.service';
import { nipValidator } from '../../validators/nip-validator/nip-validator';
import { PostOfficeModalComponent } from '../post-office-modal/post-office-modal.component';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LegalPersonControlName,
  MaxLengthAddressField,
  NaturalPersonControlName,
} from '../services/law-person-form/law-person-form.model';
import {
  LawPersonSearch,
  LegalPersonSearch,
  isLegalPerson,
} from '../services/law-person/law-person-search.model';
import {
  ApplicantType,
  LawPersonType,
} from '../services/law-person/law-person.model';
import { LawPersonService } from '../services/law-person/law-person.service';

@Component({
  selector: 'gk-combined-person-form',
  templateUrl: './combined-person-form.component.html',
})
export class CombinedPersonFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() idPrefix = '';
  @Input() lawPersonFormGroup: FormGroup;
  @Input() wasFormValidated: boolean;
  @Input() forceApplicantType: ApplicantType;
  @Input() searchLegalPersonCustomUrl: string;
  @Input() legalPersonSearchFieldsToHide: LegalPersonControlName[] = [
    LegalPersonControlName.TypeId,
    LegalPersonControlName.Name,
  ];
  @Output() selectedPersonChange = new EventEmitter<LegalPersonSearch>();
  @ViewChild(PostOfficeModalComponent)
  postOfficeModalComponent: PostOfficeModalComponent;
  lawPersonAddressControlName = LawPersonAddressControlName;
  maxLengthAddressField = MaxLengthAddressField;
  chosenLawPersons: LawPersonSearch[] = [];
  legalPersonControlName = LegalPersonControlName;

  applicantType = ApplicantType;

  constructor(
    private pzService: PzService,
    private formAlertService: FormAlertService,
    private lawPersonService: LawPersonService,
  ) {}

  ngOnInit(): void {
    this.setProperPersonTypeMode();
    this.copyApplicantDataFromPz();
    this.subscribeToTypeFormControl();
  }

  subscribeToTypeFormControl(): void {
    this.getTypeFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((lawPersonType) =>
        this.toggleFormDisabledByLawPersonType(lawPersonType),
      );
  }

  setProperPersonTypeMode(): void {
    if (
      this.forceApplicantType === ApplicantType.FromLoggedInUser ||
      this.forceApplicantType === ApplicantType.FromLoggedInUserOrFromRegonDb
    ) {
      this.getTypeFormControl().setValue(LawPersonType.Natural);
      this.toggleFormDisabledByLawPersonType(LawPersonType.Natural);
    } else if (this.forceApplicantType === ApplicantType.FromRegonDb) {
      this.getTypeFormControl().setValue(LawPersonType.Legal);
      this.toggleFormDisabledByLawPersonType(LawPersonType.Legal);
    }
  }

  toggleFormDisabledByLawPersonType(lawPersonType: LawPersonType): void {
    const applicantFromRegonAddressFormGroup =
      this.getApplicantFromRegonAddressFormGroup();
    const legalPersonFormGroup = this.getLegalPersonFormGroup();
    if (lawPersonType === LawPersonType.Natural) {
      applicantFromRegonAddressFormGroup.disable();
      legalPersonFormGroup.disable();
    } else {
      applicantFromRegonAddressFormGroup.enable();
      legalPersonFormGroup.enable();
    }
    this.setValidatorsOfChosenLegalPersonAdditionalFormFields();
  }

  isNaturalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Natural;
  }

  isLegalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Legal;
  }

  getTypeFormControl(): UntypedFormControl {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.Type,
    ) as UntypedFormControl;
  }

  copyApplicantDataFromPz(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => {
        this.getNaturalPersonFormGroup().patchValue({
          [NaturalPersonControlName.FirstName]: petentData.firstname,
          [NaturalPersonControlName.LastName]: petentData.lastname,
        });
        this.getAddressFormGroup().patchValue({
          [LawPersonAddressControlName.Place]: new Place(
            petentData.placeId,
            petentData.place,
            true,
          ),
          [LawPersonAddressControlName.Street]: new Street(
            petentData.streetId,
            petentData.fullStreet,
          ),
          [LawPersonAddressControlName.PostalCode]: new GkPostalCode(
            petentData.postalCodeId,
            petentData.postalCode,
          ),
          [LawPersonAddressControlName.BuildingNumber]:
            petentData.buildingNumber,
          [LawPersonAddressControlName.LocalNumber]: petentData.localNumber,
        });
      });
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.Address,
    ) as UntypedFormGroup;
  }

  getNaturalPersonFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.NaturalPersonData,
    ) as UntypedFormGroup;
  }
  shouldShowPostalCodePatternErrorAlert(): boolean {
    return !!this.formAlertService.shouldShowErrorAlert(
      this.getPostalCodeFormControl(),
      'postalCodePattern',
      this.wasFormValidated,
    );
  }

  getPostalCodeFormControl(): UntypedFormControl {
    return this.getApplicantFromRegonAddressFormGroup().get(
      this.lawPersonAddressControlName.PostalCode,
    ) as UntypedFormControl;
  }

  getLocalNumberFormControl(): UntypedFormControl {
    return this.getApplicantFromRegonAddressFormGroup().get(
      this.lawPersonAddressControlName.LocalNumber,
    ) as UntypedFormControl;
  }

  getBuildingNumberFormControl(): UntypedFormControl {
    return this.getApplicantFromRegonAddressFormGroup().get(
      this.lawPersonAddressControlName.BuildingNumber,
    ) as UntypedFormControl;
  }

  getApplicantFromRegonAddressFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.ApplicantFromRegonAddress,
    ) as UntypedFormGroup;
  }

  getNipFormControl(): UntypedFormControl {
    return this.getLegalPersonFormGroup().get(
      this.legalPersonControlName.Nip,
    ) as UntypedFormControl;
  }

  getLegalPersonFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.LegalPersonData,
    ) as UntypedFormGroup;
  }

  shouldShowNipAlert(formControl: UntypedFormControl): boolean {
    return !!(
      this.formAlertService.shouldShowErrorAlert(
        formControl,
        'nip',
        undefined,
      ) && formControl.value
    );
  }

  updateChosenLegalPersonIfConfigAllows(legalPerson: LawPersonSearch): void {
    if (
      this.forceApplicantType === ApplicantType.FromRegonDb ||
      this.forceApplicantType === ApplicantType.FromLoggedInUserOrFromRegonDb
    ) {
      this.getTypeFormControl().setValue(LawPersonType.Legal);
      this.chosenLawPersons = [legalPerson];
      this.emitPersonChange();
    }
  }

  emitPersonChange(): void {
    this.setValidatorsOfChosenLegalPersonAdditionalFormFields();
    if (
      this.chosenLawPersons.length === 0 ||
      !isLegalPerson(this.chosenLawPersons[0])
    ) {
      this.selectedPersonChange.emit(null);
    } else {
      this.selectedPersonChange.emit(this.chosenLawPersons[0]);
    }
  }

  setValidatorsOfChosenLegalPersonAdditionalFormFields(): void {
    const nipFormControl = this.getNipFormControl();
    const postalCodeFormControl = this.getPostalCodeFormControl();
    const buildingNumberFormControl = this.getBuildingNumberFormControl();
    const placeFormControl = this.getPlaceFormControl();
    const streetFormControl = this.getStreetFormControl();
    const isPersonAddedToDb = this.chosenLawPersons[0]?.id;
    if (
      this.chosenLawPersons.length === 0 ||
      !isLegalPerson(this.chosenLawPersons[0])
    ) {
      return;
    }
    if (
      isPersonAddedToDb &&
      this.chosenLawPersons[0]?.data?.legalPersonData?.nip
    ) {
      nipFormControl.clearValidators();
    } else {
      nipFormControl.setValidators([Validators.required, nipValidator()]);
    }
    if (this.isLegalPersonPlaceRequired()) {
      placeFormControl.setValidators([Validators.required]);
    } else {
      placeFormControl.clearValidators();
    }
    if (this.isLegalPersonStreetRequired()) {
      streetFormControl.setValidators([Validators.required]);
    } else {
      streetFormControl.clearValidators();
    }
    if (this.isLegalPersonBuildingNumberRequired()) {
      buildingNumberFormControl.setValidators([Validators.required]);
    } else {
      buildingNumberFormControl.clearValidators();
    }
    if (this.isLegalPersonPostalCodeRequired()) {
      postalCodeFormControl.setValidators([Validators.required]);
    } else {
      postalCodeFormControl.clearValidators();
    }
    nipFormControl.updateValueAndValidity();
    postalCodeFormControl.updateValueAndValidity();
    buildingNumberFormControl.updateValueAndValidity();
    placeFormControl.updateValueAndValidity();
    streetFormControl.updateValueAndValidity();
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      this.wasFormValidated,
    );
  }

  getSelectedLegalPerson(): Observable<LegalPersonSearch> {
    if (
      this.chosenLawPersons.length === 0 ||
      !isLegalPerson(this.chosenLawPersons[0]) ||
      this.isNaturalPersonChosen()
    ) {
      return of(null);
    }
    const chosenLawPerson = this.chosenLawPersons[0];
    if (chosenLawPerson.id && !this.isSomePersonFormFieldVisible()) {
      return of(chosenLawPerson);
    } else {
      return this.postOfficeModalComponent
        .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
        .pipe(
          switchMap(() => {
            const formRawValue = this.lawPersonFormGroup.getRawValue();

            return this.lawPersonService.addLegalPerson({
              ...chosenLawPerson.data,
              legalPersonData: {
                ...chosenLawPerson.data.legalPersonData,
                nip:
                  chosenLawPerson.data.legalPersonData?.nip ||
                  formRawValue.legalPersonData.nip,
              },
              address: {
                place:
                  formRawValue.applicantFromRegonAddress.place ||
                  chosenLawPerson.data.address.place,
                street:
                  formRawValue.applicantFromRegonAddress.street ||
                  chosenLawPerson.data.address.street,
                postalCode:
                  formRawValue.applicantFromRegonAddress.postalCode ||
                  chosenLawPerson.data.address.postalCode,
                postOffice:
                  formRawValue.applicantFromRegonAddress.postOffice ||
                  chosenLawPerson.data.address.postOffice,
                buildingNumber:
                  formRawValue.applicantFromRegonAddress.buildingNumber ||
                  chosenLawPerson.data.address.buildingNumber,
                localNumber:
                  formRawValue.applicantFromRegonAddress.localNumber ||
                  chosenLawPerson.data.address.localNumber,
              },
            });
          }),
        );
    }
  }

  isSomePersonFormFieldVisible(): boolean {
    return (
      this.isLegalPersonNipRequired() ||
      this.isLegalPersonPlaceRequired() ||
      this.isLegalPersonStreetRequired() ||
      this.isLegalPersonPostalCodeRequired() ||
      this.isLegalPersonBuildingNumberRequired()
    );
  }

  isLegalPersonNipRequired(): boolean {
    return (
      isLegalPerson(this.chosenLawPersons[0]) &&
      !this.chosenLawPersons[0]?.data?.legalPersonData?.nip
    );
  }

  isLegalPersonPlaceRequired(): boolean {
    const place = isLegalPerson(this.chosenLawPersons[0])
      ? this.chosenLawPersons[0]?.data?.address?.place
      : undefined;
    return !place || !place?.name;
  }

  isLegalPersonStreetRequired(): boolean {
    const street = isLegalPerson(this.chosenLawPersons[0])
      ? this.chosenLawPersons[0]?.data?.address?.street
      : undefined;
    return !street || !street?.name;
  }

  isLegalPersonPostalCodeRequired(): boolean {
    const postalCode = isLegalPerson(this.chosenLawPersons[0])
      ? this.chosenLawPersons[0]?.data?.address?.postalCode
      : undefined;
    return !postalCode || !postalCode?.name;
  }

  isLegalPersonBuildingNumberRequired(): boolean {
    return (
      isLegalPerson(this.chosenLawPersons[0]) &&
      !this.chosenLawPersons[0]?.data?.address?.buildingNumber
    );
  }

  isValid(): boolean {
    return (
      this.isNaturalPersonChosen() ||
      (this.chosenLawPersons.length === 1 &&
        this.getApplicantFromRegonAddressFormGroup().valid &&
        this.getNipFormControl().valid)
    );
  }

  isPersonChosen(): boolean {
    return this.isNaturalPersonChosen() || this.chosenLawPersons.length === 1;
  }

  getPlaceFormControl(): UntypedFormControl {
    return this.getApplicantFromRegonAddressFormGroup().get(
      this.lawPersonAddressControlName.Place,
    ) as UntypedFormControl;
  }

  getStreetFormControl(): UntypedFormControl {
    return this.getApplicantFromRegonAddressFormGroup().get(
      this.lawPersonAddressControlName.Street,
    ) as UntypedFormControl;
  }

  getFieldsToHideArray(): LawPersonAddressControlName[] {
    return [
      this.isLegalPersonPlaceRequired()
        ? null
        : LawPersonAddressControlName.Place,
      this.isLegalPersonStreetRequired()
        ? null
        : LawPersonAddressControlName.Street,
      this.isLegalPersonPostalCodeRequired()
        ? null
        : LawPersonAddressControlName.PostalCode,
      this.isLegalPersonBuildingNumberRequired()
        ? null
        : LawPersonAddressControlName.BuildingNumber,
      this.isLocalNumberShouldBeVisible()
        ? null
        : LawPersonAddressControlName.LocalNumber,
    ].filter(Boolean);
  }

  isLocalNumberShouldBeVisible(): boolean {
    if (!isLegalPerson(this.chosenLawPersons[0])) {
      return false;
    }
    const buildingNumber =
      this.chosenLawPersons[0]?.data?.address?.buildingNumber;
    const localNumber = this.chosenLawPersons[0]?.data?.address?.localNumber;

    return !buildingNumber && !localNumber;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
