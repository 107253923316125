import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PaymentProviderTypes } from './selection-payment-provider.model';

@Component({
  selector: 'gk-selection-payment-provider',
  templateUrl: './selection-payment-provider.component.html',
  styleUrls: ['./selection-payment-provider.component.scss'],
})
export class SelectionPaymentProviderComponent implements OnInit, OnDestroy {
  isPayment24Visible = false;
  isBlueMediaVisible = false;
  isPayUVisible = false;
  isPayByNetVisible = false;
  paymentProviders: string[];
  @Output() providerSelectedChange: EventEmitter<string> =
    new EventEmitter<string>();

  ngOnInit(): void {
    this.setPaymentProvidersVisibility();
  }

  setPaymentProvidersVisibility(): void {
    this.isPayment24Visible = this.paymentProviders.includes(
      PaymentProviderTypes.payment24,
    );
    this.isBlueMediaVisible = this.paymentProviders.includes(
      PaymentProviderTypes.blueMedia,
    );
    this.isPayUVisible = this.paymentProviders.includes(
      PaymentProviderTypes.payU,
    );
    this.isPayByNetVisible = this.paymentProviders.includes(
      PaymentProviderTypes.payByNet,
    );
  }

  emitProvider(event: Event): void {
    const target = event.target as HTMLElement;
    const imageId = target.getAttribute('data-image-id');
    if (imageId) {
      this.providerSelectedChange.emit(imageId);
    }
  }

  ngOnDestroy(): void {
    this.providerSelectedChange.emit(undefined);
  }
}
