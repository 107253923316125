import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private isScreenMobileWidth = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initBreakpointObserver();
  }

  public getIsScreenMobileWidth(): boolean {
    return this.isScreenMobileWidth;
  }

  private initBreakpointObserver(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => this.handleBreakpointState(result));
  }

  private handleBreakpointState(breakpointState: BreakpointState): void {
    this.isScreenMobileWidth =
      breakpointState.matches &&
      (breakpointState.breakpoints[Breakpoints.HandsetPortrait] ||
        breakpointState.breakpoints[Breakpoints.HandsetLandscape]);
  }
}
