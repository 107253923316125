import { FormControl, FormGroup } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum BPMDNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  DecisionInformation = 'decisionInformation',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export enum BPMDDecisionInformationControlName {
  PublicationDate = 'publicationDate',
  DecisionNumber = 'decisionNumber',
  CaseNumber = 'caseNumber',
  ConstructionProjectName = 'constructionProjectName',
}

export interface BPMDNewRequestFormGroup {
  [BPMDNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [BPMDNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [BPMDNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [BPMDNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [BPMDNewRequestControlName.DecisionInformation]: FormGroup<BPMDDecisionInformationFormGroup>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [BPMDNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [BPMDNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
}

export interface BPMDDecisionInformationFormGroup {
  [BPMDDecisionInformationControlName.PublicationDate]: FormControl<NgbDate>;
  [BPMDDecisionInformationControlName.DecisionNumber]: FormControl<string>;
  [BPMDDecisionInformationControlName.CaseNumber]: FormControl<string>;
  [BPMDDecisionInformationControlName.ConstructionProjectName]: FormControl<string>;
}
