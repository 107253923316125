import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonAddressControlName,
  getDefaultControlValue,
  streetFromDictionaryValidator,
} from '@gk/gk-modules';
import { DecisionFormType } from '../../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../../../../services/investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../../../../services/shipping-form/shipping-form.service';
import {
  ConstructionNotificationControlName,
  ConstructionNotificationType,
} from './construction-notification-form.model';

@Injectable()
export class ConstructionNotificationFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    public shippingFormService: ShippingFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        [ConstructionNotificationControlName.ConstructionIntention]: [
          ConstructionNotificationType.ConstructionIntention,
          [Validators.required],
        ],
        [ConstructionNotificationControlName.InvestorDetails]:
          this.investorDetailsFormService.getFormGroup(),
        [ConstructionNotificationControlName.ProxyDetailsCheckbox]: [
          getDefaultControlValue(),
        ],
        [ConstructionNotificationControlName.BuildingNameAndType]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.StreetAbsence]: false,
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
        [ConstructionNotificationControlName.DecisionForm]: [
          DecisionFormType.ElectronicForm,
          [Validators.required],
        ],
        [ConstructionNotificationControlName.Description]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [ConstructionNotificationControlName.ConstructionWorksCommencementDate]:
          [getDefaultControlValue(), [Validators.required]],
        [ConstructionNotificationControlName.ShippingForm]:
          this.shippingFormService.getFormGroup(),
      },
      { validator: streetFromDictionaryValidator() },
    );
  }
}
