@if (currentSkipLinksConfiguration) {
  <a id="top" tabindex="-1"></a>
  <ul id="skip-links" class="m-0 p-0 position-absolute top-0 list-unstyled">
    @for (
      skipLink of currentSkipLinksConfiguration.skipLinkItems | async;
      track skipLink.href
    ) {
      <li>
        <a
          href="{{ skipLink.href }}"
          class="d-inline p-2 position-absolute text-center text-decoration-none"
        >
          {{ 'GO_TO' | translate }}
          {{ skipLink.label | translate }}
        </a>
      </li>
    }
  </ul>
}
