import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AddTextAfterBasePipe } from '../utils/pipes/add-text-after-base/add-text-after-base.pipe';
import { FormBaseComponent } from './form-base/form-base.component';
import { ControlValidationComponent } from './form-control-validation/form-control.validation.component';
import { FormCheckboxGroupRepresentativeComponent } from './form-fields/form-checkbox-group-representative/form-checkbox-group-representative.component';
import { FormCheckboxComponent } from './form-fields/form-checkbox/form-checkbox.component';
import { FormDatepickerComponent } from './form-fields/form-datepicker/form-datepicker.component';
import { FormInputComponent } from './form-fields/form-input/form-input.component';
import { FormLandParcelNumberComponent } from './form-fields/form-land-parcel-number/form-land-parcel-number.component';
import { FormNestedGroupComponent } from './form-fields/form-nested-group/form-nested-group.component';
import { FormPlacesTypeaheadComponent } from './form-fields/form-places-typeahead/form-places-typeahead.component';
import { FormRadioOptionComponent } from './form-fields/form-radio-option/form-radio-option.component';
import { FormSectionTitleComponent } from './form-fields/form-section-title/form-section-title.component';
import { BasicSelectComponent } from './form-fields/form-select/basic-select/basic-select.component';
import { CommunitySelectComponent } from './form-fields/form-select/community/community-select.component';
import { ControlNetworkComponent } from './form-fields/form-select/control-network/control-network.component';
import { CoordinateSystemComponent } from './form-fields/form-select/coordinate-system/coordinate-system.component';
import { DistrictSelectComponent } from './form-fields/form-select/district/district-select.component';
import { LandAndMortgageRegisterDepartmentSelectComponent } from './form-fields/form-select/land-and-mortgage-register-department/land-and-mortgage-register-department-select.component';
import { LandAndMortgageRegisterDepartmentService } from './form-fields/form-select/land-and-mortgage-register-department/services/land-and-mortgage-register/land-and-mortgage-register-department.service';
import { OrthophotomapSelectComponent } from './form-fields/form-select/orthophotomap/orthophotomap-select.component';
import { SectionComponent } from './form-fields/form-select/section/section.component';
import { SheetSelectComponent } from './form-fields/form-select/sheet/sheet-select.component';
import { FormSeparatorComponent } from './form-fields/form-separator/form-separator.component';
import { FormStreetsTypeaheadComponent } from './form-fields/form-streets-typeahead/form-streets-typeahead.component';
import { FormRendererComponent } from './form-renderer/form-renderer.component';
import { DynamicFormComponent } from './gk-dynamic-form.component';
import { BuildingService } from './services/building/building.service';
import { ControlNetworkService } from './services/control-network/control-network.service';
import { CoordinateSystemService } from './services/coordinate-system/coordinate-system.service';
import { PlaceAndStreetFormService } from './services/place-and-street-form/place-and-street-form.service';
import { PlaceService } from './services/place/place.service';
import { GkPostalCodeService } from './services/postal-code/postal-code.service';
import { SectionService } from './services/section/section.service';
import { SettingsService } from './services/settings/settings.service';
import { StreetService } from './services/street/street.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
  ],
  declarations: [
    FormBaseComponent,
    DynamicFormComponent,
    FormInputComponent,
    FormLandParcelNumberComponent,
    FormCheckboxComponent,
    FormCheckboxGroupRepresentativeComponent,
    CommunitySelectComponent,
    DistrictSelectComponent,
    SheetSelectComponent,
    FormDatepickerComponent,
    FormSectionTitleComponent,
    FormNestedGroupComponent,
    FormPlacesTypeaheadComponent,
    FormStreetsTypeaheadComponent,
    FormRadioOptionComponent,
    OrthophotomapSelectComponent,
    ControlValidationComponent,
    FormRendererComponent,
    FormSeparatorComponent,
    BasicSelectComponent,
    SectionComponent,
    ControlNetworkComponent,
    CoordinateSystemComponent,
    LandAndMortgageRegisterDepartmentSelectComponent,
    AddTextAfterBasePipe,
  ],
  exports: [DynamicFormComponent],
  providers: [
    PlaceService,
    SettingsService,
    StreetService,
    SectionService,
    LandAndMortgageRegisterDepartmentService,
    ControlNetworkService,
    CoordinateSystemService,
    PlaceAndStreetFormService,
    BuildingService,
    GkPostalCodeService,
  ],
})
export class GkDynamicFormModule {}
