<ul
  ngbNav
  #propertyPriceRegisterFormNav="ngbNav"
  class="nav nav-tabs"
  [activeId]="pprfFormNavId.TransactionAndPricing"
>
  <li
    [ngbNavItem]="pprfFormNavId.TransactionAndPricing"
    [id]="pprfFormNavId.TransactionAndPricing"
  >
    <a ngbNavLink>
      {{
        (propertyPriceRegisterFormService.getConfig() | async)
          .transactionAndPricingTabKey | translate
      }}
    </a>

    <ng-template ngbNavContent>
      <gk-pprf-transaction-and-pricing-form
        [form]="form"
        [formModel]="formModel.transactionAndPricing"
      ></gk-pprf-transaction-and-pricing-form>
    </ng-template>
  </li>

  <li [ngbNavItem]="pprfFormNavId.Property" [id]="pprfFormNavId.Property">
    <a ngbNavLink>
      {{ 'PROPERTY_PRICE_REGISTER_FORM.PROPERTY.TITLE' | translate }}
    </a>

    <ng-template ngbNavContent>
      <gk-pprf-property-form
        [form]="form"
        [formModel]="formModel.property"
      ></gk-pprf-property-form>
    </ng-template>
  </li>

  <li [ngbNavItem]="pprfFormNavId.Parcel" [id]="pprfFormNavId.Parcel">
    <a ngbNavLink>
      {{ 'PROPERTY_PRICE_REGISTER_FORM.PARCEL.TITLE' | translate }}
    </a>

    <ng-template ngbNavContent>
      <gk-pprf-parcel-form
        [form]="form"
        [formModel]="formModel.parcel"
      ></gk-pprf-parcel-form>
    </ng-template>
  </li>

  <li [ngbNavItem]="pprfFormNavId.Building" [id]="pprfFormNavId.Building">
    <a ngbNavLink>
      {{ 'PROPERTY_PRICE_REGISTER_FORM.BUILDING.TITLE' | translate }}
    </a>

    <ng-template ngbNavContent>
      <gk-pprf-building-form
        [form]="form"
        [formModel]="formModel.building"
      ></gk-pprf-building-form>
    </ng-template>
  </li>

  <li [ngbNavItem]="pprfFormNavId.Premises" [id]="pprfFormNavId.Premises">
    <a ngbNavLink>
      {{ 'PROPERTY_PRICE_REGISTER_FORM.PREMISES.TITLE' | translate }}
    </a>

    <ng-template ngbNavContent>
      <gk-pprf-premises-form
        [form]="form"
        [formModel]="formModel.premises"
      ></gk-pprf-premises-form>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="propertyPriceRegisterFormNav" class="mt-2"></div>
