import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DocSignService,
  EgibObject,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { BuildingPermitTransferControlName } from '../../../services/building-permit-transfer/building-permit-transfer-form/building-permit-transfer-form.model';
import { BuildingPermitTransferFormService } from '../../../services/building-permit-transfer/building-permit-transfer-form/building-permit-transfer-form.service';
import {
  BuildingPermitTransfer,
  BuildingPermitTransferRequestDto,
} from '../../../services/building-permit-transfer/building-permit-transfer/building-permit-transfer.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
})
export class NewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  formNotValidTranslation: string;
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  requestSuccessfullySigned = true;
  override controlName = BuildingPermitTransferControlName;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  override decisionFormValue = DecisionFormType.ElectronicForm;
  override portalId = PortalId.BuildingPermitTransfer;

  constructor(
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    private buildingPermitTransferFormService: BuildingPermitTransferFormService,
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
    );
  }

  override ngOnInit(): void {
    this.fetchApplicantMapSettings();
    this.createForm();
    this.fetchFormNotValidTranslation();
    this.subscribeToDocSignTranslations();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.BuildingPermitTransfer,
      this.mapStateService.getViewState(
        MapId.BuildingPermitTransfer,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.BuildingPermitTransfer,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  createForm(): void {
    this.formGroup = this.buildingPermitTransferFormService.getFormGroup();
  }

  fetchFormNotValidTranslation(): void {
    this.translateService
      .get(
        'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            BuildingPermitTransferRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/przeniesieniePozwoleniaBudowy/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getFormValue(): BuildingPermitTransfer {
    return {
      ...this.formGroup.value,
      landParcels: this.getParcelsMapObjects(),
    };
  }

  getParcelsMapObjects(): ZamowienieDzialkaDto[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data as EgibObject));
  }

  override handleDocSignSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.BuildingPermitTransfer}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.attachmentsComponent.areDocumentsValid()
    );
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  checkInvestorDetailsFormGroupValidOrNotSubmitted(): boolean {
    return this.getInvestorDetailsFormGroup().valid || !this.submitted;
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      BuildingPermitTransferControlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  checkCurrentInvestorDetailsFormGroupValidOrNotSubmitted(): boolean {
    return this.getCurrentInvestorDetailsFormGroup().valid || !this.submitted;
  }

  getCurrentInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      BuildingPermitTransferControlName.CurrentInvestorDetails,
    ) as UntypedFormGroup;
  }

  checkEachBuildingPermitTransferFormControlValidOrNotSubmitted(): boolean {
    const permitTransferDateFormControl =
      this.getBuildingPermitTransferDateFormControl();
    const permitTransferNumberFormControl =
      this.getBuildingPermitTransferNumberFormControl();

    return (
      !this.submitted ||
      (permitTransferDateFormControl &&
        permitTransferDateFormControl.valid &&
        permitTransferNumberFormControl &&
        permitTransferNumberFormControl.valid)
    );
  }

  getBuildingPermitTransferDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      BuildingPermitTransferControlName.PermitTransferDate,
    ) as UntypedFormControl;
  }

  getBuildingPermitTransferNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      BuildingPermitTransferControlName.PermitTransferNumber,
    ) as UntypedFormControl;
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string,
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(formControl && (formControl.dirty || formControl.touched));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  checkIdentificationConstructionIntentValidOrNotSubmitted(): boolean {
    const streetFormControl = this.getStreetFormControl();
    return (
      !this.submitted ||
      (this.areParcelsChosen() &&
        this.getBuildingNameAndTypeFormControl().valid &&
        this.getPlaceFormControl().valid &&
        (streetFormControl.valid || streetFormControl.disabled) &&
        this.getBuildingNumberFormControl().valid &&
        this.getPostalCodeFormControl().valid)
    );
  }

  getBuildingNameAndTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      BuildingPermitTransferControlName.BuildingNameAndType,
    ) as UntypedFormControl;
  }

  getBuildingNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      BuildingPermitTransferControlName.BuildingNumber,
    ) as UntypedFormControl;
  }

  getPostalCodeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      BuildingPermitTransferControlName.PostalCode,
    ) as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
