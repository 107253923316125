import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import {
  District,
  Sheet,
} from '../../../gk-dynamic-form/services/cached/cached.model';
import { CachedService } from '../../../gk-dynamic-form/services/cached/cached.service';
import { PlaceService } from '../../../gk-dynamic-form/services/place/place.service';
import { StreetService } from '../../../gk-dynamic-form/services/street/street.service';
import { landParcelNumberPattern } from '../../../utils/utils';
import {
  ColumnConfig,
  DateStructForBetweenOperator,
  DictionaryControlConfig,
  Filter,
  FilterOperator,
  FilterOperatorType,
  FilterRightOperand,
  SortType,
  ValueType,
} from '../../gk-dynamic-list.model';
import {
  Dictionary,
  DictionaryField,
  FieldId,
  isDictionaryField,
} from '../../services/dictionary/dictionary.model';
import { DictionaryService } from '../../services/dictionary/dictionary.service';
import { DateHelperUtil } from '../../utils/date-helper.util';

@Component({
  selector: 'gk-filter-creator',
  templateUrl: './filter-creator.component.html',
  styleUrls: ['./filter-creator.component.scss'],
})
export class FilterCreatorComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input()
  columnConfigs: ColumnConfig[] = [];
  @Output()
  changeFilters = new EventEmitter<Filter[]>();
  appliedfilters: Filter[] = [];
  currentFilter = {} as Filter;
  currentDictionary: Dictionary = { fields: [] } as Dictionary;
  ValueType = ValueType;
  FilterOperatorType = FilterOperatorType;
  columnTranslatedLabels: { [key: string]: string } = {};
  dictionaryFilterOperators = [
    new FilterOperator(FilterOperatorType.Equals, 'równe'),
    new FilterOperator(FilterOperatorType.NotEquals, 'różne'),
  ];
  districtColumnConfig = new ColumnConfig(
    false,
    SortType.None,
    true,
    this.dictionaryFilterOperators,
    new DictionaryControlConfig(
      'Obręb',
      'ObrebId',
      false,
      undefined,
      undefined,
      () => this.getDistrictDictionary(),
    ),
    false,
    undefined,
    false,
    true,
  );
  sheetColumnConfig = new ColumnConfig(
    false,
    SortType.None,
    true,
    this.dictionaryFilterOperators,
    new DictionaryControlConfig(
      'Arkusz',
      'ArkuszId',
      false,
      undefined,
      undefined,
      () => this.getSheetDictionary(),
    ),
    false,
    undefined,
    false,
    false,
    true,
  );
  landParcelNumberPattern = landParcelNumberPattern;

  constructor(
    private dictionaryService: DictionaryService,
    private translateService: TranslateService,
    private cachedService: CachedService,
    private placeService: PlaceService,
    private streetService: StreetService,
  ) {}

  ngOnInit(): void {
    this.fetchLabelTranslations();
  }

  fetchLabelTranslations(): void {
    [
      ...this.columnConfigs,
      this.districtColumnConfig,
      this.sheetColumnConfig,
    ].forEach((config) => {
      this.translateService
        .get(config.controlConfig.label)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(
          (translatedLabel) =>
            (this.columnTranslatedLabels[config.controlConfig.label] =
              translatedLabel ||
              this.columnTranslatedLabels[config.controlConfig.label]),
        );
    });
  }

  getColumnConfigsWithAvailableFilters(): ColumnConfig[] {
    return this.columnConfigs.filter(
      (columnConfig) =>
        columnConfig.filterable && columnConfig.availableOperators.length,
    );
  }

  addFilter(): void {
    this.appliedfilters = [
      ...this.appliedfilters,
      isDictionaryField(this.currentFilter.rightOperand)
        ? ({
            ...this.currentFilter,
            columnConfig: {
              ...this.currentFilter.columnConfig,
              controlConfig: {
                ...this.currentFilter.columnConfig.controlConfig,
                pathToValue: `${this.currentFilter.columnConfig.controlConfig.pathToValue}Id`,
              },
            },
          } as Filter)
        : this.currentFilter,
    ];
    this.cleanCurrentFilter();
    this.changeFilters.emit(this.handleBetweenFilters(this.appliedfilters));
  }

  handleBetweenFilters(filters: Filter[]): Filter[] {
    return filters.flatMap((filter) => {
      if (filter.operator?.filterOperatorType === FilterOperatorType.Between) {
        const betweenOperand =
          filter.rightOperand as DateStructForBetweenOperator;
        return [
          {
            columnConfig: filter.columnConfig,
            operator: {
              filterOperatorType: FilterOperatorType.GreaterThanOrEquals,
              name: 'większe lub równe',
            },
            rightOperand: betweenOperand.from,
          },
          {
            columnConfig: filter.columnConfig,
            operator: {
              filterOperatorType: FilterOperatorType.LessThanOrEquals,
              name: 'mniejsze lub równe',
            },
            rightOperand: betweenOperand.to,
          },
        ];
      }

      return [filter];
    });
  }

  removeFilter(filterToRemove: Filter): void {
    this.appliedfilters = this.appliedfilters.filter(
      (filter) => !_.isEqual(filterToRemove, filter),
    );
    this.changeFilters.emit(this.appliedfilters);
  }

  cleanCurrentFilter(): void {
    this.currentFilter = {} as Filter;
  }

  initFilter(): void {
    this.currentFilter = {
      ...this.currentFilter,
      operator: _.get(this.currentFilter, 'columnConfig.availableOperators[0]'),
      rightOperand: undefined,
    } as Filter;
  }

  initDict(): void {
    if (
      this.currentFilter.columnConfig.controlConfig.valueType === ValueType.Dict
    ) {
      if (
        (
          this.currentFilter.columnConfig
            .controlConfig as DictionaryControlConfig
        ).getStaticDictionaryIfDefined
      ) {
        (
          this.currentFilter.columnConfig
            .controlConfig as DictionaryControlConfig
        )
          .getStaticDictionaryIfDefined()
          .pipe(takeWhile(() => this.isAlive))
          .subscribe((dictionaryFields) => {
            this.currentDictionary = new Dictionary(
              undefined,
              undefined,
              dictionaryFields,
            );
          });
      } else {
        this.currentDictionary = this.dictionaryService.getDictionary(
          (
            this.currentFilter.columnConfig
              .controlConfig as DictionaryControlConfig
          ).dictionaryType,
        );
      }
    }
  }

  initFilterCreator(): void {
    this.initFilter();
    this.initDict();
  }

  getDistrictDictionary(): Observable<District[]> {
    return this.cachedService.communitiesWithDistricts.pipe(
      takeWhile(() => this.isAlive),
      map((communitiesDictionary) => {
        const communityFilter = this.appliedfilters.find(
          (appliedfilter) => appliedfilter.columnConfig.isCommunityControl,
        );
        return communityFilter && communityFilter.rightOperand
          ? communitiesDictionary.find(
              (community) => community.id === communityFilter.rightOperand,
            ).districts
          : this.cachedService.allDistricts.getValue();
      }),
    );
  }

  getSheetDictionary(): Observable<Sheet[]> {
    return this.cachedService.communitiesWithDistricts.pipe(
      takeWhile(() => this.isAlive),
      map((communitiesDictionary) => {
        const allDistricts = this.cachedService.allDistricts.getValue();
        const communityFilter = this.appliedfilters.find(
          (appliedfilter) => appliedfilter.columnConfig.isCommunityControl,
        );
        const districtFilter = this.appliedfilters.find(
          (appliedfilter) => appliedfilter.columnConfig.isDistrictControl,
        );
        return districtFilter && districtFilter.rightOperand
          ? allDistricts.find(
              (district) => district.id === districtFilter.rightOperand,
            ).sheets
          : communityFilter && communityFilter.rightOperand
            ? _.flatMap(
                communitiesDictionary.find(
                  (community) => community.id === communityFilter.rightOperand,
                ).districts,
                'sheets',
              )
            : this.cachedService.allSheets.getValue();
      }),
    );
  }

  initRightOperand(): void {
    if (
      this.currentFilter.columnConfig.controlConfig.valueType ===
        ValueType.DateTime &&
      this.currentFilter.operator.filterOperatorType ===
        FilterOperatorType.Between
    ) {
      this.currentFilter = {
        ...this.currentFilter,
        rightOperand: { from: undefined, to: undefined },
      } as Filter;
    } else {
      this.currentFilter = {
        ...this.currentFilter,
        rightOperand: undefined,
      } as Filter;
    }
  }

  getFilterRightOperandForLabel(filter: Filter): string | void {
    switch (filter.columnConfig.controlConfig.valueType) {
      case ValueType.DateTime:
        if (filter.operator.filterOperatorType !== FilterOperatorType.Between) {
          return DateHelperUtil.ngbDateToUiDate(
            filter.rightOperand as NgbDateStruct,
          );
        } else {
          const datetimeForBetweenOperatorString = `${DateHelperUtil.ngbDateToUiDate(
            _.get(filter.rightOperand, 'from') as NgbDateStruct,
          )} a ${DateHelperUtil.ngbDateToUiDate(
            _.get(filter.rightOperand, 'to') as NgbDateStruct,
          )}`;
          return datetimeForBetweenOperatorString;
        }
      case ValueType.Bool:
        return `${filter.rightOperand === 'true' ? "'Tak'" : "'Nie'"}`;
      case ValueType.Dict:
        if (filter.columnConfig.isDistrictControl) {
          const districts = this.cachedService.allDistricts.getValue();

          return `'${
            districts.find((district) => district.id === filter.rightOperand)
              .name
          }'`;
        }
        if (filter.columnConfig.isSheetControl) {
          const sheets = this.cachedService.allSheets.getValue();

          return `'${
            sheets.find((sheet) => sheet.id === filter.rightOperand).name
          }'`;
        }
        return `'${this.dictionaryService.getFieldNameById(
          (filter.columnConfig.controlConfig as DictionaryControlConfig)
            .dictionaryType,
          filter.rightOperand as FieldId,
        )}'`;
      case ValueType.Number:
      case ValueType.Text:
      case ValueType.LandParcelNumber:
        return `'${filter.rightOperand}'`;
      case ValueType.PlaceTypeahead:
      case ValueType.StreetTypeahead:
        return isDictionaryField(filter.rightOperand)
          ? `'${filter.rightOperand.name}'`
          : `'${filter.rightOperand}'`;
    }
  }

  getFilterBadgeLabel(filter: Filter): string {
    return `${
      this.columnTranslatedLabels[filter.columnConfig.controlConfig.label]
    } ${filter.operator.name} ${this.getFilterRightOperandForLabel(filter)}`;
  }

  isValidOperator(filterOperator: FilterOperator): boolean {
    return !!(
      filterOperator &&
      filterOperator.filterOperatorType &&
      filterOperator.name
    );
  }

  isProperNgbDateStruct = (maybeNgbDate: any): boolean =>
    !_.isEmpty(maybeNgbDate) &&
    !!maybeNgbDate.day &&
    !!maybeNgbDate.month &&
    !!maybeNgbDate.year;

  isValidDateStructForBetweenOperator(currentFilter: Filter): boolean {
    return (
      this.isProperNgbDateStruct(_.get(currentFilter, 'rightOperand.from')) &&
      this.isProperNgbDateStruct(_.get(currentFilter, 'rightOperand.to'))
    );
  }

  isValidRightOperand(filter: Filter): boolean | void {
    switch (filter.columnConfig.controlConfig.valueType) {
      case ValueType.DateTime:
        if (filter.operator.filterOperatorType === FilterOperatorType.Between) {
          return this.isValidDateStructForBetweenOperator(filter);
        } else {
          return this.isProperNgbDateStruct(filter.rightOperand);
        }
      case ValueType.LandParcelNumber:
        return this.landParcelNumberPattern.test(filter.rightOperand as string);
      case ValueType.Bool:
      case ValueType.Number:
      case ValueType.Dict:
      case ValueType.Text:
      case ValueType.PlaceTypeahead:
      case ValueType.StreetTypeahead:
        return (
          filter.rightOperand !== null && filter.rightOperand !== undefined
        );
    }
  }

  shouldDisableAddFilter(): boolean {
    return (
      _.isEmpty(this.currentFilter) ||
      !this.currentFilter.columnConfig ||
      !this.isValidOperator(this.currentFilter.operator) ||
      !this.isValidRightOperand(this.currentFilter)
    );
  }

  searchPlace = ($term: Observable<string>) =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.placeService.getPlacesByTerm(term)),
    );

  formatter = (dictField: DictionaryField) => dictField.name;

  searchStreet = ($term: Observable<string>) =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.streetService.getStreets(term)),
      map((streetsFromApi) =>
        streetsFromApi.map(
          (streetFromApi) =>
            new DictionaryField(streetFromApi.id, streetFromApi.name),
        ),
      ),
    );

  getFromValue = (): NgbDateStruct =>
    this.isDateStructForBetweenOperator(this.currentFilter.rightOperand)
      ? this.currentFilter.rightOperand.from
      : undefined;

  getToValue = (): NgbDateStruct =>
    this.isDateStructForBetweenOperator(this.currentFilter.rightOperand)
      ? this.currentFilter.rightOperand.to
      : undefined;

  setFromValue(value: NgbDateStruct): void {
    if (this.isDateStructForBetweenOperator(this.currentFilter.rightOperand)) {
      this.currentFilter.rightOperand.from = value;
    } else {
      this.currentFilter.rightOperand = value;
    }
  }

  setToValue(value: NgbDateStruct): void {
    if (this.isDateStructForBetweenOperator(this.currentFilter.rightOperand)) {
      this.currentFilter.rightOperand.to = value;
    } else {
      this.currentFilter.rightOperand = value;
    }
  }

  isDateStructForBetweenOperator = (
    item: FilterRightOperand,
  ): item is DateStructForBetweenOperator =>
    !_.isString(item) && ('from' in item || 'to' in item);

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
