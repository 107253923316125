import { Injectable } from '@angular/core';
import { SessionType } from '@gk/gk-modules';
import { map, Observable } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import {
  defaultPortalConfig,
  PortalConfig,
} from '../../services/portal-config/portal-config.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Injectable({
  providedIn: 'root',
})
export class AppraiserPortalProjectConfigService implements PortalConfigGetter {
  constructor(private webPortalService: WebPortalService) {}

  getPortalConfig(): Observable<PortalConfig> {
    return this.webPortalService
      .getWebPortalDataByCurrentRoute(MainRoutes.AppraiserPortal)
      .pipe(
        map((webPortal) => ({
          ...defaultPortalConfig,
          isPerunAuthenticationAvailable: webPortal.ewidLogging,
          isPzAuthenticationAvailable: webPortal.pzLogging,
          sessionTypes: [SessionType.PerunAuth],
          accessKeys: ['valuerPortal'],
          portalId: webPortal.portalId,
          redirectCookieEnabled: true,
        })),
      );
  }
}
