import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DictionaryField,
  DocSignService,
  EgibObject,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  ToolType,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, from, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { ExclusionPurposeService } from '../../../services/exclusion-purpose/exclusion-purpose.service';
import { FormsStateService } from '../../../services/forms-state/forms-state.service';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import {
  ConstructionNotificationControlName,
  ConstructionNotificationType,
} from './services/construction-notification-form/construction-notification-form.model';
import { ConstructionNotificationFormService } from './services/construction-notification-form/construction-notification-form.service';
import {
  ConstructionNotification,
  ConstructionNotificationRequestDto,
} from './services/construction-notification/construction-notification.model';

@Component({
  selector: 'app-construction-notification-request',
  templateUrl: './construction-notification-request.component.html',
})
export class ConstructionNotificationRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild('canDeactivateModal')
  canDeactivateModal: NgbModalRef;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  override controlName = ConstructionNotificationControlName;
  constructionNotificationType = ConstructionNotificationType;
  exclusionPurposes: DictionaryField[];
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
    [...this.formsStateService.constructionNotificationRequestChosenParcels],
  );
  formValue: ConstructionNotificationRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  requestSuccessfullySigned = true;
  override portalId =
    PortalId.ConstructionPortalConstructionNotificationRequest;

  constructor(
    public simplifiedForestManagementFormService: ConstructionNotificationFormService,
    public override mapStateService: MapStateService,
    private exclusionPurposesService: ExclusionPurposeService,
    public override mapSettingsService: MapSettingsService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public formsStateService: FormsStateService,
    private modalService: NgbModal,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToExclusionPurposesList();
    this.fetchApplicantMapSettings();
    this.subscribeToDecisionFormRadioFormControl();
    super.subscribeToDocSignTranslations();
  }

  createForm(): void {
    this.formGroup = this.simplifiedForestManagementFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  subscribeToExclusionPurposesList(): void {
    this.exclusionPurposesService.exclusionPurposes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((exclusionPurposes) => {
        this.exclusionPurposes = exclusionPurposes;
      });
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewConstructionPortalRequest,
      this.mapStateService.getViewState(
        MapId.NewConstructionPortalRequest,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewConstructionPortalRequest,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            ConstructionNotificationRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/zgloszenieZamiaruBudowy/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.ConstructionPortalConstructionNotificationRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.attachmentsComponent.areDocumentsValid()
    );
  }

  getFormValue(): ConstructionNotification {
    return {
      ...this.formGroup.value,
      landParcels: this.getParcelsMapObjects(),
    };
  }

  getParcelsMapObjects(): ZamowienieDzialkaDto[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        ),
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data));
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      ConstructionNotificationControlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  checkInvestorDetailsFormGroupValidAndFormValidated(): boolean {
    return !this.getInvestorDetailsFormGroup().valid && this.submitted;
  }

  checkDescriptionValidAndFormValidated(): boolean {
    return !this.getDescriptionFormControl().valid && this.submitted;
  }

  checkConstructionWorksCommencementDateFormControlValidAndFormValidated(): boolean {
    return (
      !this.formGroup.get(this.controlName.ConstructionWorksCommencementDate)
        .valid && this.submitted
    );
  }

  checkIdentificationConstructionIntentValidAndFormValidated(): boolean {
    const streetFormControl = this.getStreetFormControl();
    return (
      (!this.areParcelsChosen() && this.submitted) ||
      (!this.getBuildingNameAndTypeFormControl().valid && this.submitted) ||
      (!this.getPlaceFormControl().valid && this.submitted) ||
      (!(streetFormControl.valid || streetFormControl.disabled) &&
        this.submitted) ||
      (!this.getBuildingNumberFormControl().valid && this.submitted) ||
      (!this.getPostalCodeFormControl().valid && this.submitted)
    );
  }

  getStreetAbsenceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.StreetAbsence,
    ) as UntypedFormControl;
  }

  getBuildingNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.BuildingNumber,
    ) as UntypedFormControl;
  }

  getPostalCodeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.PostalCode,
    ) as UntypedFormControl;
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string,
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(formControl && (formControl.dirty || formControl.touched));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  getBuildingNameAndTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.BuildingNameAndType,
    ) as UntypedFormControl;
  }

  getDescriptionFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.Description,
    ) as UntypedFormControl;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.requestSuccessfullySigned) {
      return true;
    }
    return this.isRequestDirty()
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty ||
      this.attachmentsComponent.areChosenDocuments() ||
      this.areParcelsChosen()
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
