import {
  BuildingDto,
  EgibObject,
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import { ApiNewDokumentPowiazanyDalDto } from '../../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';

export interface DPNewRequestDto {
  DaneInwestora:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  Dzialki: ZamowienieDzialkaDto[];
  Budynki: BuildingDto[];
  NazwaRozbiorki: string;
  ZgodaNaKorespondencjeElektroniczna: boolean;
  DanePelnomocnika: InvestorDetailsRequestDto;
}

export class DPNewRequest {
  constructor(
    public lawPerson: LawPerson,
    public contactData: LawPerson,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public demolitionInformation: string,
    public electronicCorrespondenceStatement: boolean,
    public proxyDetails: InvestorDetails,
    public proxyDetailsCheckbox: boolean,
  ) {}

  static fromAppToApi(
    data: DPNewRequest,
    parcels: EgibObject[],
    buildings: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): DPNewRequestDto {
    return {
      DaneInwestora: {
        ...(data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson)),
        Telefon: data.contactData.naturalPersonData.phoneNumber,
        Email: data.contactData.naturalPersonData.email,
      },
      DanePelnomocnika: data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel),
      ),
      Budynki: buildings.map((building) => BuildingDto.fromAppToApi(building)),
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      NazwaRozbiorki: data.demolitionInformation,
      ZgodaNaKorespondencjeElektroniczna:
        data.electronicCorrespondenceStatement,
    };
  }
}
