@if (!!footerConfig) {
  <div class="d-flex">
    @if (footerConfig.showCounter) {
      <gk-kendo-grid-counter
        class="flex-grow-1"
        [counter]="counter"
      ></gk-kendo-grid-counter>
    }
    @if (footerConfig.legend) {
      <gk-kendo-grid-legend
        class='flex-grow-1 align-self-center'
        [legendDataConfig]="footerConfig.legend"
      ></gk-kendo-grid-legend>
    }
  </div>
}
