import { FormControl, FormGroup } from '@angular/forms';
import {
  LawPerson,
  LawPersonAddressControlName,
  LawPersonControlName,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { NgbDateRange } from '../../../../services/new-request/new-request.model';
import { SharingPurpose } from '../../../../services/sharing-purpose/sharing-purpose.model';

export enum FSPNewRequestControlName {
  LawPerson = 'lawPerson',
  Period = 'period',
  PublicRegister = 'publicRegister',
  PersonalData = 'personalData',
  Purpose = 'purpose',
  Par1 = 'par1',
  Par2 = 'par2',
  Remarks = 'remarks',
  PublicTaskCaseReference = 'publicTaskCaseReference',
}

export interface FSPNewRequestFormGroup {
  [FSPNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [FSPNewRequestControlName.Period]: FormControl<NgbDateRange>;
  [FSPNewRequestControlName.PublicRegister]: FormControl<string>;
  [FSPNewRequestControlName.Purpose]: FormControl<SharingPurpose>;
  [FSPNewRequestControlName.PersonalData]: FormControl<boolean>;
  [FSPNewRequestControlName.Par1]: FormControl<string>;
  [FSPNewRequestControlName.Par2]: FormControl<string>;
  [FSPNewRequestControlName.Remarks]: FormControl<string>;
  [FSPNewRequestControlName.PublicTaskCaseReference]: FormControl<string>;
  [LawPersonControlName.ApplicantFromRegonAddress]: FormGroup<{
    [LawPersonAddressControlName.PostalCode]: FormControl<string>;
    [LawPersonAddressControlName.BuildingNumber]: FormControl<string>;
    [LawPersonAddressControlName.LocalNumber]: FormControl<string>;
  }>;
}

export interface FSPNewRequestFormValue {
  [FSPNewRequestControlName.LawPerson]: LawPerson;
  [FSPNewRequestControlName.Period]: NgbDateRange;
  [FSPNewRequestControlName.PublicRegister]: string;
  [FSPNewRequestControlName.Purpose]: SharingPurpose;
  [FSPNewRequestControlName.PersonalData]: boolean;
  [FSPNewRequestControlName.Par1]: string;
  [FSPNewRequestControlName.Par2]: string;
  [FSPNewRequestControlName.Remarks]: string;
  [FSPNewRequestControlName.PublicTaskCaseReference]: string;
  [LawPersonControlName.ApplicantFromRegonAddress]: {
    [LawPersonAddressControlName.PostalCode]: string;
    [LawPersonAddressControlName.BuildingNumber]: string;
    [LawPersonAddressControlName.LocalNumber]: string;
  };
}
export interface LegalInterestDto {
  Id: number;
  Nazwa: string;
  Par1: string;
  Par2: string;
  AutoEnabled: boolean;
}

export interface LegalInterestAutocompleteItem {
  value: string;
  ommitAuthorisedPerson: boolean;
  id: number;
}

export class LegalInterest {
  constructor(
    public id: number,
    public name: string,
    public param1: string,
    public param2: string,
    public autoEnabled: boolean,
  ) {}

  static readonly id = 117;

  static fromApiToApp(apiLegalInterest: LegalInterestDto): LegalInterest {
    return new this(
      apiLegalInterest.Id,
      apiLegalInterest.Nazwa,
      apiLegalInterest.Par1,
      apiLegalInterest.Par2,
      apiLegalInterest.AutoEnabled,
    );
  }

  static checkTypeOfItem = (item: any): item is LegalInterest =>
    !_.isString(item) && 'name' in item;
}
