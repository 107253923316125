import { Observable, of as observableOf } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import { MainRouteParams, MainRoutes } from '../guard.models';

@Injectable()
export class NoSessionGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.sessionService.getSystemSession().pipe(
      map((sessionState) => {
        if (!sessionState || !sessionState.isAuthenticated()) {
          return true;
        }
        const expectedRoute =
          route.params?.[MainRouteParams.ParentRoute] ||
          MainRoutes.OfficeDepartmentsPortal;

        this.router.navigate([expectedRoute]);

        return false;
      }),
      catchError(() => observableOf(false)),
    );
  }
}
