import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DocSignService,
  FormAlertService,
  MapSettingsService,
  MapStateService,
  PortalId,
  PzService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { FishingCardControlName } from '../../../services/environmental-protection/fishing-card-form/fishing-card-form.model';
import { FishingCardFormService } from '../../../services/environmental-protection/fishing-card-form/fishing-card-form.service';
import {
  FishingCard,
  FishingCardRequestDto,
} from '../../../services/environmental-protection/fishing-card/fishing-card.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-fishing-card-request',
  templateUrl: './fishing-card-request.component.html',
})
export class FishingCardRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  override controlName = FishingCardControlName;
  formValue: FishingCardRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  override portalId = PortalId.EnvironmentalProtectionPortalFishingCardRequest;

  constructor(
    public fishingCardFormService: FishingCardFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override formAlertService: FormAlertService,
    public override pzService: PzService,
    public override mapSettingsService: MapSettingsService,
    public override mapStateService: MapStateService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToDecisionFormRadioFormControl();
  }

  createForm(): void {
    this.formGroup = this.fishingCardFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            FishingCardRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/wydanieKartyWedkarskiej/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalFishingCardRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid && this.attachmentsComponent.areDocumentsValid()
    );
  }

  getFormValue(): FishingCard {
    return {
      ...this.formGroup.value,
    };
  }

  getBirthDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FishingCardControlName.BirthDate,
    ) as UntypedFormControl;
  }

  getBirthPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FishingCardControlName.BirthPlace,
    ) as UntypedFormControl;
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      FishingCardControlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getInvestorDetailsFormGroup().valid && this.submitted) ||
      (!this.getBirthPlaceFormControl().valid && this.submitted) ||
      (!this.getBirthDateFormControl().valid && this.submitted)
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
