<div class="modal-header">
  <h4 class="modal-title">
    {{ 'GK.COMPONENTS.PAYMENT_OWNER_MODAL.PAYMENT' | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>{{
          'GK.COMPONENTS.PAYMENT_OWNER_MODAL.CHOOSE_OWNER' | translate
        }}</mat-label>
        <mat-select [(value)]="paymentOwnerId">
          @for (
            owner of stampDutyService.getOwners(this.portalId) | async;
            track $index
          ) {
            <mat-option [value]="owner.id">{{ owner.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="dismiss()">
    {{ 'GK.COMPONENTS.PAYMENT_OWNER_MODAL.CANCEL' | translate }}
  </button>

  <button type="button" class="btn btn-primary" (click)="close()">
    {{ 'GK.COMPONENTS.PAYMENT_OWNER_MODAL.GO_TO_PAYMENT' | translate }}
  </button>
</div>
