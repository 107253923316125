import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { WebPortal, WebPortalResponseDto } from './base-web-portal.model';

@Injectable({
  providedIn: 'root',
})
export class BaseWebPortalService {
  webPortalUrl = '/api/system/web/portal';

  private webPortalData: Observable<WebPortal[]>;

  constructor(private httpClient: HttpClient) {}

  getWebPortalData(): Observable<WebPortal[]> {
    if (!this.webPortalData) {
      this.webPortalData = this.httpClient
        .get<WebPortalResponseDto[]>(this.webPortalUrl)
        .pipe(
          map((apiFields) =>
            apiFields.map((apiField) => WebPortal.fromApiToApp(apiField)),
          ),
          shareReplay(1),
        );
    }
    return this.webPortalData;
  }
}
