import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GkWindowRef } from '../../services/kendo-window/kendo-window.model';
import {
  getGkMapSheetFormActionButtonsConfig,
  gkMapSheetFormModel,
} from './gk-map-sheet-form.model';
import {
  MapAction,
  MapObjectTableActionType,
  MapSheetFormState,
  MapSheetFormValue,
  Paper,
  Scale,
  SourceActionType,
  SourceType,
  ToolType,
} from '../../../gk-map/models';
import { GkKendoGridMapService } from '../gk-kendo-grid-map.service';
import { GkMapSheetFormService } from './gk-map-sheet-form.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _ from 'lodash';
import { MapControl } from '../../../gk-map/controls';
import { mapSheetScales, scales } from '../../../gk-map/configs';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';

@Component({
  selector: 'gk-kendo-map-sheet-form',
  templateUrl: './gk-map-sheet-form.component.html',
})
export class GkMapSheetFormComponent
  extends MapControl
  implements OnInit, OnDestroy
{
  formValue: MapSheetFormValue;
  windowRef: GkWindowRef;
  form = new FormGroup({});
  formlyFormConfig: {
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  };
  formModel = _.cloneDeep(gkMapSheetFormModel);
  gkMapSheetFormActionButtonsConfig =
    getGkMapSheetFormActionButtonsConfig(this);
  allAvailableScales: Scale[] = [...mapSheetScales];
  mapScales: Scale[] = [...scales];
  paperRestriction = false;
  scaleRestriction = false;

  constructor(
    private gkMapSheetFormService: GkMapSheetFormService,
    private gkKendoGridMapService: GkKendoGridMapService, // private mapSettingsService: MapSettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.formModel);
    this.mapState = this.gkKendoGridMapService.$mapState.getValue();
    this.toolType = ToolType.RectangularExtent;
    this.sourceType = SourceType.MapSheetForm;
    this.dispatch.subscribe((action: MapAction) => {
      this.gkKendoGridMapService.$pendingMapActions.next([action]);
    });
    this.formlyFormConfig = this.gkMapSheetFormService.getFormConfig(this);
    this.updateMapScalesBasedOnPassedScalesDenominators();
    this.loadFormValue();
    this.formModel.paper = this.formValue.paper;
    this.formModel.scale = this.formValue.scale;
    this.formModel.paperOrientation = this.formValue.paper.orientation;
  }

  updateMapScalesBasedOnPassedScalesDenominators(): void {
    const scalesDenominators = (
      this.mapState.toolsState[this.toolType][
        this.sourceType
      ] as MapSheetFormState
    ).availableMapScalesDenominators;
    if (!scalesDenominators || !scalesDenominators.length) {
      return;
    }

    this.allAvailableScales = this.mapScales.filter((mapScale) =>
      scalesDenominators.includes(mapScale.denominator),
    );
  }

  loadFormValue(): void {
    const formValueFromState = (
      this.mapState.toolsState[this.toolType][
        this.sourceType
      ] as MapSheetFormState
    ).formValue;

    this.formValue = formValueFromState.center
      ? { ...formValueFromState, center: this.getCenter() }
      : this.getNewFormValue();

    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  dispatchFormValueChange(): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.FormValueChange, {
        value: this.formValue,
        options: { toolType: this.toolType, sourceType: this.sourceType },
      }),
    );
  }

  getCenter(): Coordinate {
    return getCenter(this.mapState.viewState.currentNativeExtent);
  }

  getNewFormValue(): MapSheetFormValue {
    return {
      scale: this.getScale(),
      paper: this.getPaper(),
      center: this.getCenter(),
    };
  }

  getScale(): Scale {
    const scaleFromMapSheetRestriction = this.getScaleFromMapSheetRestriction();
    this.scaleRestriction = !!scaleFromMapSheetRestriction;

    return this.scaleRestriction
      ? scaleFromMapSheetRestriction
      : this.getScaleBasedOnMapResolution();
  }

  getScaleBasedOnMapResolution(): Scale {
    const mapScale = this.mapScales.find(
      (scale) => scale.resolution === this.mapState.viewState.resolution,
    );
    const scaleIndex = this.allAvailableScales.findIndex((scale) => {
      return mapScale?.resolution === scale.resolution;
    });

    if (scaleIndex === -1) {
      return mapScale?.resolution < this.allAvailableScales[0].resolution
        ? this.allAvailableScales[0]
        : this.allAvailableScales[this.allAvailableScales.length - 1];
    }

    return mapScale.resolution === this.allAvailableScales[0].resolution
      ? this.allAvailableScales[0]
      : this.allAvailableScales[scaleIndex - 1];
  }

  getScaleFromMapSheetRestriction(): Scale | undefined {
    const restriction = this.getCleanMapSheetRestriction();

    return this.allAvailableScales.find(
      (scale) =>
        restriction && restriction.includes(scale.name.replace(/\s/g, '')),
    );
  }

  getCleanMapSheetRestriction(): string {
    const mapSheetRestriction = this.mapState.toolbarState.mapSheetRestriction;

    return mapSheetRestriction ? mapSheetRestriction.replace(/\s/g, '') : '';
  }

  getPaper(): Paper {
    const mapSheetPaperFromMapSheetRestriction =
      this.getPaperFromMapSheetRestriction();
    this.paperRestriction = !!mapSheetPaperFromMapSheetRestriction;

    return this.paperRestriction
      ? mapSheetPaperFromMapSheetRestriction
      : this.mapState.toolbarState.papers[0];
  }

  getPaperFromMapSheetRestriction(): Paper | undefined {
    const restriction = this.getCleanMapSheetRestriction();

    return this.mapState.toolbarState.papers.find(
      (paper) => restriction && restriction.includes(paper.name),
    );
  }

  clearForm(): void {
    this.form.reset();
  }

  onFormSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const mapSheetGeomObject =
        this.gkKendoGridMapService.$mapState.getValue().toolsState[
          this.toolType
        ].mapObjects[0];

      this.windowRef.close(mapSheetGeomObject.geom);
    }
  }

  onCancel(): void {
    this.windowRef.close();
  }

  handleMapSheetResolutionChange(resolution: number): void {
    this.formValue = {
      ...this.formValue,
      scale: this.allAvailableScales.find(
        (scale) => scale.resolution === resolution,
      ),
    };
    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  handleMapSheetPaperOrientationChange(orientation: string): void {
    this.formValue = {
      ...this.formValue,
      paper: this.mapState.toolbarState.papers.find(
        (paper) =>
          paper.orientation === orientation &&
          paper.name === this.formValue.paper.name,
      ),
    };
    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  handleMapSheetPaperNameChange(name: string): void {
    this.formValue = {
      ...this.formValue,
      paper: this.mapState.toolbarState.papers.find(
        (paper) =>
          paper.name === name &&
          paper.orientation === this.formValue.paper.orientation,
      ),
    };
    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  ngOnDestroy(): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapObjectTableActionType.DeselectAll,
        undefined,
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    ]);
  }
}
