import { FormControl } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum DPNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  DemolitionInformation = 'demolitionInformation',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
}

export interface DPNewRequestFormGroup {
  [DPNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [DPNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [DPNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [DPNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [DPNewRequestControlName.DemolitionInformation]: FormControl<string>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [DPNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [DPNewRequestControlName.ProxyDetails]: FormControl<InvestorDetails>;
}
