import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  filter,
  interval,
  map,
  Observable,
  shareReplay,
  switchMap,
  takeWhile,
} from 'rxjs';
import {
  DictionaryField,
  DictionaryFieldFromApi,
} from '../../gk-dynamic-list/services/dictionary/dictionary.model';
import { BasicResponse } from '../../utils/basic-response/basic-response.model';
import { PortalId } from '../../utils/portal-id/portal-id.model';
import { StampDutyPaymentStatus } from './stamp-duty.model';

@Injectable({
  providedIn: 'root',
})
export class StampDutyService {
  private ownersCache = new Map<PortalId, Observable<DictionaryField[]>>();
  private paymentStatusCache = new Map<
    string,
    Observable<StampDutyPaymentStatus>
  >();

  constructor(private httpClient: HttpClient) {}

  getOwners(portalId: PortalId): Observable<DictionaryField[]> {
    if (!this.ownersCache.has(portalId)) {
      this.ownersCache.set(
        portalId,
        this.httpClient
          .get<
            DictionaryFieldFromApi[]
          >(`/api/system/oplataskarbowa/owners/portal/${portalId}`)
          .pipe(
            map((response) =>
              response.map((owner) => DictionaryField.fromApiToApp(owner)),
            ),
            shareReplay(1),
          ),
      );
    }

    return this.ownersCache.get(portalId);
  }

  isPendingInCurrentSession(
    portalId: PortalId,
    documentSectionId: string | number,
  ): Observable<boolean> {
    return this.isSpecificStatusInCurrentSession(
      portalId,
      documentSectionId,
      StampDutyPaymentStatus.Pending,
    );
  }

  isPaidInCurrentSession(
    portalId: PortalId,
    documentSectionId: string | number,
  ): Observable<boolean> {
    return this.isSpecificStatusInCurrentSession(
      portalId,
      documentSectionId,
      StampDutyPaymentStatus.Paid,
    );
  }

  private isSpecificStatusInCurrentSession(
    portalId: PortalId,
    documentSectionId: string | number,
    status: StampDutyPaymentStatus,
  ): Observable<boolean> {
    return this.checkPaymentStatusInterval(portalId, documentSectionId).pipe(
      filter((currentStatus) => currentStatus === status),
      map(() => true),
    );
  }

  checkPaymentStatusInterval(
    portalId: PortalId,
    documentSectionId: string | number,
  ): Observable<StampDutyPaymentStatus> {
    const cacheKey = this.generateCacheKey(portalId, documentSectionId);

    if (!this.paymentStatusCache.has(cacheKey)) {
      this.paymentStatusCache.set(
        cacheKey,
        interval(7000).pipe(
          switchMap(() => this.getPaymentStatus(portalId, documentSectionId)),
          takeWhile(
            (status) => status === StampDutyPaymentStatus.Pending,
            true,
          ),
          shareReplay(1),
        ),
      );
    }

    return this.paymentStatusCache.get(cacheKey);
  }

  deletePaymentStatusCache(
    portalId: PortalId,
    documentSectionId: string | number,
  ): void {
    this.paymentStatusCache.delete(
      this.generateCacheKey(portalId, documentSectionId),
    );
  }

  getPaymentStatus(
    portalId: PortalId,
    documentSectionId: string | number,
  ): Observable<StampDutyPaymentStatus> {
    return this.httpClient
      .get<
        BasicResponse<StampDutyPaymentStatus>
      >(`/api/system/oplataskarbowa/portal/${portalId}/kdokrodz/${documentSectionId}/status`)
      .pipe(map((response) => response.Result));
  }

  generateCacheKey(
    portalId: PortalId,
    documentSectionId: string | number,
  ): string {
    return `${portalId}_${documentSectionId}`;
  }
}
