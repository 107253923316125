import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const locale = 'pl-PL';

export enum ApiTimeString {
  Start = 'T00:00:00.0000000',
  End = 'T23:59:59.0000000',
}

export class DateHelperUtil {
  static ngbDateToApiDateTime(
    ngbDate: NgbDateStruct,
    apiTimeString = ApiTimeString.Start,
  ): string {
    if (!ngbDate) {
      return null;
    }
    const apiDateTime = `${ngbDate.year}-${
      ngbDate.month > 9 ? ngbDate.month : '0' + ngbDate.month
    }-${ngbDate.day > 9 ? ngbDate.day : '0' + ngbDate.day}${apiTimeString}`;
    return apiDateTime;
  }

  static ngbDateToUiDate(ngbDate: NgbDateStruct): string {
    const uiDate = `${ngbDate.day > 9 ? ngbDate.day : '0' + ngbDate.day}/${
      ngbDate.month > 9 ? ngbDate.month : '0' + ngbDate.month
    }/${ngbDate.year}`;
    return uiDate;
  }

  static apiToUiDate = (dateTimeStringFromApi: string): string => {
    if (!DateHelperUtil.isApiDateTimeFormat(dateTimeStringFromApi)) {
      throw new Error('Wrong datetime format from API!');
    }

    const parts = new Date(dateTimeStringFromApi)
      .toLocaleDateString(locale)
      .split(/\.|\//);

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const uiDateString = `${day.length > 1 ? day : '0' + day}/${
      month.length > 1 ? month : '0' + month
    }/${year}`;

    return uiDateString;
  };

  static apiToNgbDate = (dateTimeStringFromApi: string): NgbDate => {
    if (!DateHelperUtil.isApiDateTimeFormat(dateTimeStringFromApi)) {
      throw new Error('Wrong datetime format from API!');
    }

    const parts = new Date(dateTimeStringFromApi)
      .toLocaleDateString(locale)
      .split(/\.|\//);

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    return new NgbDate(year, month, day);
  };

  static apiToUiTime = (dateTimeStringFromApi: string): string => {
    if (!DateHelperUtil.isApiDateTimeFormat(dateTimeStringFromApi)) {
      throw new Error('Wrong datetime format from API!');
    }
    const newString = new Date(dateTimeStringFromApi).toLocaleTimeString(
      locale,
    );

    return newString.slice(0, 5);
  };

  static apiToUiDateTime = (dateTimeStringFromApi: string): string => {
    return `${DateHelperUtil.apiToUiTime(
      dateTimeStringFromApi,
    )} ${DateHelperUtil.apiToUiDate(dateTimeStringFromApi)}`;
  };

  static isUiDateFormat = (maybeUiDateString: string): boolean => {
    const regExp = new RegExp(/[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}/);

    return regExp.test(maybeUiDateString);
  };

  static isApiDateTimeFormat = (maybeApiDateTimeString: string): boolean => {
    if (maybeApiDateTimeString && maybeApiDateTimeString.length !== 27) {
      return false;
    }
    const regExp = new RegExp(
      /[0-9]{4}-[0-9]{2}-[0-9]{2}[A-Z]{1}[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{7}/,
    );

    return regExp.test(maybeApiDateTimeString);
  };

  static isNgbDateStructValidDate(ngbDateStruct: NgbDateStruct): boolean {
    const allDefined = !!(
      ngbDateStruct.year &&
      ngbDateStruct.month &&
      ngbDateStruct.day
    );

    if (!allDefined) {
      return false;
    }

    const date = new Date();
    date.setFullYear(
      ngbDateStruct.year,
      ngbDateStruct.month - 1,
      ngbDateStruct.day,
    );

    if (
      date.getFullYear() === ngbDateStruct.year &&
      date.getMonth() + 1 === ngbDateStruct.month &&
      date.getDate() === ngbDateStruct.day
    ) {
      return true;
    }

    return false;
  }

  static getCurrentNgbDate(): NgbDate {
    const currentDate = this.getCurrentDate();

    return new NgbDate(
      currentDate.getFullYear(),
      currentDate.getUTCMonth() + 1,
      currentDate.getUTCDate(),
    );
  }

  // 0 - equal, > 0 - date1 is greater, < 0 - date2 is greater
  static compareTwoDates(date1: NgbDate, date2: NgbDate): number {
    if (!this.areDatesDefined(date1, date2)) {
      return 0;
    }

    const time1 = this.ngbDateToDate(date1).getTime();
    const time2 = this.ngbDateToDate(date2).getTime();

    return time1 - time2;
  }

  static isToDateNotLessThanFromDate(from: NgbDate, to: NgbDate): boolean {
    if (!this.areDatesDefined(from, to)) {
      return false;
    }

    const fromTime = this.ngbDateToDate(from).getTime();
    const toTime = this.ngbDateToDate(to).getTime();

    return fromTime <= toTime;
  }

  static areDatesEqual(date1: NgbDate, date2: NgbDate): boolean {
    if (!this.areDatesDefined(date1, date2)) {
      return false;
    }

    const time1 = this.ngbDateToDate(date1).getTime();
    const time2 = this.ngbDateToDate(date2).getTime();

    return time1 === time2;
  }

  private static areDatesDefined(from: NgbDate, to: NgbDate): boolean {
    return !!(
      from &&
      from.year &&
      from.month &&
      from.day &&
      to &&
      to.year &&
      to.month &&
      to.day
    );
  }

  private static ngbDateToDate(ngbDate: NgbDate): Date {
    return new Date(ngbDate.year, ngbDate.month, ngbDate.day);
  }

  static getCurrentDate(): Date {
    return new Date();
  }

  static getDateWithZeroTime(date = this.getCurrentDate()): Date {
    date.setHours(0, 0, 0, 0);

    return new Date(date);
  }

  static addDaysToNgbDate(ngbDate: NgbDate, days: number): NgbDate {
    if (!ngbDate) {
      return null;
    }
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    date.setDate(date.getDate() + days);
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
}
