@if (
  (loading$ | async) ||
  (loginService.navigationToExternalLoginInProcess$ | async)
) {
  <div class="global-loader">
    <div class="d-flex justify-content-center my-3">
      <h1 class="text-center">
        {{ 'INITIAL_LOADING_DESCRIPTION' | translate }}
      </h1>
    </div>
    <div class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  </div>
} @else {
  <app-accessible-navigation> </app-accessible-navigation>
  <div class="d-flex flex-column app-container" appSessionRefresher>
    @if (isNavbarVisible) {
      <app-navbar-provider class="app-navbar"></app-navbar-provider>
    }
    <router-outlet></router-outlet>
    <app-footer appSessionRefresher class="mt-auto"></app-footer>
    @if (checkIfLocationPathNameIncludesDepartmentsPortalRoute()) {
      <app-modal-prolong-session></app-modal-prolong-session>
    }
    <gk-app-cookies-bar></gk-app-cookies-bar>
  </div>
}

<app-version-update></app-version-update>
