import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { ClientSettings, ClientSettingsDto } from './client-settings.model';

@Injectable()
export class ClientSettingsService {
  clientSettingsUrl = '/api/interesant/settings';
  clientSettings: Observable<ClientSettings>;

  constructor(private http: HttpClient) {}

  public getSettings(): Observable<ClientSettings> {
    if (!this.clientSettings) {
      this.clientSettings = this.http
        .get<ClientSettingsDto>(this.clientSettingsUrl)
        .pipe(
          map((settingsFromApi) =>
            ClientSettings.fromApiToApp(settingsFromApi),
          ),
          shareReplay(1),
        );
    }

    return this.clientSettings;
  }
}
